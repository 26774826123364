import React, { FC } from 'react';
import styled from 'styled-components';
import { Button, DraggableRow } from 'ag-components';
import { TokenData } from './TokenData';
import { PlayableTokenFragment } from 'backend';

export type TokenRowProps = {
  group: string;
  index: number;
  token: PlayableTokenFragment;
  remove: () => void;
  move: (fromIndex: number, toIndex: number) => void;
  play: () => void;
};

const DraggableTokenRow = styled(DraggableRow)`
  padding: 1rem 2rem;
  :hover {
    background: var(--background-secondary-color);
  }
`;

export const TokenRow: FC<TokenRowProps> = ({
  group,
  index,
  token,
  remove,
  move,
  play,
}) => {
  return (
    <DraggableTokenRow
      index={index}
      group={group}
      move={move}
      onClick={e => {
        if (e.target === e.currentTarget) {
          play();
        }
      }}
      justify="space-between"
      align="center"
    >
      <TokenData token={token} />
      <Button text onClick={remove}>
        ×
      </Button>
    </DraggableTokenRow>
  );
};
