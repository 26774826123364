import styled from 'styled-components';

export type ClampedTextProps = {
  lines?: number;
};

export const ClampedText = styled.span<ClampedTextProps>`
  -webkit-line-clamp: ${({ lines = 1 }) => lines};
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
