import React, { FC } from 'react';
import styled from 'styled-components';
import { SalesTableRowFragment } from 'backend';
import {
  GridTable,
  Link,
  UserTag,
  ClampedText,
  Price,
  Row,
  formatAddress,
} from 'ag-components';
import { formatDistance } from 'date-fns';
import { PlaybackControls } from './PlaybackControls';
import { tokenPath, userPath } from '../utils';

export type SalesTableProps = {
  sales: SalesTableRowFragment[];
  includePlayer?: boolean;
};

const Container = styled.div`
  margin: 0 -1rem;
`;

export const SalesTable: FC<SalesTableProps> = ({
  sales,
  includePlayer = true,
}) => {
  return (
    <Container>
      <GridTable
        cols={5}
        header={['TOKEN', 'FROM', 'TO', 'TIME', 'PRICE']}
        visibleCols={{
          sm: [true, false, false, true, true],
          md: [true, false, true, true, true],
          lg: [true, true, true, true, true],
        }}
        rows={sales.map(sale => [
          <Link
            href={tokenPath(sale.token.collection.address, sale.token.tokenID)}
          >
            <Row gap={1} align="center">
              {includePlayer && <PlaybackControls token={sale.token} />}
              <ClampedText lines={2}>
                {sale.token.collection.name} #{sale.token.tokenID}
              </ClampedText>
            </Row>
          </Link>,
          sale.from ? (
            <UserTag
              href={userPath(sale.from.address)}
              avatarURI={sale.from.avatar?.uri}
              name={sale.from.name ?? formatAddress(sale.from.address)}
            />
          ) : (
            `minted`
          ),
          <UserTag
            href={userPath(sale.to.address)}
            avatarURI={sale.to.avatar?.uri}
            name={sale.to.name ?? formatAddress(sale.to.address)}
          />,
          `${formatDistance(new Date(sale.time), new Date())} ago`,
          <Price price={sale.price} />,
        ])}
      />
    </Container>
  );
};
