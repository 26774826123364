import React, { FC } from 'react';
import { PlayableTokenFragment } from 'backend';
import { Card, Col, Row, Link, ClampedText, Illustration } from 'ag-components';
import { PlaybackControls } from './PlaybackControls';
import { artistPath, tokenPath } from '../utils';

export type TokenCardProps = {
  token: PlayableTokenFragment;
};

export const TokenCard: FC<TokenCardProps> = ({
  token,
  token: { tokenID, image, name, collection, artist },
}) => {
  return (
    <Card padding={0}>
      <Col style={{ position: 'relative' }} align="stretch">
        <Link href={tokenPath(collection.address, tokenID)} block>
          {image && <Illustration src={image?.uri} />}
        </Link>
        <Row gap={1} align="center" padding={1}>
          <PlaybackControls token={token} />
          <Col>
            <Link
              href={tokenPath(collection.address, tokenID)}
              title={`${collection.name} - ${name}`}
            >
              <ClampedText>{collection.name}</ClampedText>
              <ClampedText>{name}</ClampedText>
            </Link>
            <Link href={artistPath(artist.chainID!)}>
              <ClampedText>{artist.name}</ClampedText>
            </Link>
          </Col>
        </Row>
      </Col>
    </Card>
  );
};
