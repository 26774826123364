import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { P } from './P';
import { H3 } from './H3';
import { ExternalLink } from './ExternalLink';

export type TextDocProps = {
  text: string;
};

const PP = styled(P)`
  font-family: Sans;
  * {
    font-family: inherit;
  }
`;
const EM = styled.em`
  color: var(--text-secondary-color);
  padding: 0 0.125em;
`;
const HH = styled(H3)`
  color: var(--text-secondary-color);
`;

const parser =
  /(?<link>\[(?<linkText>[\w\s]+)\]\((?<linkUrl>(?:https?:\/\/[\w.\/\-?=#]+)|\/[\w.\/\-?=#]*)\))|\*(?<em>[\w\s\,\[\]\-]+)\*|\*\*(?<strong>[\w\s\d]+)\*\*/g;

const markdown = (input: string): ReactNode[] => {
  const result: ReactNode[] = [];

  parser.lastIndex = 0;

  let lastIndex = 0;
  let match;
  while ((match = parser.exec(input))) {
    result.push(input.slice(lastIndex, match.index));
    lastIndex = parser.lastIndex;

    const groups = match.groups!;
    if (groups.link) {
      result.push(
        <ExternalLink href={groups.linkUrl}>{groups.linkText}</ExternalLink>,
      );
    } else if (groups.em) {
      result.push(<EM>{groups.em}</EM>);
    } else if (groups.strong) {
      result.push(<strong>{groups.strong}</strong>);
    }
  }

  result.push(input.slice(lastIndex));

  return result;
};

export const TextDoc: FC<TextDocProps> = ({ text }) => {
  return (
    <>
      {text
        .split('\n')
        .filter(v => v)
        .map(l => {
          if (l.match(/^# /)) {
            return <HH as="h4">{l.slice(2)}</HH>;
          }
          return (
            <PP
              style={{
                lineHeight: '1.666em',
                fontFamily: 'Sans',
              }}
            >
              {markdown(l)}
            </PP>
          );
        })}
    </>
  );
};
