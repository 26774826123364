import React, { FC } from 'react';
import { SVGIcon } from './SVGIcon';

export type PlayButtonProps = {
  onClick?: () => void;
  size?: number;
  disabled?: boolean;
};

export const PlayButton: FC<PlayButtonProps> = ({
  size,
  onClick,
  disabled,
}) => (
  <SVGIcon size={size} onClick={onClick} disabled={disabled}>
    <title>play</title>
    <path d="M8 5v14l11-7z" />
  </SVGIcon>
);
