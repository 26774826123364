import React from 'react';
import {
  H2,
  RouteHandler,
  LoadingScaffold,
  ErrorScaffold,
  Col,
  P,
  Row,
  Link,
  Section,
  Illustration,
  useQuery,
} from 'ag-components';
import { CollectionGrid, PlatformLayout, IllustratedHero } from '../components';
import { ArtistPageQuery, ArtistPageQueryVariables } from 'backend';
import { useAutoQueue } from '../hooks';
import { artistCollectionsPath, artistPath } from '../utils';

export const ArtistPage: RouteHandler = ({ pathParams }) => {
  const chainID = parseInt(pathParams.chainID);
  const [loading, error, data] = useQuery<
    ArtistPageQuery,
    ArtistPageQueryVariables
  >('ArtistPageQuery', { chainID });

  useAutoQueue(data, data =>
    data.artist.collections.edges.map(e => e.featuredToken),
  );

  if (loading) return <LoadingScaffold />;
  if (error || data === null) return <ErrorScaffold />;

  const {
    artist: { name, avatar, description, collections },
  } = data;

  return (
    <PlatformLayout
      breadcrumbs={[
        ['artists', null],
        [name, artistPath(chainID)],
      ]}
    >
      <Section>
        <IllustratedHero
          illustration={<Illustration src={avatar?.uri} />}
          content={
            <Col gap={2}>
              <H2 as="h1">{name}</H2>
              <P>{description}</P>
            </Col>
          }
        />
      </Section>

      <Section>
        <H2>Collections</H2>
        <CollectionGrid
          minSize={36}
          cols={collections.edges.length === 1 ? 1 : undefined}
          collections={collections.edges}
        />
        {collections.pageInfo.hasNextPage && (
          <Row>
            <Link href={artistCollectionsPath(chainID)}>All collections</Link>
          </Row>
        )}
      </Section>
    </PlatformLayout>
  );
};
