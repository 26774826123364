import React, { FC } from 'react';
import { H1, H2, Footer, Section, TextDoc } from 'ag-components';
import { PlatformLayout } from '../../components';
import { aboutPath, homePath, privacyPath, termsPath } from '../../utils';
import { default as content } from './content.txt';

export const AboutPage: FC = () => (
  <PlatformLayout smallMargin>
    <Section>
      <H1>About</H1>
      <TextDoc text={content} />
    </Section>
    <Section>
      <H2>More</H2>
      <Footer
        links={[
          ['home', homePath()],
          ['about', aboutPath()],
          ['terms', termsPath()],
          ['privacy', privacyPath()],
        ]}
      />
    </Section>
  </PlatformLayout>
);
