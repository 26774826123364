import React, { FC, useContext } from 'react';
import {
  Row,
  PlayButton,
  PauseButton,
  StopButton,
  MenuButton,
  strokeWidth,
} from 'ag-components';
import { PlayableTokenFragment } from 'backend';
import { PlayerContext } from '../contexts';
import { useTokenMenuItems } from '../hooks';
import { MoreButton } from 'ag-components/src/components/MoreButton';

export type PlaybackControlsProps = {
  token: PlayableTokenFragment;
};

export const PlaybackControls: FC<PlaybackControlsProps> = ({ token }) => {
  const { playing, current, play, pause, stop } = useContext(PlayerContext);
  const isCurrent = current?.token.queueID === token.queueID;
  const menuItems = useTokenMenuItems()(token);

  return (
    <Row
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
      }}
      gap={0.5}
      flex="0 0 auto"
    >
      {playing && isCurrent ? (
        <PauseButton onClick={() => pause()} />
      ) : (
        <PlayButton onClick={() => play(token)} />
      )}
      <StopButton onClick={stop} />
      <MenuButton
        items={menuItems}
        menuOffset={[3 * strokeWidth, 3 * strokeWidth]}
      >
        <MoreButton />
      </MenuButton>
    </Row>
  );
};
