import React, { FC } from 'react';
import styled from 'styled-components';

const SVG = styled.svg`
  display: inline;
  vertical-align: middle;
  margin-left: 0.25rem;
  path {
    fill: currentColor;
  }
`;

export type ExternalLinkIconProps = { size: number };

export const ExternalLinkIcon: FC<ExternalLinkIconProps> = ({ size }) => {
  return (
    <SVG width={size} height={size} viewBox="0 0 24 24">
      <path d="M 5 3 C 3.9069372 3 3 3.9069372 3 5 L 3 19 C 3 20.093063 3.9069372 21 5 21 L 19 21 C 20.093063 21 21 20.093063 21 19 L 21 12 L 19 12 L 19 19 L 5 19 L 5 5 L 12 5 L 12 3 L 5 3 z M 14 3 L 14 5 L 17.585938 5 L 8.2929688 14.292969 L 9.7070312 15.707031 L 19 6.4140625 L 19 10 L 21 10 L 21 3 L 14 3 z" />
    </SVG>
  );
};
