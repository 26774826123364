import React, { FC } from 'react';
import { DataList, Link, formatAddress } from 'ag-components';
import { TokenPageQuery } from 'backend';
import { artistPath, collectionPath, tokenPath, userPath } from '../../utils';

export type TokenDataProps = {
  data: TokenPageQuery;
};

export const TokenData: FC<TokenDataProps> = ({
  data: { collection, token },
}) => {
  return (
    <DataList
      data={[
        [
          'ARTIST',
          <Link href={artistPath(collection.artist.chainID!)}>
            {collection.artist.name}
          </Link>,
        ],
        [
          'COLLECTION',
          <Link href={collectionPath(collection.address)}>
            {collection.name}
          </Link>,
        ],
        [
          'TOKEN ID',
          <Link href={tokenPath(collection.address, token.tokenID)}>
            #{token.tokenID}
          </Link>,
        ],
        [
          'OWNER',
          <Link href={userPath(token.owner.address)}>
            {token.owner.name || formatAddress(token.owner.address)}
          </Link>,
        ],
        ['SEED', `0x${token.seed.toString(16)}`],
      ]}
    />
  );
};
