import React from 'react';
import {
  RouteHandler,
  Grid,
  LoadingScaffold,
  ErrorScaffold,
  useQuery,
  Section,
  formatAddress,
  H2,
} from 'ag-components';
import { PlatformLayout, TokenCard, PaginationControls } from '../components';
import { UserTokensPageQuery, UserTokensPageQueryVariables } from 'backend';
import { useAutoQueue } from '../hooks';
import { userPath, userTokensPath, connectionArgs } from '../utils';

export const UserTokensPage: RouteHandler = ({
  searchParams,
  pathParams: { address },
}) => {
  const [loading, error, data] = useQuery<
    UserTokensPageQuery,
    UserTokensPageQueryVariables
  >('UserTokensPageQuery', { address, ...connectionArgs(searchParams) });

  useAutoQueue(data, data => data.user.ownedTokens.edges);

  if (loading) return <LoadingScaffold />;
  if (error || data === null) return <ErrorScaffold />;

  const {
    user: { name, ownedTokens },
  } = data;

  return (
    <PlatformLayout
      breadcrumbs={[
        [name ?? formatAddress(address), userPath(address)],
        ['tokens', userTokensPath(address)],
      ]}
    >
      <Section>
        <H2 as="h1">{name}’s Tokens</H2>
        <Grid minSize={20} style={{ margin: '0 -1rem' }}>
          {ownedTokens.edges.map((token, i) => (
            <TokenCard token={token} key={i} />
          ))}
        </Grid>
        <PaginationControls pageInfo={ownedTokens.pageInfo} />
      </Section>
    </PlatformLayout>
  );
};
