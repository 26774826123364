import React from 'react';
import {
  Button,
  ButtonProps,
  ConnectWalletButton,
  ConnectWalletButtonsProps,
} from '.';
import { SpinningLoader } from './SpinningLoader';

type MintButtonProps = {
  onMint: () => Promise<void>;
  loading?: boolean;
  connectBtnProps?: ConnectWalletButtonsProps['connectBtnProps'];
  btnProps?: ButtonProps;
};

export function MintButton({
  onMint,
  loading,
  connectBtnProps,
  btnProps,
}: MintButtonProps) {
  return (
    <ConnectWalletButton
      connectBtnProps={{
        children: 'Connect to mint',
        large: true,
        ...connectBtnProps,
      }}
    >
      <Button
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '5px',
        }}
        large
        onClick={onMint}
        disabled={loading}
        {...btnProps}
      >
        {loading && <SpinningLoader />}
        Mint
      </Button>
    </ConnectWalletButton>
  );
}
