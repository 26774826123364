import React, { FC } from 'react';
import styled from 'styled-components';
import { PlayableTokenFragment } from 'backend';
import {
  GridTable,
  Link,
  ClampedText,
  Row,
  useMediaQuery,
} from 'ag-components';
import { PlaybackControls } from './PlaybackControls';
import { tokenPath } from '../utils';

export type MintedTokenTableProps = {
  tokens: PlayableTokenFragment[];
  includePlayer?: boolean;
  cols?: number;
};

const Container = styled.div`
  margin: 0 -1rem;
`;

const Image = styled.img`
  width: 4rem;
  height: 4rem;
  object-fit: cover;
  flex: none;
`;

const ImgPlaceholder = styled.div`
  width: 4rem;
  height: 4rem;
  background: var(--background-color);
  opacity: 0.25;
`;

export const TokenTable: FC<MintedTokenTableProps> = ({
  tokens,
  includePlayer = true,
}) => {
  const isSmall = useMediaQuery('(max-width: 730px)');
  return (
    <Container>
      <GridTable
        cols={isSmall ? 1 : 2}
        rows={tokens.map(token => [
          <Link
            href={tokenPath(token.collection!.address, token.tokenID!)}
            style={{ flex: 1 }}
          >
            <Row gap={1} align="center">
              {token.image && <Image src={token.image.uri} />}
              {!token.image && <ImgPlaceholder />}
              {includePlayer && (
                <PlaybackControls token={token as PlayableTokenFragment} />
              )}
              <ClampedText lines={2}>
                {token.artist!.name} - {token.collection!.name} - #
                {token.tokenID}
              </ClampedText>
            </Row>
          </Link>,
        ])}
      />
    </Container>
  );
};
