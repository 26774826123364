import { useContext } from 'react';
import {
  MenuItems,
  RoutingContext,
  SessionContext,
  useMutation,
} from 'ag-components';
import { PlayableTokenFragment } from 'backend';
import { PlayerContext } from '../contexts';
import {
  CreatePlaylistItemMutation,
  CreatePlaylistItemMutationVariables,
  CreatePlaylistMutation,
  CreatePlaylistMutationVariables,
} from 'backend';
import { editPlaylistPath } from '../utils';

export const useTokenMenuItems = () => {
  const { route } = useContext(RoutingContext);
  const { currentUser, playlists, mergePlaylistsChanges } =
    useContext(SessionContext);
  const { setQueue, addToQueue } = useContext(PlayerContext);

  const [createPlaylist] = useMutation<
    CreatePlaylistMutation,
    CreatePlaylistMutationVariables
  >('CreatePlaylistMutation', true);
  const [createPlaylistItem] = useMutation<
    CreatePlaylistItemMutation,
    CreatePlaylistItemMutationVariables
  >('CreatePlaylistItemMutation', true);

  const viewerIsConnected = currentUser !== null;

  return (token: PlayableTokenFragment): MenuItems => {
    return [
      [
        ['Play next', () => setQueue([token])],
        ['Add to queue', () => addToQueue(token)],
      ],
      ...((viewerIsConnected
        ? [
            [
              [
                'Add to playlist',
                [
                  [
                    [
                      'New playlist',
                      async () => {
                        const response = await createPlaylist({
                          input: {
                            name: 'Playlist',
                            collectionAddress: token.collection.address,
                            tokenID: token.tokenID,
                          },
                        });
                        if (response) {
                          const {
                            createPlaylist: { playlist },
                          } = response;
                          route(editPlaylistPath(playlist.id));
                          mergePlaylistsChanges([...playlists, playlist]);
                        }
                      },
                    ],
                  ],
                  ...((playlists.length > 0
                    ? [
                        playlists.map(({ id, name }) => [
                          name,
                          async () => {
                            await createPlaylistItem({
                              input: {
                                playlistID: id,
                                collectionAddress: token.collection.address,
                                tokenID: token.tokenID,
                              },
                            });
                          },
                        ]),
                      ]
                    : []) as MenuItems),
                ],
              ],
            ],
          ]
        : []) as MenuItems),
    ];
  };
};
