import React from 'react';
import { useCallback, useContext } from 'react';
import { A } from '.';
import { SessionContext } from '..';

export function Logout() {
  const { currentUser } = useContext(SessionContext);

  const onClick = useCallback(e => {
    e.preventDefault();
    localStorage?.clear();
    window.location.reload();
  }, []);

  if (currentUser == null) return <></>;

  return (
    <A style={{ width: '100%', whiteSpace: 'nowrap' }} onClick={onClick}>
      Log out
    </A>
  );
}
