import React, { FC } from 'react';
import { SVGIcon } from './SVGIcon';

export type StopButtonProps = {
  onClick?: () => void;
  size?: number;
  disabled?: boolean;
};

export const StopButton: FC<StopButtonProps> = ({
  size,
  onClick,
  disabled,
}) => (
  <SVGIcon size={size} onClick={onClick} disabled={disabled}>
    <title>stop</title>
    <path d="M6 6h12v12H6z" />
  </SVGIcon>
);
