import { Graph, SeededGraph, seedGraph } from 'helicon';
import { PlayableTokenFragment } from 'backend';
import { GRAPHQL_OPERATION_URL } from 'ag-config';

const MAX_CACHE_SIZE = 33;

export class GraphLoader {
  // use map as LRU cache
  cache: Map<string, Graph> = new Map();

  async load(token: PlayableTokenFragment): Promise<SeededGraph> {
    const address = token.collection.address;

    let graph = this.cache.get(address);
    if (graph) {
      this.cache.delete(address);
      this.cache.set(address, graph);
    } else {
      const resp = await fetch(GRAPHQL_OPERATION_URL, {
        method: 'POST',
        body: JSON.stringify({
          name: 'CollectionGraphQuery',
          vars: { address },
        }),
      });
      graph = (await resp.json()).data.collection.graph as Graph;
      this.cache.set(address, graph);
      if (this.cache.size > MAX_CACHE_SIZE) {
        this.cache.delete(this.cache.keys().next().value);
      }
    }

    return seedGraph(graph, token.seed);
  }
}
