import CoinbaseWalletSDK from '@coinbase/wallet-sdk';
import { CHAIN_ID, PUBLIC_RPC_NODE } from 'ag-web3';
import detectEthereumProvider from '@metamask/detect-provider';

export const getCoinbaseWalletProvider = () => {
  const coinbaseWallet = new CoinbaseWalletSDK({
    appName: 'Unlooped',
    // appLogoUrl: APP_LOGO_URL,
    darkMode: false,
    overrideIsMetaMask: true,
  });
  return coinbaseWallet.makeWeb3Provider(PUBLIC_RPC_NODE, parseInt(CHAIN_ID));
};

// MetaMask Provider
export const getMetaMaskProvider: () => Promise<any> = async () => {
  const provider = await detectEthereumProvider();
  return provider;
};
