import React, { FC, useContext } from 'react';
import { StyleContext } from '../contexts';
import { SVGIcon } from './SVGIcon';

export type DarkModeToggleButtonProps = {
  size?: number;
};

export const DarkModeToggleButton: FC<DarkModeToggleButtonProps> = ({
  size,
}) => {
  const { darkMode, setDarkMode } = useContext(StyleContext);

  const title = darkMode ? 'light mode' : 'dark mode';
  const path = darkMode
    ? 'M 11 0 L 11 3 L 13 3 L 13 0 L 11 0 z M 4.2226562 2.8085938 L 2.8085938 4.2226562 L 4.9296875 6.34375 L 6.34375 4.9296875 L 4.2226562 2.8085938 z M 19.777344 2.8085938 L 17.65625 4.9296875 L 19.070312 6.34375 L 21.191406 4.2226562 L 19.777344 2.8085938 z M 12 5 A 7 7 0 0 0 5 12 A 7 7 0 0 0 12 19 A 7 7 0 0 0 19 12 A 7 7 0 0 0 12 5 z M 0 11 L 0 13 L 3 13 L 3 11 L 0 11 z M 21 11 L 21 13 L 24 13 L 24 11 L 21 11 z M 4.9296875 17.65625 L 2.8085938 19.777344 L 4.2226562 21.191406 L 6.34375 19.070312 L 4.9296875 17.65625 z M 19.070312 17.65625 L 17.65625 19.070312 L 19.777344 21.191406 L 21.191406 19.777344 L 19.070312 17.65625 z M 11 21 L 11 24 L 13 24 L 13 21 L 11 21 z'
    : 'M13.019,21.998c-3.729,0-7.313-2.093-9.032-5.673c-1.156-2.408-1.305-5.122-0.419-7.642c0.886-2.52,2.7-4.544,5.108-5.7 c1.543-0.741,3.261-1.073,4.976-0.96l0.382,1.001c-3.423,1.399-5.003,6.686-3.46,9.901l0,0c1.393,2.901,5.41,5.999,10.011,4.012 l0.607,0.795c-0.983,1.407-2.319,2.542-3.861,3.282C15.939,21.681,14.467,21.997,13.019,21.998z';

  return (
    <div>
      <SVGIcon size={size} onClick={() => setDarkMode(!darkMode)}>
        <title>{title}</title>
        <path d={path} />
      </SVGIcon>
    </div>
  );
};
