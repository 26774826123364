import React, { useContext } from 'react';
import styled from 'styled-components';
import {
  H1,
  P,
  RouteHandler,
  LoadingScaffold,
  ErrorScaffold,
  useQuery,
  Section,
  Col,
  Link,
  InfoText,
  breakpointQueries,
  SessionContext,
  useMutation,
  MenuItems,
  formatAddress,
} from 'ag-components';
import { PlatformLayout, PaginationControls } from '../../components';
import {
  PlaylistPageQuery,
  PlaylistPageQueryVariables,
  DeletePlaylistItemMutation,
  DeletePlaylistItemMutationVariables,
  UpdatePlaylistItemMutation,
  UpdatePlaylistItemMutationVariables,
} from 'backend';
import {
  connectionArgs,
  editPlaylistPath,
  playlistPath,
  userPath,
  userPlaylistsPath,
} from '../../utils';
import { useAutoQueue, useTokenMenuItems } from '../../hooks';
import { PlaylistRow } from './PlaylistRow';
import { HeaderRow } from './HeaderRow';
import { PlaylistInfo } from './PlaylistInfo';
import { PlayerContext } from '../../contexts';
import { stringMidpoint } from '../../../../string-midpoint/src';

const LayoutRow = styled.div`
  display: flex;
  gap: 4rem;
  flex-direction: column;
  @media ${breakpointQueries.lg} {
    flex-direction: row-reverse;
    align-items: stretch;
  }
`;
const PlaylistContent = styled(Col)`
  margin: 0 -1rem;
  @media ${breakpointQueries.lg} {
    background: var(--grid-line-secondary-color);
  }
`;

export const PlaylistPage: RouteHandler = ({
  pathParams: { playlistID },
  searchParams,
}) => {
  const { currentUser } = useContext(SessionContext);
  const { setAutoQueue } = useContext(PlayerContext);

  const [loading, error, data, mergeQueryChanges] = useQuery<
    PlaylistPageQuery,
    PlaylistPageQueryVariables
  >('PlaylistPageQuery', { playlistID, ...connectionArgs(searchParams) });

  const [updatePlaylistItem] = useMutation<
    UpdatePlaylistItemMutation,
    UpdatePlaylistItemMutationVariables
  >('UpdatePlaylistItemMutation', true);
  const [deletePlaylistItem] = useMutation<
    DeletePlaylistItemMutation,
    DeletePlaylistItemMutationVariables
  >('DeletePlaylistItemMutation', true);

  const tokenMenuItems = useTokenMenuItems();

  useAutoQueue(data, data => data.playlist.items.edges.map(e => e.token));

  if (loading) return <LoadingScaffold />;
  if (error || data === null) return <ErrorScaffold />;

  const {
    playlist,
    playlist: { name, items, user },
  } = data;
  const viewerIsPlaylistOwner = user.address === currentUser?.address;

  return (
    <PlatformLayout
      breadcrumbs={[
        [user.name ?? formatAddress(user.address), userPath(user.address)],
        ['playlists', userPlaylistsPath(user.address)],
        [name, playlistPath(playlistID)],
      ]}
    >
      <Section>
        <Col gap={1}>
          <H1>{name}</H1>
          {viewerIsPlaylistOwner && (
            <P>
              <Link href={editPlaylistPath(playlistID)}>edit playlist</Link>
            </P>
          )}
        </Col>
        <LayoutRow>
          {items.edges.length > 0 && <PlaylistInfo data={data} />}
          {items.edges.length <= 0 ? (
            <Col flex="1" align="stretch">
              <P>
                <InfoText>Nothing has been added to this playlist yet</InfoText>
              </P>
            </Col>
          ) : (
            <PlaylistContent flex="1" align="stretch" gap={3}>
              <Col align="stretch">
                <HeaderRow />
                {items.edges.map(({ token, id }, i) => (
                  <PlaylistRow
                    key={id}
                    token={token}
                    index={i}
                    viewerIsPlaylistOwner={viewerIsPlaylistOwner}
                    move={(fromIndex, toIndex) => {
                      const nextItems = [...items.edges];
                      const [item] = nextItems.splice(fromIndex, 1);

                      const left = nextItems[toIndex - 1]?.position;
                      const right = nextItems[toIndex]?.position;
                      const nextPosition = stringMidpoint(left, right);

                      nextItems.splice(toIndex, 0, {
                        ...item,
                        position: nextPosition,
                      });

                      updatePlaylistItem({
                        input: {
                          id: item.id,
                          position: nextPosition,
                        },
                      });

                      setAutoQueue(nextItems.map(t => t.token));

                      mergeQueryChanges({
                        playlist: {
                          ...playlist,
                          items: {
                            ...playlist.items,
                            edges: nextItems,
                          },
                        },
                      });
                    }}
                    contextMenuItems={[
                      ...tokenMenuItems(token),
                      ...((viewerIsPlaylistOwner
                        ? [
                            [
                              [
                                'Remove from playlist',
                                () => {
                                  deletePlaylistItem({
                                    input: { id },
                                  });

                                  const nextItems = [...items.edges];
                                  nextItems.splice(i, 1);

                                  setAutoQueue(nextItems.map(i => i.token));
                                  mergeQueryChanges({
                                    playlist: {
                                      ...playlist,
                                      items: {
                                        ...playlist.items,
                                        edges: nextItems,
                                      },
                                    },
                                  });
                                },
                              ],
                            ],
                          ]
                        : []) as MenuItems),
                    ]}
                  />
                ))}
              </Col>
              <PaginationControls pageInfo={items.pageInfo} />
            </PlaylistContent>
          )}
        </LayoutRow>
      </Section>
    </PlatformLayout>
  );
};
