import React, {
  FC,
  useState,
  useMemo,
  useEffect,
  ReactNode,
  ComponentType,
} from 'react';
import { RoutingContext, RoutingOptions } from '../contexts';
import { match, MatchFunction } from 'path-to-regexp';

export type Params = { [name: string]: string };
export type HandlerProps = {
  path: string;
  search: string;
  hash: string;
  pathParams: Params;
  searchParams: Params;
};
export type RouteHandler = FC<HandlerProps>;
export type Route = [string, RouteHandler];
export type CompiledRoute = {
  match: MatchFunction;
  handler: RouteHandler;
};
export type RouterProps = {
  routes: Route[];
  NotFound: RouteHandler;
  children?: ReactNode;
  providers?: ComponentType<{ children?: ReactNode }>[];
};

const CONFIRM_EXIT_MESSAGE =
  'Leave this page? Changes you made may not be saved.';

export const Router: FC<RouterProps> = ({
  routes,
  NotFound,
  providers = [],
}) => {
  const [{ path, search, hash, nonce }, setState] = useState<{
    path: string;
    search: string;
    hash: string;
    nonce: number;
  }>({
    path: window.location.pathname,
    search: window.location.search,
    hash: window.location.hash,
    nonce: 0,
  });
  const [confirmExit, setConfirmExit] = useState(false);

  useEffect(() => {
    // listen to pop state events and block or update
    const popstate = () => {
      setState(state => ({
        path: window.location.pathname,
        search: window.location.search,
        hash: window.location.hash,
        nonce: state.nonce,
      }));
    };
    window.addEventListener('popstate', popstate);

    // confirm exit on refresh or navigation away
    const beforeUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      e.returnValue = '';
    };
    if (confirmExit) {
      window.addEventListener('beforeunload', beforeUnload);
    }

    return () => {
      window.removeEventListener('beforeunload', beforeUnload);
      window.removeEventListener('popstate', popstate);
    };
  }, [confirmExit]);

  const compiledRoutes = useMemo(
    () =>
      routes.map(
        ([path, handler]: Route): CompiledRoute => ({
          match: match(path),
          handler,
        }),
      ),
    [routes],
  );

  const routingOptions = useMemo<RoutingOptions>(
    () => ({
      route(href: string): void {
        if (confirmExit && !confirm(CONFIRM_EXIT_MESSAGE)) return;

        window.history.pushState(null, '', href);
        const url = new URL(href, window.location.origin);
        setState(state => ({
          path: url.pathname,
          search: url.search,
          hash: url.hash,
          nonce: state.nonce + 1,
        }));
      },
      refresh(): void {
        setState(state => ({ ...state, nonce: state.nonce + 1 }));
      },
      confirmExit,
      setConfirmExit,
    }),
    [confirmExit],
  );

  const { Component, props } = useMemo(() => {
    let compiledRoute;
    let match;
    for (let i = 0; !match && i < compiledRoutes.length; i++) {
      match = compiledRoutes[i].match(path);
      if (match) {
        compiledRoute = compiledRoutes[i];
      }
    }
    return {
      Component: compiledRoute?.handler ?? NotFound,
      props: {
        path,
        search,
        hash,
        pathParams: (match ? match.params : {}) as Params,
        searchParams: Object.fromEntries(new URLSearchParams(search).entries()),
      },
    };
  }, [compiledRoutes, path, search, hash, NotFound]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [Component, props]);

  return (
    <RoutingContext.Provider value={routingOptions}>
      {providers?.reduce(
        (memo, P) => (
          <P>{memo}</P>
        ),
        <Component key={path + search + nonce.toString()} {...props} />,
      )}
    </RoutingContext.Provider>
  );
};
