import React, { FC, ReactNode, useState, useMemo } from 'react';
import { ContextMenu } from '../components';
import { Vector, MenuItems } from '../types';
import { ContextMenuContext, ContextMenuOptions } from '../contexts';

export type ContextMenuProviderProps = {
  children?: ReactNode;
};

export const ContextMenuProvider: FC<ContextMenuProviderProps> = ({
  children,
}) => {
  const [menuOptions, setMenuOptions] = useState<
    | {
        items: MenuItems;
        position: Vector;
        spliceElement?: HTMLElement | SVGElement | null;
        key: number;
      }
    | undefined
  >();

  const contextMenuOptions = useMemo<ContextMenuOptions>(
    () => ({
      open: (position, items, spliceElement) =>
        setMenuOptions({
          position,
          items,
          key: (menuOptions?.key ?? 0) + 1,
          spliceElement,
        }),
      close: () => setMenuOptions(undefined),
      isOpen: menuOptions !== undefined,
    }),
    [menuOptions],
  );

  return (
    <ContextMenuContext.Provider value={contextMenuOptions}>
      {children}
      {menuOptions && (
        <ContextMenu
          key={menuOptions.key}
          position={menuOptions.position}
          items={menuOptions.items}
          spliceElement={menuOptions.spliceElement}
          close={() => setMenuOptions(undefined)}
        />
      )}
    </ContextMenuContext.Provider>
  );
};
