import React, { FC, useRef, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { TriggerEvent, AudioGraph, SeededGraph, mulberry32 } from 'helicon';
import { Vector } from 'ag-components';

const edgeWidth = 2;
const nodeSize = 4;
const c1 = 'var(--input-disabled-color)';
const c2 = 'var(--visualization-color)';
const c3 = 'var(--text-color)';

const SVG = styled.svg`
  margin: 0;
`;

export type GraphThumbnailProps = {
  audioGraph?: AudioGraph;
  graph: SeededGraph;
  width: number | string;
};

export const GraphThumbnail: FC<GraphThumbnailProps> = ({
  audioGraph,
  graph,
  width,
}) => {
  const svgRef = useRef<SVGSVGElement | null>(null);

  useEffect(() => {
    if (audioGraph) {
      const listeners = Object.entries(audioGraph.audioNodes).reduce(
        (memo, [id, node]) => {
          memo[id] = ((e: TriggerEvent) => {
            if (e.detail) {
              (
                svgRef.current?.querySelector(`[data-id='${id}']`) as any
              )?.beginElement();
            }
          }) as EventListener;
          node.addEventListener('trigger', memo[id]);
          return memo;
        },
        {} as { [id: string]: EventListener },
      );

      return () => {
        Object.entries(listeners).forEach(([id, listener]) =>
          audioGraph?.audioNodes[id].removeEventListener('trigger', listener),
        );
      };
    }
    return undefined;
  }, [audioGraph]);

  const w = 180;
  const h = 180;

  const { positions, minX, maxX, minY, maxY } = useMemo(() => {
    const rand = mulberry32(graph.seed);
    const positions: { [id: string]: Vector } = {};
    let minX = w,
      maxX = 0,
      minY = h,
      maxY = 0;

    Object.keys(graph.nodes).forEach(id => {
      const angle = rand() * Math.PI * 2;
      const distance = (Math.pow(rand(), 1.25) * w) / 2;
      const x = distance * Math.cos(angle) + w / 2;
      const y = distance * Math.sin(angle) + w / 2;
      if (x < minX) minX = x;
      if (x > maxX) maxX = x;
      if (y < minY) minY = y;
      if (y > maxY) maxY = y;
      positions[id] = [x, y];
    });

    return { positions, minX, maxX, minY, maxY };
  }, [graph]);

  const size = Math.max(maxX - minX + nodeSize, maxY - minY + nodeSize);

  return (
    <SVG ref={svgRef} viewBox={`${minX} ${minY} ${size} ${size}`} width={width}>
      {Object.entries(graph.edges).map(([id, { from, to }]) => {
        const [x1, y1] = positions[from.node];
        const [x2, y2] = positions[to.node];
        return (
          <line
            key={id}
            x1={x1 + nodeSize / 2}
            y1={y1 + nodeSize / 2}
            x2={x2 + nodeSize / 2}
            y2={y2 + nodeSize / 2}
            strokeWidth={edgeWidth}
            stroke={c1}
          />
        );
      })}
      {Object.entries(graph.nodes).map(([id]) => {
        const [x, y] = positions[id];
        return (
          <rect
            key={id}
            x={x}
            y={y}
            width={nodeSize}
            height={nodeSize}
            rx={1}
            fill={c2}
          />
        );
      })}
      {Object.entries(graph.nodes).map(([id]) => {
        const [x, y] = positions[id];
        return (
          <rect
            key={id}
            x={x}
            y={y}
            width={nodeSize}
            height={nodeSize}
            rx={1}
            fill={'transparent'}
          >
            <animate
              data-id={id}
              attributeName="fill"
              begin="indefinate"
              dur="0.15s"
              from={c3}
              to={'transparent'}
            />
          </rect>
        );
      })}
    </SVG>
  );
};
