import React, { FC } from 'react';
import styled from 'styled-components';
import { Row } from './Row';
import { Link } from './Link';
import { ClampedText } from './ClampedText';

export type UserTagProps = {
  avatarURI?: string;
  href: string;
  name: string | null | undefined;
};

const Avatar = styled.img`
  width: 1.875rem;
  height: 1.875rem;
`;

export const UserTag: FC<UserTagProps> = ({ avatarURI, href, name }) => (
  <Link href={href} block style={{ width: '100%' }}>
    <Row gap={0.5} align="center" style={{ width: '100%' }}>
      {avatarURI && <Avatar src={avatarURI} />}
      <ClampedText>{name}</ClampedText>
    </Row>
  </Link>
);
