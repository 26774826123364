import { breakpoints } from 'ag-components';
import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

const ResponsiveRow = styled.div`
  display: flex;
  gap: 1.5rem;
  align-items: center;
  margin: 0 -1rem;

  @media (max-width: ${breakpoints.lg - 1}px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const IllustrationCell = styled.div`
  flex: 0 0 40rem;
  max-width: 40rem;

  @media (max-width: ${breakpoints.lg - 1}px) {
    flex: none;
    width: 100%;
    align-items: flex-start;
  }
`;

const ContentCell = styled.div`
  padding: 0 1rem;
  @media (max-width: ${breakpoints.lg - 1}px) {
    max-width: 40rem;
  }
`;

export type IllustratedHeroProps = {
  illustration: ReactNode;
  content: ReactNode;
};

export const IllustratedHero: FC<IllustratedHeroProps> = ({
  illustration,
  content,
}) => (
  <ResponsiveRow>
    <IllustrationCell>{illustration}</IllustrationCell>
    <ContentCell>{content}</ContentCell>
  </ResponsiveRow>
);
