import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import {
  PreviousButton,
  Button,
  Col,
  CurrentTime,
  NextButton,
  H2,
  PauseButton,
  PlayButton,
  Row,
  ShowQueueButton,
  StopButton,
} from 'ag-components';
import { PlayerContext } from '../../contexts';
import { TokenData } from './TokenData';
import { TokenList } from './TokenList';

export type PlayerProps = {};

const Container = styled.div`
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  margin-left: auto;
  margin-right: auto;
  background: var(--group-color);
  padding: 1rem 2rem;
  transition: transform 0.4s ease-out;
  width: 100%;
  max-width: 600px;
  z-index: 2;
`;

const Queue = styled.div`
  position: absolute;
  bottom: 100%;
  width: 100%;
  max-width: 600px;
  max-height: calc(100vh - 4.5rem);
  overflow-y: auto;
  right: 0;
  padding: 1rem 0;
  background: var(--project-preview-color);
`;

const SectionHeader = styled.div`
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Player: FC<PlayerProps> = () => {
  const {
    current,
    playing,
    queue,
    setQueue,
    autoQueue,
    setAutoQueue,
    play,
    pause,
    next,
    previous,
    stop,
  } = useContext(PlayerContext);
  const [queueOpen, setQueueOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (queueOpen) {
      const handleClick = (e: MouseEvent) => {
        if (!containerRef.current?.contains(e.target as Node)) {
          setQueueOpen(false);
        }
      };
      document.body.addEventListener('click', handleClick, true);
      return () =>
        document.body.removeEventListener('click', handleClick, true);
    }
    return;
  }, [queueOpen]);

  return (
    <Container
      style={{
        transform:
          current === undefined ? `translate(0,100%)` : `translate(0,0)`,
      }}
      ref={containerRef}
    >
      <Row gap={1} align="center">
        <Row gap={0.5}>
          <PreviousButton onClick={() => previous()} />
          {playing ? (
            <PauseButton onClick={() => pause()} />
          ) : (
            <PlayButton onClick={() => play()} />
          )}
          <StopButton onClick={stop} />
          <NextButton onClick={() => next()} />
        </Row>
        <CurrentTime
          audioContext={current?.audioGraph.context}
          frameDivisor={5}
        />
        <div style={{ flex: 1, paddingLeft: '1rem' }}>
          {current && <TokenData token={current?.token} />}
        </div>
        <ShowQueueButton
          onClick={() => {
            setQueueOpen(s => !s);
          }}
          disabled={queue.length === 0 && autoQueue.length === 0 && !queueOpen}
        />
      </Row>
      {queueOpen && current && (
        <Queue>
          <Col align="stretch">
            <SectionHeader>
              <H2 size={1.5}>Playing Now</H2>
            </SectionHeader>
            <div style={{ padding: '1rem 2rem ' }}>
              <TokenData token={current.token} />
            </div>
            {queue.length > 0 && (
              <>
                <SectionHeader>
                  <H2 size={1.5}>Next in Queue</H2>
                  <Button text onClick={() => setQueue([])}>
                    clear
                  </Button>
                </SectionHeader>
                <TokenList
                  group="player-queue"
                  play={play}
                  queue={queue}
                  setQueue={setQueue}
                />
              </>
            )}
            {autoQueue.length > 0 && (
              <>
                <SectionHeader>
                  <H2 size={1.5}>Up Next</H2>
                </SectionHeader>
                <TokenList
                  group="player-auto-queue"
                  play={play}
                  queue={autoQueue}
                  setQueue={setAutoQueue}
                />
              </>
            )}
          </Col>
        </Queue>
      )}
    </Container>
  );
};
