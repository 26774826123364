import { GRAPHQL_OPERATION_URL } from 'ag-config';

function processVarsAndExtractFiles(
  vars: any,
  files: File[],
  map: { [index: string]: string[] },
  path: string[] = [],
): any {
  if (vars instanceof File) {
    // Direct File instance
    map[`${files.length}`] = [`vars.${path.join('.')}`];
    files.push(vars);
    return null;
  } else if (Array.isArray(vars)) {
    // Process each item in the array
    return vars.map((item, index) =>
      processVarsAndExtractFiles(item, files, map, path.concat(String(index))),
    );
  } else if (typeof vars === 'object' && vars !== null) {
    // Process each property in the object
    Object.keys(vars).forEach(key => {
      vars[key] = processVarsAndExtractFiles(
        vars[key],
        files,
        map,
        path.concat(key),
      );
    });
    return vars;
  } else {
    return vars;
  }
}

export const graphqlOperation = async <Result, Variables>(
  name: string,
  vars: Variables,
  authToken?: string,
): Promise<Result | null> => {
  // detect files in vars
  const map: { [name: string]: string[] } = {};
  const files: File[] = [];
  processVarsAndExtractFiles(vars, files, map);

  // create post body
  const formData = new FormData();
  formData.append(
    'operations',
    JSON.stringify({
      name,
      vars,
      authToken,
    }),
  );
  formData.append('map', JSON.stringify(map));
  files.forEach((file, i) => formData.append(`${i}`, file));

  // send request to server
  const response = await fetch(GRAPHQL_OPERATION_URL, {
    method: 'POST',
    body: formData,
  });
  if (!response.ok) {
    return null;
  }
  const graphqlResponse = await response.json();
  if (graphqlResponse.errors?.length > 0) {
    console.error('mutation errors', graphqlResponse.errors);
    return null;
  }

  return graphqlResponse.data;
};
