import { License, LicenseOption } from 'backend';
import React, { FC } from 'react';

export type LicenseNameProps = {
  license: License;
  includeVersion?: boolean;
};

export const LicenseName: FC<LicenseNameProps> = ({
  license: {
    publicDomain,
    commercialUse,
    derivativeWorks,
    sharingRequirements,
  },
  includeVersion = true,
}) => (
  <span>
    {publicDomain === LicenseOption.All
      ? `CC0${includeVersion ? ' 1.0' : ''}`
      : `CC ${[
          'BY',
          commercialUse !== LicenseOption.All && 'NC',
          derivativeWorks !== LicenseOption.All
            ? 'ND'
            : sharingRequirements !== LicenseOption.All
            ? 'SA'
            : false,
        ]
          .filter(v => v)
          .join('-')}${includeVersion ? ' 4.0' : ''}${
          [commercialUse, derivativeWorks, sharingRequirements].some(
            v => v === LicenseOption.Holders,
          )
            ? ' with grants to token owners'
            : ''
        }`}
  </span>
);
