import React, { useState } from 'react';
import {
  A,
  P,
  RoutingContext,
  SessionContext,
  useDep,
  useWeb3Store,
} from 'ag-components';
import { useContext } from 'react';
import { userPath } from '../utils';
import { Address, BASIC_MINTER, minter } from 'ag-web3';
import toast from 'react-hot-toast';
import { encodeFunctionData } from 'viem';

export const useMint = (
  contractAddress: string | null | undefined,
  mintPriceInEth: string,
  onDone?: any,
  onWrongChain?: any,
) => {
  const { route } = useContext(RoutingContext);
  const dep = useDep();
  const { currentUser } = useContext(SessionContext);
  const { address, walletClient, publicClient, chainId, depJwt } =
    useWeb3Store();
  const [loading, updateLoading] = useState(false);

  return {
    loading,
    mint: async () => {
      if (currentUser) {
        try {
          if (!depJwt && chainId != process.env.CHAIN_ID) {
            console.error('Connected to the wrong chain.');
            onWrongChain?.();
            return;
          }
          updateLoading(true);
          const mintPrice = BigInt(Number(mintPriceInEth) * 10 ** 18);
          const common = {
            abi: minter,
            functionName: 'mint',
            args: [contractAddress as Address, BigInt(1)],
            account: address!,
            value: mintPrice,
          } as const;
          const params = {
            address: BASIC_MINTER as Address,
            chain: null,
            ...common,
          } as const;
          let hash;
          if (depJwt) {
            const data = encodeFunctionData(common);
            hash = await dep.sendTx({
              to: BASIC_MINTER as Address,
              value: common.value.toString(),
              data,
            });
          } else {
            hash = await walletClient?.writeContract(params);
          }
          await publicClient?.waitForTransactionReceipt({
            hash: hash as any,
          });
          setTimeout(() => {
            updateLoading(false);
            try {
              onDone?.();
            } catch (e) {
              console.log(e);
            }
            let id: any;
            id = toast(
              <>
                <div>
                  <P fontSize={1.2}>Your item is minted!</P>
                  <A
                    onClick={e => {
                      toast.dismiss(id);
                      e.preventDefault();
                      route(userPath(currentUser.address));
                    }}
                  >
                    Go to profile
                  </A>
                </div>
              </>,
              {
                duration: 10000,
                position: 'top-center',
                icon: '🎉',
              },
            );
          }, 3000);
        } catch (e: any) {
          if (!e?.message?.includes('User rejected the request')) {
            // alert(e?.message);
          }
          updateLoading(false);
        }
      } else {
        // TODO: Connect wallet?
      }
    },
  };
};
