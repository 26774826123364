import styled from 'styled-components';

export type ColProps = {
  gap?: number;
  justify?: string;
  align?: string;
  flex?: string;
  fullHeight?: boolean;
  padding?: number;
};

export const Col = styled.div<ColProps>`
  display: flex;
  flex-direction: column;
  flex: ${({ flex }) => (flex !== undefined ? flex : '0 1 auto')};
  align-items: ${({ align }) => align ?? 'flex-start'};
  justify-content: ${({ justify }) => justify ?? 'flex-start'};
  gap: ${({ gap }) => gap ?? 0}rem;
  ${({ fullHeight }) => (fullHeight ? `min-height: 100vh;` : '')}
  padding: ${({ padding = 0 }) => padding}rem;
`;
