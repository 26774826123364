import Upload from 'graphql-upload/Upload.mjs';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Address: string;
  Cursor: string;
  Date: string;
  JSON: any;
  Price: number;
  Seed: number;
  Signature: string;
  UUID: any;
  Upload: Upload;
};

export type Artist = {
  avatar?: Maybe<Image>;
  chainID?: Maybe<Scalars['Int']>;
  collections: CollectionConnection;
  description: Scalars['String'];
  draftCollections: DraftCollectionConnection;
  id: Scalars['UUID'];
  name: Scalars['String'];
  published: Scalars['Boolean'];
  user: User;
  userAddress: Scalars['Address'];
  verified: Scalars['Boolean'];
};


export type ArtistCollectionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type ArtistDraftCollectionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type ArtistConnection = {
  edges: Array<Artist>;
  pageInfo: PageInfo;
};

export type AudioFile = {
  extension: Scalars['String'];
  id: Scalars['UUID'];
  name: Scalars['String'];
  size: Scalars['Int'];
  uri: Scalars['String'];
};

export type AudioFileConnection = {
  edges: Array<AudioFile>;
  pageInfo: PageInfo;
};

export enum Chain {
  Base = 'BASE'
}

export type Collection = {
  address: Scalars['Address'];
  artist: Artist;
  artistID: Scalars['UUID'];
  chain: Chain;
  coverImage?: Maybe<Image>;
  description: Scalars['String'];
  featuredToken?: Maybe<Token>;
  featuredTokenID?: Maybe<Scalars['Int']>;
  floorPrice?: Maybe<Scalars['Price']>;
  graph: Scalars['JSON'];
  license: License;
  listedTokens: TokenConnection;
  mintCloseTime?: Maybe<Scalars['Date']>;
  mintPrice: Scalars['Price'];
  mintStartTime?: Maybe<Scalars['Date']>;
  minting: Scalars['Boolean'];
  name: Scalars['String'];
  numberOfTokens: Scalars['Int'];
  numberOfTokensListed: Scalars['Int'];
  numberOfTokensMinted: Scalars['Int'];
  numberOfTokensReserved: Scalars['Int'];
  publishTime?: Maybe<Scalars['Date']>;
  tokens: TokenConnection;
  transfers: SaleConnection;
};


export type CollectionListedTokensArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type CollectionTokensArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type CollectionTransfersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type CollectionConnection = {
  edges: Array<Collection>;
  pageInfo: PageInfo;
};

export type CreateArtistInput = {
  avatarID?: InputMaybe<Scalars['UUID']>;
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type CreateArtistResult = {
  artist: Artist;
};

export type CreateDraftCollectionInput = {
  artistID: Scalars['UUID'];
  graph?: InputMaybe<Scalars['JSON']>;
};

export type CreateDraftCollectionResult = {
  draftCollection: DraftCollection;
};

export type CreateOrLoadCurrentUserInput = {
  address: Scalars['Address'];
};

export type CreateOrLoadCurrentUserResult = {
  user: User;
};

export type CreatePlaylistInput = {
  collectionAddress?: InputMaybe<Scalars['Address']>;
  name: Scalars['String'];
  tokenID?: InputMaybe<Scalars['Int']>;
};

export type CreatePlaylistItemInput = {
  collectionAddress: Scalars['Address'];
  playlistID: Scalars['UUID'];
  tokenID: Scalars['Int'];
};

export type CreatePlaylistItemResult = {
  item: PlaylistItem;
};

export type CreatePlaylistResult = {
  playlist: Playlist;
};

export type CreateSessionInput = {
  address: Scalars['String'];
  signature: Scalars['String'];
  time: Scalars['Date'];
};

export type CreateSessionResult = {
  authToken: Scalars['String'];
  session: Session;
};

export type DeleteArtistInput = {
  id: Scalars['UUID'];
};

export type DeleteArtistResult = {
  id: Scalars['UUID'];
};

export type DeleteDraftCollectionInput = {
  artistMetadataId: Scalars['UUID'];
  id: Scalars['UUID'];
};

export type DeleteDraftCollectionResult = {
  id: Scalars['UUID'];
};

export type DeletePlaylistInput = {
  id: Scalars['UUID'];
};

export type DeletePlaylistItemInput = {
  id: Scalars['UUID'];
};

export type DeletePlaylistItemResult = {
  id: Scalars['UUID'];
};

export type DeletePlaylistResult = {
  id: Scalars['UUID'];
};

export type DraftCollection = {
  artist: Artist;
  artistID: Scalars['UUID'];
  chain: Chain;
  coverImage?: Maybe<Image>;
  description: Scalars['String'];
  graph: Scalars['JSON'];
  id: Scalars['UUID'];
  license: License;
  mintCloseTime?: Maybe<Scalars['Date']>;
  mintPrice: Scalars['Price'];
  mintStartTime?: Maybe<Scalars['Date']>;
  name: Scalars['String'];
  numberOfTokens: Scalars['Int'];
  numberOfTokensReserved: Scalars['Int'];
  published: Scalars['Boolean'];
  publishedAddress?: Maybe<Scalars['String']>;
  totalGraphChunks?: Maybe<Scalars['Int']>;
  totalGraphChunksUploaded?: Maybe<Scalars['Int']>;
};

export type DraftCollectionConnection = {
  edges: Array<DraftCollection>;
  pageInfo: PageInfo;
};

export type FeaturedCollection = {
  collection: Collection;
  priority: Scalars['Int'];
};

export type FeaturedCollectionConnection = {
  edges: Array<FeaturedCollection>;
  pageInfo: PageInfo;
};

export type Image = {
  id: Scalars['UUID'];
  uri: Scalars['String'];
};

export type License = {
  commercialUse: LicenseOption;
  derivativeWorks: LicenseOption;
  publicDomain: LicenseOption;
  sharingRequirements: LicenseOption;
};

export type LicenseInput = {
  commercialUse?: InputMaybe<LicenseOption>;
  derivativeWorks?: InputMaybe<LicenseOption>;
  publicDomain?: InputMaybe<LicenseOption>;
  sharingRequirements?: InputMaybe<LicenseOption>;
};

export enum LicenseOption {
  All = 'ALL',
  Holders = 'HOLDERS',
  None = 'NONE'
}

export type Mutation = {
  createArtist: CreateArtistResult;
  createDraftCollection: CreateDraftCollectionResult;
  createOrLoadCurrentUser: CreateOrLoadCurrentUserResult;
  createPlaylist: CreatePlaylistResult;
  createPlaylistItem: CreatePlaylistItemResult;
  createSession: CreateSessionResult;
  deleteArtist: DeleteArtistResult;
  deleteDraftCollection: DeleteDraftCollectionResult;
  deletePlaylist: DeletePlaylistResult;
  deletePlaylistItem: DeletePlaylistItemResult;
  updateArtist: UpdateArtistResult;
  updateDraftCollection: UpdateDraftCollectionResult;
  updateDraftCollectionGraph: UpdateDraftCollectionGraphResult;
  updatePlaylist: UpdatePlaylistResult;
  updatePlaylistItem: UpdatePlaylistItemResult;
  updateUser: UpdateUserResult;
  uploadAudio: UploadAudioResult;
  uploadImage: UploadImageResult;
};


export type MutationCreateArtistArgs = {
  input: CreateArtistInput;
};


export type MutationCreateDraftCollectionArgs = {
  input: CreateDraftCollectionInput;
};


export type MutationCreateOrLoadCurrentUserArgs = {
  input: CreateOrLoadCurrentUserInput;
};


export type MutationCreatePlaylistArgs = {
  input: CreatePlaylistInput;
};


export type MutationCreatePlaylistItemArgs = {
  input: CreatePlaylistItemInput;
};


export type MutationCreateSessionArgs = {
  input: CreateSessionInput;
};


export type MutationDeleteArtistArgs = {
  input: DeleteArtistInput;
};


export type MutationDeleteDraftCollectionArgs = {
  input: DeleteDraftCollectionInput;
};


export type MutationDeletePlaylistArgs = {
  input: DeletePlaylistInput;
};


export type MutationDeletePlaylistItemArgs = {
  input: DeletePlaylistItemInput;
};


export type MutationUpdateArtistArgs = {
  input: UpdateArtistInput;
};


export type MutationUpdateDraftCollectionArgs = {
  input: UpdateDraftCollectionInput;
};


export type MutationUpdateDraftCollectionGraphArgs = {
  input: UpdateDraftCollectionGraphInput;
};


export type MutationUpdatePlaylistArgs = {
  input: UpdatePlaylistInput;
};


export type MutationUpdatePlaylistItemArgs = {
  input: UpdatePlaylistItemInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationUploadAudioArgs = {
  input: UploadAudioInput;
};


export type MutationUploadImageArgs = {
  input: UploadImageInput;
};

export type PageInfo = {
  endCursor?: Maybe<Scalars['Cursor']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['Cursor']>;
};

export type Playlist = {
  artists: ArtistConnection;
  description: Scalars['String'];
  id: Scalars['String'];
  items: PlaylistItemConnection;
  name: Scalars['String'];
  numberOfItems: Scalars['Int'];
  user: User;
};


export type PlaylistArtistsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type PlaylistItemsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type PlaylistConnection = {
  edges: Array<Playlist>;
  pageInfo: PageInfo;
};

export type PlaylistItem = {
  id: Scalars['UUID'];
  position: Scalars['String'];
  token: Token;
};

export type PlaylistItemConnection = {
  edges: Array<PlaylistItem>;
  pageInfo: PageInfo;
};

export type Query = {
  artist: Artist;
  collection: Collection;
  collections: CollectionConnection;
  draftCollection: DraftCollection;
  featuredCollections: FeaturedCollectionConnection;
  mints: TokenConnection;
  playlist: Playlist;
  session?: Maybe<Session>;
  status?: Maybe<Scalars['String']>;
  token: Token;
  transfers: SaleConnection;
  user: User;
};


export type QueryArtistArgs = {
  chainID: Scalars['Int'];
};


export type QueryCollectionArgs = {
  address: Scalars['Address'];
};


export type QueryCollectionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryDraftCollectionArgs = {
  id: Scalars['UUID'];
};


export type QueryFeaturedCollectionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryMintsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryPlaylistArgs = {
  id: Scalars['UUID'];
};


export type QueryTokenArgs = {
  address: Scalars['Address'];
  tokenID: Scalars['Int'];
};


export type QueryTransfersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type QueryUserArgs = {
  address: Scalars['Address'];
};

export type Sale = {
  from?: Maybe<User>;
  id: Scalars['UUID'];
  price: Scalars['Price'];
  time: Scalars['Date'];
  to: User;
  token: Token;
};

export type SaleConnection = {
  edges: Array<Sale>;
  pageInfo: PageInfo;
};

export type Session = {
  artist: Artist;
  audioFiles: AudioFileConnection;
  expiry: Scalars['Date'];
  user: User;
};


export type SessionArtistArgs = {
  id: Scalars['UUID'];
};


export type SessionAudioFilesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

export type Token = {
  artist: Artist;
  chain: Chain;
  collection: Collection;
  collectionAddress: Scalars['Address'];
  image?: Maybe<Image>;
  lastTransferTime: Scalars['Date'];
  listed: Scalars['Boolean'];
  mintPrice?: Maybe<Scalars['Price']>;
  mintTime: Scalars['Date'];
  minter: User;
  minterID: Scalars['UUID'];
  name: Scalars['String'];
  owner: User;
  ownerAddress: Scalars['String'];
  price?: Maybe<Scalars['Price']>;
  priceExpirationTime?: Maybe<Scalars['Date']>;
  queueID: Scalars['String'];
  seed: Scalars['Seed'];
  tokenID: Scalars['Int'];
  transfers: SaleConnection;
};


export type TokenTransfersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type TokenConnection = {
  edges: Array<Token>;
  pageInfo: PageInfo;
};

export type UpdateArtistInput = {
  avatarID?: InputMaybe<Scalars['UUID']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateArtistResult = {
  artist: Artist;
};

export type UpdateDraftCollectionGraphInput = {
  graph: Scalars['JSON'];
  id: Scalars['UUID'];
};

export type UpdateDraftCollectionGraphResult = {
  id: Scalars['UUID'];
};

export type UpdateDraftCollectionInput = {
  coverImageID?: InputMaybe<Scalars['UUID']>;
  description: Scalars['String'];
  id: Scalars['UUID'];
  license: LicenseInput;
  mintCloseTime?: InputMaybe<Scalars['Date']>;
  mintPrice?: InputMaybe<Scalars['Price']>;
  mintStartTime?: InputMaybe<Scalars['Date']>;
  name: Scalars['String'];
  numberOfTokens: Scalars['Int'];
};

export type UpdateDraftCollectionResult = {
  draftCollection: DraftCollection;
};

export type UpdatePlaylistInput = {
  description: Scalars['String'];
  id: Scalars['UUID'];
  name: Scalars['String'];
};

export type UpdatePlaylistItemInput = {
  id: Scalars['UUID'];
  position: Scalars['String'];
};

export type UpdatePlaylistItemResult = {
  item: PlaylistItem;
};

export type UpdatePlaylistResult = {
  playlist: Playlist;
};

export type UpdateUserInput = {
  avatarID?: InputMaybe<Scalars['UUID']>;
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateUserResult = {
  user: User;
};

export type UploadAudioInput = {
  upload: Scalars['Upload'];
};

export type UploadAudioResult = {
  audio: AudioFile;
};

export type UploadImageInput = {
  upload: Scalars['Upload'];
};

export type UploadImageResult = {
  image: Image;
};

export type User = {
  address: Scalars['Address'];
  artistProfiles: ArtistConnection;
  avatar?: Maybe<Image>;
  description?: Maybe<Scalars['String']>;
  ensName?: Maybe<Scalars['String']>;
  mintedTokens: TokenConnection;
  name?: Maybe<Scalars['String']>;
  ownedTokens: TokenConnection;
  playlists: PlaylistConnection;
};


export type UserArtistProfilesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type UserMintedTokensArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type UserOwnedTokensArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type UserPlaylistsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type CollectionCardFragment = { address: string, name: string, numberOfTokens: number, numberOfTokensMinted: number, mintPrice: number, floorPrice?: number | null, minting: boolean, mintCloseTime?: string | null, artist: { chainID?: number | null, name: string, avatar?: { uri: string } | null }, coverImage?: { uri: string } | null, featuredToken?: { tokenID: number, name: string, queueID: string, seed: number, artist: { chainID?: number | null, name: string }, collection: { address: string, name: string }, image?: { uri: string } | null } | null };

export type CurrentUserFragment = { address: string, name?: string | null, description?: string | null, avatar?: { uri: string } | null };

export type LicenseFragment = { publicDomain: LicenseOption, derivativeWorks: LicenseOption, sharingRequirements: LicenseOption, commercialUse: LicenseOption };

export type MintInfoFragment = { numberOfTokens: number, numberOfTokensMinted: number, mintPrice: number, floorPrice?: number | null, minting: boolean };

export type MintedTokenFragment = { mintTime: string, mintPrice?: number | null, tokenID: number, queueID: string, seed: number, name: string, minter: { address: string, name?: string | null, avatar?: { uri: string } | null }, artist: { chainID?: number | null, name: string }, collection: { address: string, name: string }, image?: { uri: string } | null };

export type PlayableTokenFragment = { tokenID: number, queueID: string, seed: number, name: string, artist: { chainID?: number | null, name: string }, collection: { address: string, name: string }, image?: { uri: string } | null };

export type PlaylistIdentityFragment = { id: string, name: string };

export type SalesTableRowFragment = { id: any, price: number, time: string, from?: { address: string, name?: string | null, avatar?: { uri: string } | null } | null, to: { address: string, name?: string | null, avatar?: { uri: string } | null }, token: { tokenID: number, queueID: string, seed: number, name: string, collection: { address: string, name: string }, artist: { chainID?: number | null, name: string }, image?: { uri: string } | null } };

export type CreateArtistMutationVariables = Exact<{
  input: CreateArtistInput;
}>;


export type CreateArtistMutation = { createArtist: { artist: { id: any } } };

export type CreateDraftCollectionMutationVariables = Exact<{
  input: CreateDraftCollectionInput;
}>;


export type CreateDraftCollectionMutation = { createDraftCollection: { draftCollection: { id: any } } };

export type CreateOrLoadCurrentUserMutationVariables = Exact<{
  input: CreateOrLoadCurrentUserInput;
}>;


export type CreateOrLoadCurrentUserMutation = { createOrLoadCurrentUser: { user: { address: string, name?: string | null, description?: string | null, playlists: { edges: Array<{ id: string, name: string }> }, avatar?: { uri: string } | null } } };

export type CreatePlaylistItemMutationVariables = Exact<{
  input: CreatePlaylistItemInput;
}>;


export type CreatePlaylistItemMutation = { createPlaylistItem: { item: { id: any } } };

export type CreatePlaylistMutationVariables = Exact<{
  input: CreatePlaylistInput;
}>;


export type CreatePlaylistMutation = { createPlaylist: { playlist: { id: string, name: string } } };

export type CreateSessionMutationVariables = Exact<{
  input: CreateSessionInput;
}>;


export type CreateSessionMutation = { createSession: { authToken: string, session: { expiry: string, user: { address: string, name?: string | null, description?: string | null, avatar?: { uri: string } | null } } } };

export type DeleteArtistMutationVariables = Exact<{
  input: DeleteArtistInput;
}>;


export type DeleteArtistMutation = { deleteArtist: { id: any } };

export type DeleteDraftCollectionMutationVariables = Exact<{
  input: DeleteDraftCollectionInput;
}>;


export type DeleteDraftCollectionMutation = { deleteDraftCollection: { id: any } };

export type DeletePlaylistItemMutationVariables = Exact<{
  input: DeletePlaylistItemInput;
}>;


export type DeletePlaylistItemMutation = { deletePlaylistItem: { id: any } };

export type DeletePlaylistMutationVariables = Exact<{
  input: DeletePlaylistInput;
}>;


export type DeletePlaylistMutation = { deletePlaylist: { id: any } };

export type UpdateArtistMutationVariables = Exact<{
  input: UpdateArtistInput;
}>;


export type UpdateArtistMutation = { updateArtist: { artist: { id: any, name: string, description: string, avatar?: { id: any, uri: string } | null } } };

export type UpdateDraftCollectionGraphMutationVariables = Exact<{
  input: UpdateDraftCollectionGraphInput;
}>;


export type UpdateDraftCollectionGraphMutation = { updateDraftCollectionGraph: { id: any } };

export type UpdateDraftCollectionMutationVariables = Exact<{
  input: UpdateDraftCollectionInput;
}>;


export type UpdateDraftCollectionMutation = { updateDraftCollection: { draftCollection: { id: any } } };

export type UpdatePlaylistItemMutationVariables = Exact<{
  input: UpdatePlaylistItemInput;
}>;


export type UpdatePlaylistItemMutation = { updatePlaylistItem: { item: { id: any } } };

export type UpdatePlaylistMutationVariables = Exact<{
  input: UpdatePlaylistInput;
}>;


export type UpdatePlaylistMutation = { updatePlaylist: { playlist: { id: string } } };

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput;
}>;


export type UpdateUserMutation = { updateUser: { user: { address: string, name?: string | null, description?: string | null, avatar?: { uri: string } | null } } };

export type UploadAudioMutationVariables = Exact<{
  input: UploadAudioInput;
}>;


export type UploadAudioMutation = { uploadAudio: { audio: { uri: string, name: string, size: number, extension: string } } };

export type UploadImageMutationVariables = Exact<{
  input: UploadImageInput;
}>;


export type UploadImageMutation = { uploadImage: { image: { id: any, uri: string } } };

export type ArtistCollectionsPageQueryVariables = Exact<{
  chainID: Scalars['Int'];
  before?: InputMaybe<Scalars['Cursor']>;
  after?: InputMaybe<Scalars['Cursor']>;
}>;


export type ArtistCollectionsPageQuery = { artist: { name: string, collections: { edges: Array<{ address: string, name: string, numberOfTokens: number, numberOfTokensMinted: number, mintPrice: number, floorPrice?: number | null, minting: boolean, mintCloseTime?: string | null, artist: { chainID?: number | null, name: string, avatar?: { uri: string } | null }, coverImage?: { uri: string } | null, featuredToken?: { tokenID: number, name: string, queueID: string, seed: number, artist: { chainID?: number | null, name: string }, collection: { address: string, name: string }, image?: { uri: string } | null } | null }>, pageInfo: { hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null } } } };

export type ArtistPageQueryVariables = Exact<{
  chainID: Scalars['Int'];
}>;


export type ArtistPageQuery = { artist: { name: string, description: string, avatar?: { uri: string } | null, collections: { edges: Array<{ address: string, name: string, numberOfTokens: number, numberOfTokensMinted: number, mintPrice: number, floorPrice?: number | null, minting: boolean, mintCloseTime?: string | null, artist: { chainID?: number | null, name: string, avatar?: { uri: string } | null }, coverImage?: { uri: string } | null, featuredToken?: { tokenID: number, name: string, queueID: string, seed: number, artist: { chainID?: number | null, name: string }, collection: { address: string, name: string }, image?: { uri: string } | null } | null }>, pageInfo: { hasNextPage: boolean } } } };

export type CollectionGraphQueryVariables = Exact<{
  address: Scalars['Address'];
}>;


export type CollectionGraphQuery = { collection: { graph: any } };

export type CollectionListingsPageQueryVariables = Exact<{
  address: Scalars['Address'];
  before?: InputMaybe<Scalars['Cursor']>;
  after?: InputMaybe<Scalars['Cursor']>;
}>;


export type CollectionListingsPageQuery = { collection: { name: string, address: string, listedTokens: { edges: Array<{ tokenID: number, price?: number | null, priceExpirationTime?: string | null, ownerAddress: string, queueID: string, seed: number, name: string, owner: { address: string }, artist: { chainID?: number | null, name: string }, collection: { address: string, name: string }, image?: { uri: string } | null }>, pageInfo: { hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null } } } };

export type CollectionPageQueryVariables = Exact<{
  address: Scalars['Address'];
}>;


export type CollectionPageQuery = { collection: { address: string, name: string, description: string, publishTime?: string | null, numberOfTokens: number, numberOfTokensMinted: number, minting: boolean, mintCloseTime?: string | null, mintStartTime?: string | null, mintPrice: number, floorPrice?: number | null, artist: { chainID?: number | null, name: string, avatar?: { uri: string } | null }, coverImage?: { uri: string } | null, license: { publicDomain: LicenseOption, derivativeWorks: LicenseOption, sharingRequirements: LicenseOption, commercialUse: LicenseOption }, tokens: { edges: Array<{ tokenID: number, queueID: string, seed: number, name: string, artist: { chainID?: number | null, name: string }, collection: { address: string, name: string }, image?: { uri: string } | null }>, pageInfo: { hasNextPage: boolean } } } };

export type CollectionSalesPageQueryVariables = Exact<{
  address: Scalars['Address'];
  after?: InputMaybe<Scalars['Cursor']>;
}>;


export type CollectionSalesPageQuery = { collection: { name: string, transfers: { edges: Array<{ id: any, price: number, time: string, token: { tokenID: number, queueID: string, seed: number, name: string, collection: { address: string, name: string }, artist: { chainID?: number | null, name: string }, image?: { uri: string } | null }, from?: { address: string, name?: string | null, avatar?: { uri: string } | null } | null, to: { address: string, name?: string | null, avatar?: { uri: string } | null } }>, pageInfo: { hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null } } } };

export type CollectionTokensPageQueryVariables = Exact<{
  address: Scalars['Address'];
  before?: InputMaybe<Scalars['Cursor']>;
  after?: InputMaybe<Scalars['Cursor']>;
}>;


export type CollectionTokensPageQuery = { collection: { name: string, tokens: { edges: Array<{ tokenID: number, queueID: string, seed: number, name: string, artist: { chainID?: number | null, name: string }, collection: { address: string, name: string }, image?: { uri: string } | null }>, pageInfo: { hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null } } } };

export type CollectionsPageQueryVariables = Exact<{
  before?: InputMaybe<Scalars['Cursor']>;
  after?: InputMaybe<Scalars['Cursor']>;
}>;


export type CollectionsPageQuery = { collections: { edges: Array<{ address: string, name: string, numberOfTokens: number, numberOfTokensMinted: number, mintPrice: number, floorPrice?: number | null, minting: boolean, mintCloseTime?: string | null, artist: { chainID?: number | null, name: string, avatar?: { uri: string } | null }, coverImage?: { uri: string } | null, featuredToken?: { tokenID: number, name: string, queueID: string, seed: number, artist: { chainID?: number | null, name: string }, collection: { address: string, name: string }, image?: { uri: string } | null } | null }>, pageInfo: { hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null } } };

export type EditPlaylistPageQueryVariables = Exact<{
  playlistID: Scalars['UUID'];
}>;


export type EditPlaylistPageQuery = { playlist: { id: string, name: string, description: string, user: { address: string } } };

export type EditUserPageQueryVariables = Exact<{
  address: Scalars['Address'];
}>;


export type EditUserPageQuery = { user: { address: string, name?: string | null, description?: string | null, avatar?: { id: any, uri: string } | null } };

export type HomePageQueryVariables = Exact<{ [key: string]: never; }>;


export type HomePageQuery = { featuredCollections: { edges: Array<{ collection: { address: string, name: string, numberOfTokens: number, numberOfTokensMinted: number, mintPrice: number, floorPrice?: number | null, minting: boolean, mintCloseTime?: string | null, artist: { chainID?: number | null, name: string, avatar?: { uri: string } | null }, coverImage?: { uri: string } | null, featuredToken?: { tokenID: number, name: string, queueID: string, seed: number, artist: { chainID?: number | null, name: string }, collection: { address: string, name: string }, image?: { uri: string } | null } | null } }> }, collections: { edges: Array<{ address: string, name: string, numberOfTokens: number, numberOfTokensMinted: number, mintPrice: number, floorPrice?: number | null, minting: boolean, mintCloseTime?: string | null, artist: { chainID?: number | null, name: string, avatar?: { uri: string } | null }, coverImage?: { uri: string } | null, featuredToken?: { tokenID: number, name: string, queueID: string, seed: number, artist: { chainID?: number | null, name: string }, collection: { address: string, name: string }, image?: { uri: string } | null } | null }> }, mints: { edges: Array<{ mintTime: string, mintPrice?: number | null, tokenID: number, queueID: string, seed: number, name: string, minter: { address: string, name?: string | null, avatar?: { uri: string } | null }, artist: { chainID?: number | null, name: string }, collection: { address: string, name: string }, image?: { uri: string } | null }> } };

export type ListTokenPageQueryVariables = Exact<{
  address: Scalars['Address'];
  tokenID: Scalars['Int'];
}>;


export type ListTokenPageQuery = { token: { tokenID: number, name: string, price?: number | null, priceExpirationTime?: string | null, ownerAddress: string, image?: { uri: string } | null }, collection: { address: string, name: string, artist: { name: string } } };

export type MintsPageQueryVariables = Exact<{
  before?: InputMaybe<Scalars['Cursor']>;
  after?: InputMaybe<Scalars['Cursor']>;
}>;


export type MintsPageQuery = { mints: { edges: Array<{ mintTime: string, mintPrice?: number | null, tokenID: number, queueID: string, seed: number, name: string, minter: { address: string, name?: string | null, avatar?: { uri: string } | null }, artist: { chainID?: number | null, name: string }, collection: { address: string, name: string }, image?: { uri: string } | null }>, pageInfo: { hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null } } };

export type PlaylistPageQueryVariables = Exact<{
  playlistID: Scalars['UUID'];
  before?: InputMaybe<Scalars['Cursor']>;
  after?: InputMaybe<Scalars['Cursor']>;
}>;


export type PlaylistPageQuery = { playlist: { name: string, description: string, user: { address: string, name?: string | null, avatar?: { uri: string } | null }, items: { edges: Array<{ id: any, position: string, token: { tokenID: number, queueID: string, seed: number, name: string, artist: { chainID?: number | null, name: string }, collection: { address: string, name: string }, image?: { uri: string } | null } }>, pageInfo: { hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null } }, artists: { edges: Array<{ chainID?: number | null, name: string, avatar?: { uri: string } | null }> } } };

export type SalesPageQueryVariables = Exact<{
  before?: InputMaybe<Scalars['Cursor']>;
  after?: InputMaybe<Scalars['Cursor']>;
}>;


export type SalesPageQuery = { transfers: { edges: Array<{ id: any, price: number, time: string, token: { tokenID: number, queueID: string, seed: number, name: string, collection: { address: string, name: string }, artist: { chainID?: number | null, name: string }, image?: { uri: string } | null }, from?: { address: string, name?: string | null, avatar?: { uri: string } | null } | null, to: { address: string, name?: string | null, avatar?: { uri: string } | null } }>, pageInfo: { hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null } } };

export type StudioArtistPageQueryVariables = Exact<{
  artistID: Scalars['UUID'];
}>;


export type StudioArtistPageQuery = { session?: { artist: { id: any, name: string, published: boolean, description: string, userAddress: string, avatar?: { uri: string } | null, draftCollections: { edges: Array<{ id: any, name: string, coverImage?: { uri: string } | null }> }, collections: { edges: Array<{ address: string, name: string, coverImage?: { uri: string } | null }> } } } | null };

export type StudioEditArtistPageQueryVariables = Exact<{
  artistID: Scalars['UUID'];
}>;


export type StudioEditArtistPageQuery = { session?: { artist: { id: any, name: string, published: boolean, description: string, userAddress: string, avatar?: { id: any, uri: string } | null } } | null };

export type StudioEditorPageQueryVariables = Exact<{
  draftCollectionID: Scalars['UUID'];
}>;


export type StudioEditorPageQuery = { draftCollection: { id: any, name: string, description: string, numberOfTokens: number, mintStartTime?: string | null, published: boolean, totalGraphChunksUploaded?: number | null, totalGraphChunks?: number | null, mintCloseTime?: string | null, mintPrice: number, graph: any, artist: { id: any, name: string, userAddress: string }, license: { publicDomain: LicenseOption, derivativeWorks: LicenseOption, sharingRequirements: LicenseOption, commercialUse: LicenseOption } }, session?: { audioFiles: { edges: Array<{ uri: string, name: string, size: number, extension: string }> } } | null };

export type StudioHomePageQueryVariables = Exact<{ [key: string]: never; }>;


export type StudioHomePageQuery = { session?: { user: { name?: string | null, address: string, avatar?: { uri: string } | null, artistProfiles: { edges: Array<{ id: any, name: string, avatar?: { uri: string } | null }> } } } | null };

export type StudioPublishPageQueryVariables = Exact<{
  draftCollectionID: Scalars['UUID'];
}>;


export type StudioPublishPageQuery = { draftCollection: { id: any, name: string, description: string, numberOfTokens: number, mintStartTime?: string | null, mintCloseTime?: string | null, published: boolean, publishedAddress?: string | null, totalGraphChunksUploaded?: number | null, totalGraphChunks?: number | null, mintPrice: number, graph: any, coverImage?: { id: any, uri: string } | null, artist: { id: any, chainID?: number | null, name: string }, license: { publicDomain: LicenseOption, derivativeWorks: LicenseOption, sharingRequirements: LicenseOption, commercialUse: LicenseOption } } };

export type TokenPageQueryVariables = Exact<{
  address: Scalars['Address'];
  tokenID: Scalars['Int'];
}>;


export type TokenPageQuery = { token: { name: string, tokenID: number, price?: number | null, priceExpirationTime?: string | null, queueID: string, seed: number, image?: { uri: string } | null, owner: { address: string, name?: string | null, avatar?: { uri: string } | null }, transfers: { edges: Array<{ id: any, price: number, time: string, from?: { address: string, name?: string | null, avatar?: { uri: string } | null } | null, to: { address: string, name?: string | null, avatar?: { uri: string } | null }, token: { tokenID: number, queueID: string, seed: number, name: string, collection: { address: string, name: string }, artist: { chainID?: number | null, name: string }, image?: { uri: string } | null } }>, pageInfo: { hasNextPage: boolean } }, artist: { chainID?: number | null, name: string }, collection: { address: string, name: string } }, collection: { address: string, name: string, artist: { chainID?: number | null, name: string, avatar?: { uri: string } | null } } };

export type UserPageQueryVariables = Exact<{
  address: Scalars['Address'];
}>;


export type UserPageQuery = { user: { address: string, name?: string | null, description?: string | null, avatar?: { id: any, uri: string } | null, ownedTokens: { edges: Array<{ tokenID: number, queueID: string, seed: number, name: string, artist: { chainID?: number | null, name: string }, collection: { address: string, name: string }, image?: { uri: string } | null }>, pageInfo: { hasNextPage: boolean } }, playlists: { edges: Array<{ id: string, name: string }>, pageInfo: { hasNextPage: boolean } } } };

export type UserPlaylistsPageQueryVariables = Exact<{
  address: Scalars['Address'];
  before?: InputMaybe<Scalars['Cursor']>;
  after?: InputMaybe<Scalars['Cursor']>;
}>;


export type UserPlaylistsPageQuery = { user: { address: string, name?: string | null, playlists: { edges: Array<{ id: string, name: string }>, pageInfo: { hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null } } } };

export type UserTokensPageQueryVariables = Exact<{
  address: Scalars['Address'];
  before?: InputMaybe<Scalars['Cursor']>;
  after?: InputMaybe<Scalars['Cursor']>;
}>;


export type UserTokensPageQuery = { user: { name?: string | null, address: string, ownedTokens: { edges: Array<{ collectionAddress: string, tokenID: number, queueID: string, seed: number, name: string, artist: { chainID?: number | null, name: string }, collection: { address: string, name: string }, image?: { uri: string } | null }>, pageInfo: { hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null } } } };
