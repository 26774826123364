import React, { FC } from 'react';
import styled from 'styled-components';
import { License, LicenseOption } from 'backend';

const SVG = styled.svg`
  display: inline;
  vertical-align: middle;
  path {
    fill: var(--text-color);
  }
`;

export type LicenseIconsProps = {
  license: License;
  size: number;
};

const CCIcon = () => (
  <g>
    <title>Creative commons</title>
    <rect width={24} height={24} fill="transparent" />
    <path d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M8.5,14c0.328,0,0.639-0.104,0.899-0.299 l1.202,1.598C9.992,15.758,9.265,16,8.5,16C6.57,16,5,14.43,5,12.5v-1C5,9.57,6.57,8,8.5,8c0.765,0,1.492,0.242,2.101,0.701 l-1.202,1.598C9.139,10.104,8.828,10,8.5,10C7.673,10,7,10.673,7,11.5v1C7,13.327,7.673,14,8.5,14z M16.5,14 c0.328,0,0.639-0.104,0.899-0.299l1.202,1.598C17.992,15.758,17.265,16,16.5,16c-1.93,0-3.5-1.57-3.5-3.5v-1C13,9.57,14.57,8,16.5,8 c0.765,0,1.492,0.242,2.101,0.701l-1.202,1.598C17.139,10.104,16.828,10,16.5,10c-0.827,0-1.5,0.673-1.5,1.5v1 C15,13.327,15.673,14,16.5,14z" />
  </g>
);
const CC0Icon = () => (
  <g>
    <title>Public domain</title>
    <rect width={24} height={24} fill="transparent" />
    <path d="M 12 2 C 6.49 2 2 6.49 2 12 C 2 17.51 6.49 22 12 22 C 17.51 22 22 17.51 22 12 C 22 6.49 17.51 2 12 2 z M 12 7 C 14.24 7 16 8.76 16 11 L 16 13 C 16 15.24 14.24 17 12 17 C 9.76 17 8 15.24 8 13 L 8 11 C 8 8.76 9.76 7 12 7 z M 12 9 C 10.86 9 10 9.86 10 11 L 10 13 C 10 13.21 10.039844 13.400078 10.089844 13.580078 L 12.359375 9.0292969 C 12.249375 9.0092969 12.13 9 12 9 z M 13.910156 10.419922 L 11.640625 14.970703 C 11.750625 14.990703 11.87 15 12 15 C 13.14 15 14 14.14 14 13 L 14 11 C 14 10.79 13.960156 10.599922 13.910156 10.419922 z" />
  </g>
);
const CCSAIcon = () => (
  <g>
    <title>Share alike</title>
    <rect width={24} height={24} fill="transparent" />
    <path d="M12,2C6.49,2,2,6.49,2,12s4.49,10,10,10s10-4.49,10-10S17.51,2,12,2z M16,14c0,2.21-1.79,4-4,4c-1.86,0-3.43-1.27-3.87-3 h2.14c0.35,0.6,0.99,1,1.73,1c1.1,0,2-0.9,2-2v-4c0-1.1-0.9-2-2-2s-2,0.9-2,2h2l-3,3l-3-3h2c0-2.21,1.79-4,4-4s4,1.79,4,4V14z" />
  </g>
);
const CCNDIcon = () => (
  <g>
    <title>No derivatives</title>
    <rect width={24} height={24} fill="transparent" />
    <path d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M17,11H7V9h10V11z M17,15H7v-2h10V15z" />
  </g>
);
const CCNCIcon = () => (
  <g>
    <title>Non commercial</title>
    <rect width={24} height={24} fill="transparent" />
    <path d="M14.72 15.18l4.82 3.39C17.7 20.67 15 22 12 22 6.49 22 2 17.51 2 12c0-1.9.53-3.67 1.45-5.18l9.39 6.98c.05.16.09.33.09.55 0 1.11-.72 1.14-.86 1.14s-1.02.07-1.02-1.64H9.16c0 2.9 2.1 3.13 2.41 3.17V18h1.01v-.98C12.87 16.98 14.33 16.74 14.72 15.18zM22 12c0 1.78-.47 3.46-1.29 4.91l-9.23-6.58c-.07-.16-.11-.34-.12-.55 0-1.13.63-1.17.76-1.17.24 0 .83.22.83 1.59h1.89c0-2.62-1.8-3.03-2.12-3.09V6h-1.01v1.08c-.29.04-1.73.31-2.13 1.85l-4.94-3.7C6.48 3.25 9.1 2 12 2 17.51 2 22 6.49 22 12z" />
  </g>
);

export const LicenseIcons: FC<LicenseIconsProps> = ({
  size,
  license: {
    publicDomain,
    commercialUse,
    derivativeWorks,
    sharingRequirements,
  },
}) => {
  const icons =
    publicDomain === LicenseOption.All
      ? [<CC0Icon />]
      : [
          <CCIcon />,
          commercialUse !== LicenseOption.All && <CCNCIcon />,
          derivativeWorks !== LicenseOption.All && <CCNDIcon />,
          sharingRequirements !== LicenseOption.All && <CCSAIcon />,
        ].filter(v => v);

  return (
    <SVG
      width={icons.length * size}
      height={size}
      viewBox={`0 0 ${icons.length * 24} 24`}
    >
      {icons.map((el, i) => (
        <g key={i} transform={`translate(${i * 24})`}>
          {el}
        </g>
      ))}
    </SVG>
  );
};
