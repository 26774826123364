import { createContext } from 'react';

export type StyleOptions = {
  darkMode: boolean;
  setDarkMode: (darkMode: boolean) => void;
};

export const StyleContext = createContext<StyleOptions>({
  darkMode: false,
  setDarkMode: () => {},
});
