import React, { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import { AUTH_MESSAGE_PREFIX, GRAPHQL_OPERATION_URL } from 'ag-config';
import { SessionContext } from '../contexts';
import { CurrentUserFragment, PlaylistIdentityFragment } from 'backend';
import { useWeb3Store } from '../stores/web3Store';
import { Address } from 'viem';
import { useDep } from '..';

export type SessionProviderProps = {
  children?: ReactNode;
};

type Session = {
  authToken: string;
  expiry: Date;
  session?: {
    user?: { address?: string };
  };
};

const LS_KEY = 'AG_SESSION';

export const SessionProvider: FC<SessionProviderProps> = ({ children }) => {
  const { address, isConnected, isConnecting, walletClient, depJwt } =
    useWeb3Store();
  const dep = useDep();
  const [currentUserState, setCurrentUserState] = useState<{
    loading: boolean;
    error: boolean;
    user: CurrentUserFragment | null;
    playlists: PlaylistIdentityFragment[];
  }>({
    loading: false,
    error: false,
    user: null,
    playlists: [],
  });

  const [sessionState, setSessionState] = useState<{
    loading: boolean;
    error: boolean;
    data: Session | null;
  }>(() => {
    let session = null;
    try {
      const storedString = localStorage.getItem(LS_KEY);
      if (storedString) {
        session = JSON.parse(storedString);
      }
    } catch {}
    return {
      loading: true,
      error: false,
      data: session,
    };
  });

  const clearSession = useCallback(() => {
    setSessionState({ loading: false, error: false, data: null });
    localStorage.removeItem(LS_KEY);
  }, []);

  const validateSessionOnLoad = useCallback(async () => {
    if (typeof window.ethereum !== 'undefined') {
      const state = useWeb3Store.getState();
      // If they connected with dep then don't use wallet extensions
      if (state.depJwt && state.address) {
        useWeb3Store.setState({
          address: state.address,
          isConnected: !!state.address,
        });
        return;
      }
      if (state.address) {
        await state.connect();
      }
      window.ethereum.on('chainChanged', () => {
        state.chainChanged();
      });
      window.ethereum.on('accountsChanged', function (accounts: Address[]) {
        const [account] = accounts;
        useWeb3Store.setState({
          address: account,
          isConnected: !!account,
        });
        window.location.reload();
      });
    }

    if (
      sessionState.data?.session?.user?.address?.toLowerCase() !==
      useWeb3Store.getState().address?.toLowerCase()
    ) {
      setSessionState(state => ({
        ...state,
        data: null,
      }));
      localStorage.removeItem(LS_KEY);
    }
  }, []);

  useEffect(() => {
    validateSessionOnLoad().then(() => {
      setSessionState(state => ({
        ...state,
        loading: false,
      }));
    });
  }, []);

  useEffect(() => {
    if (isConnected) {
      fetch(GRAPHQL_OPERATION_URL, {
        method: 'POST',
        body: JSON.stringify({
          name: 'CreateOrLoadCurrentUserMutation',
          vars: { input: { address: address?.toLowerCase() } },
        }),
      })
        .then(resp => resp.json())
        .then(({ data }) => {
          const {
            playlists: { edges: playlists },
            ...user
          } = data.createOrLoadCurrentUser.user;
          setCurrentUserState({
            loading: false,
            error: false,
            user,
            playlists,
          });
        })
        .catch(() => {
          setCurrentUserState({
            loading: false,
            error: true,
            user: null,
            playlists: [],
          });
        });
    }
  }, [address, isConnected]);

  return (
    <SessionContext.Provider
      value={{
        loading:
          isConnecting || currentUserState.loading || sessionState.loading,
        currentUser: currentUserState.user,
        mergeCurrentUserChanges: changes => {
          if (currentUserState.user === null) return;
          setCurrentUserState({
            ...currentUserState,
            user: {
              ...currentUserState.user,
              ...changes,
            },
          });
        },
        playlists: currentUserState.playlists,
        mergePlaylistsChanges: playlists => {
          setCurrentUserState({
            ...currentUserState,
            playlists,
          });
        },
        clearSession,
        getSession: async () => {
          if (!walletClient && !depJwt) return;
          if (sessionState.data != null) return sessionState.data;

          setSessionState({ loading: true, error: false, data: null });
          const time = Date.now();

          let signature;
          const message = `${AUTH_MESSAGE_PREFIX}\n${time}`;

          if (depJwt) {
            signature = await dep.signMessage(message);
          } else if (walletClient) {
            signature = await walletClient.signMessage({
              account: address!,
              message,
            });
          }

          return fetch(GRAPHQL_OPERATION_URL, {
            method: 'POST',
            body: JSON.stringify({
              name: 'CreateSessionMutation',
              vars: { input: { address, time, signature } },
            }),
          })
            .then(resp => resp.json())
            .then(res => {
              const { data } = res;
              localStorage.setItem(LS_KEY, JSON.stringify(data.createSession));
              setSessionState({
                loading: false,
                error: false,
                data: data.createSession,
              });
              return data.createSession;
            })
            .catch((e: any) => {
              console.log(e);
              setSessionState({ loading: false, error: true, data: null });
              return null;
            });
        },
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};
