import React, { FC } from 'react';
import { Grid, GridProps } from 'ag-components';
import { CollectionCardFragment } from 'backend';
import { CollectionCard } from './CollectionCard';

export type CollectionGridProps = {
  collections: CollectionCardFragment[];
  cols?: GridProps['cols'];
  minSize?: GridProps['minSize'];
  maxVisibleCells?: GridProps['maxVisibleCells'];
};

export const CollectionGrid: FC<CollectionGridProps> = ({
  cols,
  maxVisibleCells,
  minSize,
  collections,
}) => (
  <Grid
    cols={cols}
    maxVisibleCells={maxVisibleCells}
    minSize={minSize}
    style={{ margin: '0 -1rem' }}
  >
    {collections.map(collection => (
      <CollectionCard collection={collection} key={collection.address} />
    ))}
  </Grid>
);
