import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { lineHeight } from '../style';

export type SVGIconProps = {
  onClick?: () => void;
  size?: number;
  disabled?: boolean;
  children?: ReactNode;
  role?: string;
};

const SVG = styled.svg`
  rect,
  path,
  polygon {
    fill: var(
      ${({ disabled }: { disabled?: boolean }) =>
        disabled ? '--input-disabled-text-color' : '--play-button-color'}
    );
  }
  &:hover rect,
  &:hover path,
  &:hover polygon {
    fill: var(
      ${({ disabled }: { disabled?: boolean }) =>
        disabled ? '--input-disabled-text-color' : '--play-button-hover-color'}
    );
  }
`;

export const SVGIcon: FC<SVGIconProps> = ({
  size = 1.25 * lineHeight,
  onClick,
  disabled,
  children,
  role = 'button',
}) => (
  <SVG
    role={role}
    tabIndex={disabled ? undefined : 0}
    onClick={
      disabled || !onClick
        ? undefined
        : e => {
            e.stopPropagation();
            e.preventDefault();
            onClick();
          }
    }
    onKeyDown={
      onClick &&
      (e => {
        if (e.key === 'Enter') onClick();
      })
    }
    width={size}
    height={size}
    viewBox="0 0 24 24"
    disabled={disabled}
  >
    {children}
  </SVG>
);
