import React from 'react';
import styled, { keyframes } from 'styled-components';

const ldsRingAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const RingContainer = styled.div`
  display: inline-block;
  position: relative;
  width: 16px;
  height: 16px;
`;

const RingDiv = styled.div<{ delay: string }>`
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  border: 3px solid var(--background-color);
  border-radius: 50%;
  animation: ${ldsRingAnimation} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--background-color) transparent transparent transparent;
  animation-delay: ${props => props.delay};
`;

export const SpinningLoader: React.FC = () => {
  return (
    <RingContainer>
      <RingDiv delay="-0.45s" />
      <RingDiv delay="-0.3s" />
      <RingDiv delay="-0.15s" />
      <RingDiv delay="0s" />
    </RingContainer>
  );
};
