import styled from 'styled-components';

export const C1 = styled.div`
  flex: 0 0 3rem;
`;
export const C2 = styled.div`
  flex: 1 1;
`;
export const C3 = styled.div`
  flex: 2 1;
`;
export const C4 = styled.div`
  flex: 0 0 1rem;
  padding-right: 1rem;
`;
