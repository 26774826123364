import React from 'react';
import {
  H2,
  RouteHandler,
  LoadingScaffold,
  ErrorScaffold,
  useQuery,
  Section,
} from 'ag-components';
import {
  PlatformLayout,
  CollectionGrid,
  PaginationControls,
} from '../components';
import { CollectionsPageQuery, CollectionsPageQueryVariables } from 'backend';
import { collectionsPath, connectionArgs } from '../utils';
import { useAutoQueue } from '../hooks';

export const CollectionsPage: RouteHandler = ({ searchParams }) => {
  const [loading, error, data] = useQuery<
    CollectionsPageQuery,
    CollectionsPageQueryVariables
  >('CollectionsPageQuery', connectionArgs(searchParams));

  useAutoQueue(data, data => data.collections.edges.map(c => c.featuredToken));

  if (loading) return <LoadingScaffold />;
  if (error || data === null) return <ErrorScaffold />;

  const { collections } = data;

  return (
    <PlatformLayout breadcrumbs={[['Collections', collectionsPath()]]}>
      <Section>
        <H2>Collections</H2>
        <CollectionGrid minSize={24} collections={collections.edges} />
        <PaginationControls pageInfo={collections.pageInfo} />
      </Section>
    </PlatformLayout>
  );
};
