import { mulberry32 } from '.';
import {
  ProbabilityDistribution,
  ProbabilityDistributionType,
  ValueType,
  Value,
} from '../types';

export const resolveValue = (
  v: Value,
  rng: () => number = Math.random,
): any => {
  // consistently take a value from the rng and use it to seed a new prng so
  // that changing between values types that require different number of ranomd
  // values doesn't affect the resolution of later values
  const stepRng = mulberry32(rng() * 4294967296);

  switch (v.type) {
    case ValueType.Fixed:
      return v.value;
    case ValueType.RandomNumber:
      return resolveRandomNumberValue(v.distribution, stepRng);
    case ValueType.RandomInteger:
      return resolveRandomIntegerValue(v.distribution, stepRng);
    case ValueType.RandomMember:
      return resolveRandomMemberValue(v.items, stepRng);
    case ValueType.WeightedRandomMember:
      return resolveWeightedRandomMemberValue(v.items, stepRng);
    case ValueType.WeightedRandomSubset:
      return resolveWeightedRandomSubsetValue(v.items, stepRng);
    case ValueType.RandomNumberSequence:
      return resolveRandomNumberSequenceValue(
        v.distribution,
        v.length,
        stepRng,
      );
    case ValueType.WeightedRandomSequence:
      return resolveWeightedRandomSequenceValue(v.items, v.length, stepRng);
    case ValueType.RandomMatrix:
      return resolveRandomMatrixValue(
        v.dimensions,
        v.sparsity,
        v.distribution,
        stepRng,
      );
    default:
      throw new Error(`unrecognized value type '${(v as any).type}`);
  }
};

export const resolveRandomNumberValue = (
  d: ProbabilityDistribution,
  rng: () => number,
): number => {
  switch (d.type) {
    case ProbabilityDistributionType.Uniform:
      return d.min + rng() * (d.max - d.min);
    case ProbabilityDistributionType.Exponential:
      return d.min + Math.pow(rng(), d.exponent) * (d.max - d.min);
    case ProbabilityDistributionType.Gaussian:
      let u;
      do {
        u = rng();
      } while (u === 0);
      return (
        d.mean +
        d.variance *
          Math.sqrt(-2.0 * Math.log(u)) *
          Math.cos(2.0 * Math.PI * rng())
      );
    default:
      throw new Error(
        `unrecognized probability distribution type '${(d as any).type}`,
      );
  }
};

export const resolveRandomIntegerValue = (
  d: ProbabilityDistribution,
  rng: () => number,
): number => {
  switch (d.type) {
    case ProbabilityDistributionType.Uniform:
      return Math.floor(d.min + rng() * (d.max + 1 - d.min));
    case ProbabilityDistributionType.Exponential:
      return Math.floor(
        d.min + Math.pow(rng(), d.exponent) * (d.max + 1 - d.min),
      );
    case ProbabilityDistributionType.Gaussian:
      let u;
      do {
        u = rng();
      } while (u === 0);
      return Math.round(
        d.mean +
          d.variance *
            Math.sqrt(-2.0 * Math.log(u)) *
            Math.cos(2.0 * Math.PI * rng()),
      );
    default:
      throw new Error(
        `unrecognized probability distribution type '${(d as any).type}`,
      );
  }
};

export const resolveRandomMemberValue = <T>(items: T[], rng: () => number): T =>
  items[Math.floor(rng() * items.length)];

const divisions = (items: [v: unknown, weight: number][]): number[] =>
  items.reduce(
    (memo, [, weight], i) => ((memo[i] = (memo[i - 1] ?? 0) + weight), memo),
    new Array(items.length),
  );

const weightedRandomIndex = (
  divisions: number[],
  rng: () => number,
): number => {
  let min = 0;
  let max = divisions.length - 1;
  const v = rng() * divisions[max];
  while (max > min) {
    const mid = Math.floor((max + min) / 2);
    if (divisions[mid] < v) {
      min = mid + 1;
    } else if ((divisions[mid - 1] ?? 0) < v) {
      min = max = mid;
    } else {
      max = mid - 1;
    }
  }
  return min;
};

export const resolveWeightedRandomMemberValue = <T>(
  items: [T, number][],
  rng: () => number,
): T => {
  const d = divisions(items);
  const i = weightedRandomIndex(d, rng);
  return items[i][0];
};

export const resolveWeightedRandomSubsetValue = <T>(
  items: [value: T, probability: number][],
  rng: () => number,
): T[] =>
  items.reduce((memo, [v, p]) => (p > rng() ? [...memo, v] : memo), [] as T[]);

export const resolveRandomNumberSequenceValue = (
  d: ProbabilityDistribution,
  l: ProbabilityDistribution,
  rng: () => number,
): number[] => {
  const length = Math.max(0, resolveRandomIntegerValue(l, rng));
  const result = new Array(length);
  for (let i = 0; i < length; i++) {
    result[i] = resolveRandomNumberValue(d, rng);
  }
  return result;
};

export const resolveWeightedRandomSequenceValue = <T>(
  items: [T, number][],
  l: ProbabilityDistribution,
  rng: () => number,
): T[] => {
  if (items.length === 0) return [];

  const d = divisions(items);
  const length = Math.max(0, resolveRandomIntegerValue(l, rng));
  const result = new Array(length);
  for (let i = 0; i < length; i++) {
    const j = weightedRandomIndex(d, rng);
    result[i] = items[j][0];
  }
  return result;
};

// returns a multi dimensional number array w/ number of dimensions equal to the
// length of the dimensions argument
export const resolveRandomMatrixValue = (
  [currentDimension, ...dimensions]: ProbabilityDistribution[],
  _sparsity: ProbabilityDistribution,
  d: ProbabilityDistribution,
  rng: () => number,
): any => {
  const currentDimensionLength = resolveRandomIntegerValue(
    currentDimension,
    rng,
  );
  const result = new Array(currentDimensionLength);
  if (dimensions.length > 0) {
    for (let i = 0; i < result.length; i++) {
      result[i] = resolveRandomMatrixValue(dimensions, _sparsity, d, rng);
    }
  } else {
    for (let i = 0; i < result.length; i++) {
      result[i] = resolveRandomNumberValue(d, rng);
    }
  }
  return result;
};
