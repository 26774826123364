import React, { FC, FormEvent, useContext, useState } from 'react';
import {
  Button,
  Col,
  ErrorScaffold,
  ImageInput,
  Input,
  LoadingScaffold,
  RoutingContext,
  TextArea,
  useMutation,
} from 'ag-components';
import { UpdateUserMutation, UpdateUserMutationVariables } from 'backend';
import { userPath } from '../../utils';

export type EditUserFormProps = {
  user: {
    address: string;
    name?: string | null | undefined;
    description?: string | null | undefined;
    avatar?:
      | {
          id: string;
          uri: string;
        }
      | null
      | undefined;
  };
};

export const EditUserForm: FC<EditUserFormProps> = ({ user }) => {
  const [updateUser, loading, error] = useMutation<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >('UpdateUserMutation', true);
  const { route } = useContext(RoutingContext);

  const [name, setName] = useState(user.name);
  const [description, setDescription] = useState(user.description);
  const [avatar, setAvatar] = useState(user.avatar);

  if (loading) return <LoadingScaffold />;
  if (error) return <ErrorScaffold />;

  return (
    <Col
      gap={2}
      as="form"
      onSubmit={async (e: FormEvent) => {
        e.preventDefault();
        if (!loading) {
          const result = await updateUser({
            input: { name, description, avatarID: avatar?.id },
          });
          if (result) {
            route(userPath(user.address));
          }
        }
      }}
    >
      <Col as="label" gap={0.5}>
        Avatar
        <ImageInput
          name="avatar"
          value={avatar}
          onChange={value => setAvatar(value)}
          disabled={loading}
        />
      </Col>
      <Col as="label" gap={0.5}>
        Name
        <Input
          name="name"
          value={name ?? ''}
          onChange={e => setName(e.target.value)}
          disabled={loading}
        />
      </Col>
      <Col as="label" gap={0.5}>
        Description
        <TextArea
          name="description"
          value={description ?? ''}
          onChange={e => setDescription(e.target.value)}
          disabled={loading}
        />
      </Col>
      <Button type="submit" disabled={loading}>
        Save
      </Button>
    </Col>
  );
};
