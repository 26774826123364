import React, { FC } from 'react';
import { H2, Footer, Section, TextDoc } from 'ag-components';
import { PlatformLayout } from '../../components';
import { aboutPath, homePath, privacyPath, termsPath } from '../../utils';
import { default as content } from './content.txt';

export const TermsPage: FC = () => (
  <PlatformLayout smallMargin>
    <Section>
      <H2 as="h1">Terms</H2>
      <TextDoc text={content} />
    </Section>
    <Section>
      <H2>More</H2>
      <Footer
        links={[
          ['home', homePath()],
          ['about', aboutPath()],
          ['terms', termsPath()],
          ['privacy', privacyPath()],
        ]}
      />
    </Section>
  </PlatformLayout>
);
