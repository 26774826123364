import React, { FC } from 'react';
import styled from 'styled-components';
import {
  Col,
  UserTag,
  H3,
  P,
  breakpointQueries,
  formatAddress,
} from 'ag-components';
import { PlaylistPageQuery } from 'backend';
import { artistPath, userPath } from '../../utils';

export type PlaylistInfoProps = {
  data: PlaylistPageQuery;
};

const InfoCol = styled.div`
  display: flex;
  flex-direction: column;
  @media ${breakpointQueries.lg} {
    gap: 3rem;
    flex: 0 0 24rem;
    padding-top: 4.5rem;
    padding-bottom: 1.5rem;
  }
`;
const FeaturedArtists = styled(Col)`
  display: none;
  @media ${breakpointQueries.lg} {
    display: flex;
  }
`;

export const PlaylistInfo: FC<PlaylistInfoProps> = ({
  data: {
    playlist: { user, description, artists },
  },
}) => (
  <InfoCol>
    <Col gap={2}>
      <Col gap={0.5}>
        <P>Created by</P>
        <P>
          <UserTag
            avatarURI={user.avatar?.uri}
            href={userPath(user.address)}
            name={user.name ?? formatAddress(user.address)}
          />
        </P>
      </Col>
      <P>{description}</P>
    </Col>
    <FeaturedArtists gap={1.5}>
      <H3>Featured Artists</H3>
      {artists.edges.map(artist => (
        <UserTag
          avatarURI={artist.avatar?.uri}
          href={artistPath(artist.chainID!)}
          name={artist.name}
        />
      ))}
    </FeaturedArtists>
  </InfoCol>
);
