import React, { FC } from 'react';
import { SVGIcon } from './SVGIcon';

export type PreviousButtonProps = {
  onClick?: () => void;
  size?: number;
  disabled?: boolean;
};

export const PreviousButton: FC<PreviousButtonProps> = ({
  size,
  onClick,
  disabled,
}) => (
  <SVGIcon size={size} onClick={onClick} disabled={disabled}>
    <title>previous</title>
    <path d="M11 18V6l-8.5 6 8.5 6zm.5-6l8.5 6V6l-8.5 6z" />
  </SVGIcon>
);
