export const fontSize = 13;
export const lineHeight = 1.25 * fontSize;
export const maxTextContentWidth = 550;
export const maxBlockContentWidth = 700;

export const strokeWidth = 2;

export const inputWidth = 18 * fontSize;

export const breakpoints: { [size: string]: number } = {
  xs: 0,
  sm: 440,
  md: 640,
  lg: 993,
};

export const breakpointQueries: { [size: string]: string } = {
  xs: `(max-width: ${breakpoints.sm - 1}px)`,
  sm: `(min-width: ${breakpoints.sm}px) and (max-width: ${
    breakpoints.md - 1
  }px)`,
  md: `(min-width: ${breakpoints.md}px) and (max-width: ${
    breakpoints.lg - 1
  }px)`,
  lg: `(min-width: ${breakpoints.lg}px)`,
};

export const cornerRadius = 1 / 3;
export const outlineOffset = 1 / 3;
export const ioRadius = 1 / 3;
export const outlineExpansion = outlineOffset * 2;

export const halfStrokeWidth = strokeWidth / 2;
export const columnPadding = 1.5 * fontSize;
export const columnHandleWidth = lineHeight / 2;
export const columnHandleHeight = 10 * lineHeight;

export const meterWidth = 96;
