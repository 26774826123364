import React, { CSSProperties, useCallback } from 'react';
import styled from 'styled-components';
import { useWeb3Store } from '../stores';
import { A } from '.';
import { switchToNewChain } from '../utils/switchChain';
import { CHAIN_ID } from 'ag-web3';

const Warning = styled.div`
  height: 50px;
  width: 100%;
  padding: 5px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: var(--error-text-color);
`;

let expectedChainId: any = CHAIN_ID;

export function ChainIDWarning({
  spanProps,
  aProps,
  callback,
}: {
  spanProps?: { style?: CSSProperties };
  aProps?: { style?: CSSProperties };
  callback?: () => void;
}) {
  const chainId = useWeb3Store(state => state.chainId);
  const switchChain = useCallback(e => {
    e.preventDefault();
    switchToNewChain(expectedChainId, callback);
  }, []);

  return (
    <>
      {chainId && chainId != expectedChainId && (
        <Warning>
          <span {...spanProps}>
            Unlooped is built on the Base network. You are connected to the
            wrong chain.{' '}
            <A {...aProps} onClick={switchChain}>
              Click here to switch to Base
            </A>
          </span>
        </Warning>
      )}
    </>
  );
}
