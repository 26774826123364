import React, { FC, ReactNode } from 'react';
import { A } from './A';
import { Link } from './Link';
import { Row } from './Row';

export type BreadcrumbsProps = {
  items: [text: ReactNode, url: string | null][];
};

export const Breadcrumbs: FC<BreadcrumbsProps> = ({ items }) => (
  <Row gap={1} flexWrap>
    {items.length > 1 &&
      items.map(([text, path], i) => (
        <React.Fragment key={i}>
          {i > 0 && <span> / </span>}
          {path ? (
            path.startsWith('//') ? (
              <A href={path}>{text}</A>
            ) : (
              <Link href={path}>{text}</Link>
            )
          ) : (
            text
          )}
        </React.Fragment>
      ))}
  </Row>
);
