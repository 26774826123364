import React, { FC, ReactNode, useContext, useState } from 'react';
import styled from 'styled-components';
import { StyleContext, useMediaQuery } from '..';
import Modal from './Modal';
import { Helmet } from 'react-helmet';

export type LayoutProps = {
  left?: ReactNode;
  right?: ReactNode;
  compact?: boolean;
};

const Container = styled.div`
  width: 100vw;
  height: calc(100vh - env(safe-area-inset-bottom));
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: stretch;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
`;

const Header = styled.header<{ compact?: boolean }>`
  padding: ${({ compact }) => (compact ? 0.5 : 1)}rem 1.5rem;
  gap: 1.5rem;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  justify-content: space-between;
  flex: 0 0 auto;
`;

const MenuIcon = styled.div`
  width: 22px;
  height: auto;

  & svg {
    width: 100%;
    height: auto;
  }
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  & > * {
    flex-wrap: wrap;
  }
`;

export const Layout: FC<LayoutProps> = ({ left, right, children, compact }) => {
  const isSmall = useMediaQuery('(max-width: 730px)');
  const [menuOpen, updateMenuOpen] = useState(false);
  const { darkMode } = useContext(StyleContext);
  return (
    <>
      <Helmet>
        <title>Unlooped - The generative music platform</title>

        <meta
          name="description"
          content="Unlooped is a platform for creating, collecting, and exploring generative music. It includes software for creating music, a marketplace for collecting and trading music, and a new standardized format."
        />

        {/* <meta property="og:title" content={name} />
        <meta
          property="og:description"
          content={truncateString(description, 150)}
        />
        <meta property="og:image" content={coverImage?.uri} />
        <meta property="og:url" content={window.location.href} />
        <meta name="twitter:card" content="summary_large_image" /> */}
      </Helmet>

      <Container>
        {(left || right) && (
          <Header compact={compact}>
            <div>{left}</div>
            {isSmall && (
              <div
                onClick={() => updateMenuOpen(!menuOpen)}
                style={{ padding: '0 10px' }}
              >
                <MenuIcon
                  dangerouslySetInnerHTML={{
                    __html: `<svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" fill="${
                      darkMode ? 'white' : 'black'
                    }" viewBox="0 0 448 512"><path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"/></svg>`,
                  }}
                />
              </div>
            )}
            {menuOpen && (
              <Modal onClose={() => updateMenuOpen(false)} isOpen={menuOpen}>
                <Col>{right}</Col>
              </Modal>
            )}
            {!isSmall && <div>{right}</div>}
          </Header>
        )}
        {children}
      </Container>
    </>
  );
};
