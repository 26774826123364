import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  A,
  Button,
  ButtonProps,
  formatAddress,
  SessionContext,
  useDep,
  UserTag,
  useWeb3Store,
} from 'ag-components';
import Modal from '../Modal';
import metamaskImg from '../../assets/metamask-fox.svg';
import coinbaseImg from '../../assets/coinbase-wallet.webp';
import depLogo from '../../assets/dep-logo-sm.jpg';
import styled from 'styled-components';
import { getCoinbaseWalletProvider } from './providers';
import { DOCS_URL } from 'ag-config';
import { truncateString } from '../../utils/truncateString';
import { off, on } from '@depdev/dep-core';

export type ConnectWalletButtonsProps = {
  href?: string;
  children?: React.ReactNode;
  onConnect?: any;
  connectBtnProps?: ButtonProps;
  showDep?: boolean;
};

const StyledButtonRow = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 20px;
  box-shadow: rgba(0, 0, 0, 0.09) 2px 2px 8px;
  margin-bottom: 16px;
  & img {
    display: inline-block;
    margin-right: 10px;
    height: 34px;
    width: auto;
  }
`;

const ButtonRow = ({
  onClick,
  src,
  title,
}: {
  onClick: () => void;
  src: string;
  title: string;
}) => {
  return (
    <StyledButtonRow onClick={onClick}>
      <img src={src} />
      <p>{title}</p>
    </StyledButtonRow>
  );
};

export const ConnectWalletButton = ({
  href = '/',
  children,
  onConnect,
  showDep = true,
  connectBtnProps = {
    children: 'Log in',
  },
}: ConnectWalletButtonsProps) => {
  const { currentUser } = useContext(SessionContext);
  const [showModal, updateShowModal] = useState(false);
  const [connectionError, updateConnectionError] = useState('');
  const { isConnected, connect, address, isConnecting } = useWeb3Store();
  const dep = useDep();

  // TODO: jwt is never changed from its initial value, do we need it?
  const [jwt] = useState('');
  useEffect(() => {
    const onAuth = async (evt: CustomEvent<string>) => {
      const data = await dep.account();
      useWeb3Store.setState({
        depJwt: evt.detail,
        address: data.wallets.find(
          (w: { isDefault: boolean; address: string }) => w.isDefault,
        )?.address as `0x${string}`,
        isConnected: true,
      });

      console.log(data);
    };
    const onMessage = (event: any) => {
      try {
        const { type } = JSON.parse(event.data);
        if (type === 'REQUEST_JWT') {
          console.log('GOT REQUEST SEND');
          event.source.postMessage(
            JSON.stringify({ type: 'JWT', jwt }),
            event.origin,
          );
        }
      } catch (e) {
        // console.log(e);
      }
    };

    window.addEventListener('message', onMessage);
    on('auth', onAuth);

    return () => {
      window.removeEventListener('message', onMessage);
      off('auth', onAuth);
    };
  }, [jwt]);

  const connectFn = useCallback(
    async (provider: any) => {
      updateConnectionError('');
      await connect(provider);
      updateShowModal(false);
      onConnect?.();
    },
    [onConnect],
  );

  return (
    <>
      <Modal isOpen={showModal} onClose={() => updateShowModal(false)}>
        <div style={{ padding: '40px 0' }}>
          {showDep && (
            <ButtonRow
              title="Sign in with Email"
              onClick={() => {
                dep.triggerSignIn({ connect: true });
                updateShowModal(false);
                onConnect?.();
              }}
              src={depLogo}
            />
          )}
          <ButtonRow
            onClick={async () => {
              const provider = getCoinbaseWalletProvider();
              connectFn(provider);
            }}
            src={coinbaseImg}
            title="Coinbase Wallet"
          />
          <ButtonRow
            onClick={async () => {
              const provider =
                window.ethereum?.providers?.find((p: any) => !!p.isMetaMask) ??
                window.ethereum;
              if (!provider) {
                return updateConnectionError(
                  "Metamask not found. Make sure it's installed, or try using Coinbase Wallet.",
                );
              }
              connectFn(provider);
            }}
            src={metamaskImg}
            title="Metamask"
          />
          {connectionError && <p style={{ color: 'red' }}>{connectionError}</p>}
        </div>
        <p>
          Need help?{' '}
          <A target="_blank" href={`${DOCS_URL}/#Installing_A_Crypto_Wallet`}>
            Learn how to get a wallet here
          </A>
          .
        </p>
      </Modal>
      {isConnecting || (isConnected && currentUser == null) ? (
        <span>...</span>
      ) : isConnected && currentUser ? (
        <>
          {!children && (
            <UserTag
              avatarURI={currentUser.avatar?.uri}
              href={href}
              name={
                currentUser.name
                  ? truncateString(currentUser.name, 20)
                  : null ??
                    // ensName ??
                    (address && formatAddress(address)) ??
                    ''
              }
            />
          )}
          {children}
        </>
      ) : (
        <Button
          as="button"
          onClick={e => {
            e.preventDefault();
            updateShowModal(true);
          }}
          {...connectBtnProps}
        />
      )}
    </>
  );
};
