// @ts-ignore
let CONFIG = {
  localhost: {
    GRAPHQL_OPERATION_URL:
      process.env.GRAPHQL_OPERATION_URL || 'http://localhost:4000/operation',
    // GRAPHQL_OPERATION_URL: 'https://api.unlpd.net/operation',
    AUTH_MESSAGE_PREFIX:
      'Please sign this message to authenticate with the unlooped platform',
    STUDIO_URL: 'http://localhost:8082',
    PLATFORM_URL: 'http://localhost:8080',
    DOCS_URL: 'http://localhost:8084',
  },
  'unlpd.net': {
    GRAPHQL_OPERATION_URL: 'https://api.unlpd.net/operation',
    AUTH_MESSAGE_PREFIX:
      'Please sign this message to authenticate with the unlooped platform',
    STUDIO_URL: 'https://studio.unlpd.net',
    PLATFORM_URL: 'https://www.unlpd.net',
    DOCS_URL: 'https://docs.unlpd.net',
  },
  'unlooped.xyz': {
    GRAPHQL_OPERATION_URL: 'https://api.unlooped.xyz/operation',
    AUTH_MESSAGE_PREFIX:
      'Please sign this message to authenticate with the unlooped platform',
    STUDIO_URL: 'https://studio.unlooped.xyz',
    PLATFORM_URL: 'https://www.unlooped.xyz',
    DOCS_URL: 'https://docs.unlooped.xyz',
  },
}[window.location.hostname.split('.').slice(-2).join('.')];

if (CONFIG === undefined) throw new Error('no config found for domain');

export const GRAPHQL_OPERATION_URL = CONFIG.GRAPHQL_OPERATION_URL;
export const AUTH_MESSAGE_PREFIX = CONFIG.AUTH_MESSAGE_PREFIX;
export const STUDIO_URL = CONFIG.STUDIO_URL;
export const PLATFORM_URL = CONFIG.PLATFORM_URL;
export const DOCS_URL = CONFIG.DOCS_URL;
