import React from 'react';
import {
  RouteHandler,
  LoadingScaffold,
  ErrorScaffold,
  useQuery,
  Section,
  H2,
  useMediaQuery,
} from 'ag-components';
import { PlatformLayout, TokenTable, PaginationControls } from '../components';
import {
  CollectionTokensPageQuery,
  CollectionTokensPageQueryVariables,
} from 'backend';
import {
  collectionPath,
  collectionTokensPath,
  collectionsPath,
  connectionArgs,
} from '../utils';
import { useAutoQueue } from '../hooks';

export const CollectionTokensPage: RouteHandler = ({
  pathParams: { address },
  searchParams,
}) => {
  const [loading, error, data] = useQuery<
    CollectionTokensPageQuery,
    CollectionTokensPageQueryVariables
  >('CollectionTokensPageQuery', {
    address,
    ...connectionArgs(searchParams),
  });
  const isSmall = useMediaQuery('(max-width: 730px)');

  useAutoQueue(data, data => data.collection.tokens.edges);

  if (loading) return <LoadingScaffold />;
  if (error || data === null) return <ErrorScaffold />;

  const {
    collection: { name, tokens },
  } = data;

  return (
    <PlatformLayout
      breadcrumbs={[
        ['collections', collectionsPath()],
        [name, collectionPath(address)],
        ['tokens', collectionTokensPath(address)],
      ]}
    >
      <Section>
        <H2 as="h1">{name}</H2>
        <TokenTable cols={isSmall ? 1 : 2} tokens={tokens.edges} />
        <PaginationControls pageInfo={tokens.pageInfo} />
      </Section>
    </PlatformLayout>
  );
};
