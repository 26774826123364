import {
  DependencyList,
  useContext,
  useEffect,
  useState,
  useCallback,
  useRef,
} from 'react';
import { SessionContext } from '../contexts';
import { graphqlOperation } from '../utils';

export const useQuery = <Result, Variables>(
  name: string,
  vars: Variables,
  deps: DependencyList = [],
  useSession: boolean = false,
  doFetch: boolean = true,
): [
  loading: boolean,
  error: boolean,
  data: Result | null,
  mergeChanges: (changes: Partial<Result>) => void,
  refetch: () => Promise<void>,
] => {
  const [{ loading, error, data }, setState] = useState<{
    loading: boolean;
    error: boolean;
    data: Result | null;
  }>({
    loading: true,
    error: false,
    data: null,
  });
  const { getSession, clearSession } = useContext(SessionContext);
  const retryCount = useRef<number>(0);
  const fetchQuery: any = useCallback(async () => {
    let authToken;
    if (useSession) {
      const session = await getSession();
      authToken = session?.authToken;
    }

    try {
      const response = await graphqlOperation<Result, Variables>(
        name,
        vars,
        authToken,
      );
      if (response === null && retryCount.current > 0) {
        setState({ loading: false, error: true, data: null });
      } else if (response === null && retryCount.current === 0) {
        clearSession();
        retryCount.current++;
        return fetchQuery();
      } else {
        setState({ loading: false, error: false, data: response });
      }
    } catch (e) {
      clearSession();
    }
  }, deps);

  useEffect(() => {
    if (doFetch) {
      fetchQuery();
    }
  }, [...deps, doFetch, fetchQuery]);

  const mergeChanges = (changes: Partial<Result>) => {
    if (data === null) return;
    setState({ loading, error, data: { ...data, ...changes } });
  };

  const refetch = async () => {
    // setState({ loading: true, error, data });
    await fetchQuery();
  };

  return [loading, error, data, mergeChanges, refetch];
};
