import React from 'react';
import Modal from '../components/Modal';
import { useState } from 'react';
import { ChainIDWarning } from '../components/ChainIDWarning';
import { useWeb3Store } from '../';
import { CHAIN_ID } from 'ag-web3';

type IncorrectChain = boolean;

export function useOnChangeChain() {
  const [modalOpen, updateModalOpen] = useState(false);
  const chainId = useWeb3Store(state => state.chainId);

  return {
    checkChain: (): IncorrectChain => {
      updateModalOpen(chainId?.toString() != CHAIN_ID.toString());
      return chainId?.toString() != CHAIN_ID.toString();
    },
    changeChainModal: (
      <Modal onClose={() => updateModalOpen(false)} isOpen={modalOpen}>
        <ChainIDWarning
          callback={() => updateModalOpen(false)}
          aProps={{ style: { textAlign: 'center', display: 'block' } }}
          spanProps={{ style: { color: 'white' } }}
        />
      </Modal>
    ),
  };
}
