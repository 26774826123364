import React, { FC, useState } from 'react';
import { useAnimationFrameEffect } from '../hooks';

export type CurrentTimeProps = {
  audioContext?: BaseAudioContext | undefined;
  frameDivisor?: number | undefined;
  showSubseconds?: boolean | undefined;
};

export const CurrentTime: FC<CurrentTimeProps> = ({
  audioContext,
  frameDivisor = 2,
  showSubseconds,
}) => {
  const [time, setTime] = useState<number>(audioContext?.currentTime ?? 0);
  useAnimationFrameEffect(
    () => {
      setTime(audioContext?.currentTime ?? 0);
    },
    [audioContext],
    frameDivisor,
  );

  const hours = `${Math.floor(time / 3600)}`.padStart(2, '0');
  const minutes = `${Math.floor((time % 3600) / 60)}`.padStart(2, '0');
  const seconds = `${Math.floor(time % 60)}`.padStart(2, '0');
  const subseconds = `${Math.floor((time % 1) * 100)}`.padEnd(2, '0');

  return (
    <>
      {hours}:{minutes}:{seconds}
      {showSubseconds && `.${subseconds}`}
    </>
  );
};
