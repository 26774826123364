import { createGlobalStyle } from 'styled-components';
import { strokeWidth, fontSize, lightTheme, darkTheme } from '../style';

export const GlobalStyle = createGlobalStyle<{ darkMode: boolean }>`
  html, body, #app {
    margin: 0;
    padding: 0;
    user-select: none;
    display: block;
    overscroll-behavior: none;
    font-size: ${fontSize}px;
    line-height: 1.25rem;
    height: 100%;
  }

  body {
    ${({ darkMode }) =>
      Object.entries(darkMode ? darkTheme : lightTheme)
        .map(([k, v]) => `${k}:${v};`)
        .join('\n')}

    background-color: var(--background-color);
    color: var(--text-color);

    &.global-styles-applied {
      transition: background-color 0.33s ease-in-out;
    }
  }

  .toast {
    background-color: var(--background-secondary-color);
    color: var(--text-color);
  }

  * {
    box-sizing: border-box;
    font-family: "Mono", monospace;
    vertical-align: top;
  }
  *:focus {
    outline: solid var(--outline-color) ${strokeWidth}px;
    outline-offset: ${2 * strokeWidth}px;
    z-index: 1;
  }

  svg *:focus {
    outline: none;
  }
  ::selection {
    color: var(--text-color);
    background: var(--selection-color);
  }
`;
