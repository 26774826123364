import React, { FC, useRef, useContext, ReactNode } from 'react';
import { MenuItems, Vector } from '../types';
import { ContextMenuContext } from '../contexts';

export type MenuButtonProps = {
  items: MenuItems;
  children?: ReactNode;
  alignRight?: boolean;
  tabIndex?: number;
  menuOffset?: Vector;
};

export const MenuButton: FC<MenuButtonProps> = ({
  items,
  children,
  alignRight,
  tabIndex,
  menuOffset = [0, 0],
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const { open, close, isOpen } = useContext(ContextMenuContext);

  const showMenu = () => {
    if (ref.current === null) return;
    const { bottom, right, left } = ref.current.getBoundingClientRect();
    const [offsetX, offsetY] = menuOffset;
    open(
      [alignRight ? right + offsetX : left - offsetX, bottom + offsetY],
      items,
      ref.current,
    );
  };

  const toggleMenu = () => {
    if (isOpen) close();
    else showMenu();
  };

  return (
    <div
      ref={ref}
      tabIndex={tabIndex}
      onClick={e => {
        e.stopPropagation();
        e.preventDefault();
        toggleMenu();
      }}
      onContextMenu={e => {
        e.preventDefault();
        showMenu();
      }}
      onKeyDown={e => {
        if (e.key === 'Enter') {
          e.preventDefault();
          e.stopPropagation();
          toggleMenu();
        }
      }}
    >
      {children}
    </div>
  );
};
