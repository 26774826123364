import React, { FC, ReactNode } from 'react';
import { fontSize } from '../style';
import { A } from './A';
import { ExternalLinkIcon } from './ExternalLinkIcon';

export type ExternalLinkProps = {
  href: string;
  children: ReactNode;
};

export const ExternalLink: FC<ExternalLinkProps> = ({ children, href }) => {
  return (
    <A href={href} target="_blank" rel="noopener noreferrer">
      {children}
      <ExternalLinkIcon size={fontSize} />
    </A>
  );
};
