import { PlayableTokenFragment } from 'backend';
import React, { FC, useContext } from 'react';
import styled from 'styled-components';
import {
  DraggableRow,
  MenuItems,
  MenuButton,
  Link,
  strokeWidth,
  useContextMenu,
} from 'ag-components';
import { C1, C2, C3, C4 } from './columns';
import { PlayerContext } from '../../contexts';
import { MoreButton } from 'ag-components/src/components/MoreButton';
import { artistPath, collectionPath, tokenPath } from '../../utils';

export type PlaylistRowProps = {
  token: PlayableTokenFragment;
  index: number;
  viewerIsPlaylistOwner: boolean;
  move: (fromIndex: number, toIndex: number) => void;
  contextMenuItems: MenuItems;
};

const Image = styled.img`
  width: 2rem;
  height: 2rem;
`;

const DraggableTokenRow = styled(DraggableRow)<{ active: boolean }>`
  padding: 1rem;
  gap: 1rem;
  align-items: center;
  background: ${({ active }) =>
    active ? 'var(--group-color)' : 'var(--menu-color)'};
  :nth-child(2n) {
    background: ${({ active }) =>
      active ? 'var(--group-color)' : 'var(--project-preview-color)'};
  }
  :hover {
    background: ${({ active }) =>
      active ? 'var(--group-color)' : 'var(--background-secondary-color)'};
  }
`;

export const PlaylistRow: FC<PlaylistRowProps> = ({
  token,
  index,
  viewerIsPlaylistOwner,
  move,
  contextMenuItems,
}) => {
  const { play, current } = useContext(PlayerContext);

  const onContextMenu = useContextMenu(contextMenuItems);

  const name = `${token.collection.name} - ${token.name}`;
  const active = current?.token.queueID === token.queueID;

  return (
    <DraggableTokenRow
      onClick={e => {
        if (!(e.target instanceof HTMLAnchorElement)) {
          play(token);
        }
      }}
      active={active}
      group="playlist"
      index={index}
      move={move}
      dragDisabled={!viewerIsPlaylistOwner}
      onContextMenu={onContextMenu}
    >
      <C1>{token.image && <Image src={token.image.uri} />}</C1>
      <C2>
        <Link
          href={tokenPath(token.collection.address, token.tokenID)}
          title={name}
        >
          {token.name}
        </Link>
      </C2>
      <C3>
        <Link
          href={collectionPath(token.collection.address)}
          title={token.collection.name}
        >
          {token.collection.name}
        </Link>
      </C3>
      <C3>
        <Link
          href={artistPath(token.artist.chainID!)}
          title={token.artist.name}
          style={{ flex: 1 }}
        >
          {token.artist.name}
        </Link>
      </C3>
      <C4>
        <MenuButton
          items={contextMenuItems}
          menuOffset={[3 * strokeWidth, 3 * strokeWidth]}
        >
          <MoreButton />
        </MenuButton>
      </C4>
    </DraggableTokenRow>
  );
};
