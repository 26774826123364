import React, { useContext } from 'react';
import {
  ErrorScaffold,
  H2,
  LoadingScaffold,
  RouteHandler,
  SessionContext,
  useQuery,
  Section,
} from 'ag-components';
import { PlatformLayout } from '../../components';
import { EditPlaylistPageQuery, EditPlaylistPageQueryVariables } from 'backend';
import { EditPlaylistForm } from './EditPlaylistForm';
import { editPlaylistPath, playlistPath } from '../../utils';

export const EditPlaylistPage: RouteHandler = ({
  pathParams: { playlistID },
}) => {
  const [loading, error, data] = useQuery<
    EditPlaylistPageQuery,
    EditPlaylistPageQueryVariables
  >('EditPlaylistPageQuery', { playlistID });
  const { currentUser } = useContext(SessionContext);

  if (loading) {
    return <LoadingScaffold />;
  }
  if (
    error ||
    data === null ||
    (data && data.playlist.user.address !== currentUser?.address)
  ) {
    return <ErrorScaffold />;
  }

  const {
    playlist,
    playlist: { id, name },
  } = data;

  return (
    <PlatformLayout
      breadcrumbs={[
        ['playlists', null],
        [name, playlistPath(id)],
        ['edit', editPlaylistPath(id)],
      ]}
    >
      <Section>
        <H2 as="h1">Edit Playlist</H2>
        <EditPlaylistForm playlist={playlist} />
      </Section>
    </PlatformLayout>
  );
};
