import { createContext } from 'react';
import { AudioGraph, SeededGraph } from 'helicon';
import { PlayableTokenFragment } from 'backend';
import { GraphLoader } from '../providers/PlayerProvider/GraphLoader';

export type Current = {
  audioGraph: AudioGraph;
  graph: SeededGraph;
  token: PlayableTokenFragment;
};

export type PlayerState = {
  current: Current | undefined;
  previousQueue: PlayableTokenFragment[];
  queue: PlayableTokenFragment[];
  autoQueue: PlayableTokenFragment[];
  playing: boolean;
  loading: boolean;
  error: boolean;
  play: (token?: PlayableTokenFragment) => Promise<void>;
  pause: () => void;
  stop: () => Promise<void>;
  next: () => void;
  previous: () => void;
  addToQueue: (token: PlayableTokenFragment) => void;
  setQueue: (queue: PlayableTokenFragment[]) => void;
  setAutoQueue: (queue: PlayableTokenFragment[]) => void;
  isInQueue: (token: PlayableTokenFragment) => boolean;
  loader: GraphLoader;
};

export const PlayerContext = createContext<PlayerState>({
  current: undefined,
  playing: false,
  loading: false,
  error: false,
  previousQueue: [],
  queue: [],
  autoQueue: [],
  play: async () => {},
  pause: () => {},
  stop: async () => {},
  next: () => {},
  previous: () => {},
  addToQueue: () => {},
  setQueue: () => {},
  setAutoQueue: () => {},
  isInQueue: () => false,
  loader: new GraphLoader(),
});
