import { useContext, useState } from 'react';
import { SessionContext } from '../contexts';
import { graphqlOperation } from '../utils';

export const useMutation = <Result, Variables>(
  name: string,
  useSession: boolean,
): [(vars: Variables) => Promise<Result | null>, boolean, boolean] => {
  const [{ loading, error }, setState] = useState({
    loading: false,
    error: false,
  });
  const { getSession } = useContext(SessionContext);

  return [
    async vars => {
      setState({ loading: true, error: false });
      const response = await graphqlOperation<Result, Variables>(
        name,
        vars,
        useSession ? (await getSession())?.authToken : undefined,
      );
      if (response === null) {
        setState({ loading: false, error: true });
        return null;
      } else {
        setState({ loading: false, error: false });
        return response;
      }
    },
    loading,
    error,
  ];
};
