import React from 'react';
import { A, Card, Grid, P } from 'ag-components';
import styled from 'styled-components';
import { format } from 'date-fns';

const posts = [
  {
    id: 'JNCujeesRwiw5q1aCSGIvw',
    title: 'Saint Stereo - Generative Music Artist Spotlight',
    tags: ['artist-spotlight'],
    createdAt: 1705255881308,
    titleUpdatedAt: 1705256982253,
    tagsUpdatedAt: 1705256363954,
    intro:
      'It’s been less than a month since Unlooped, the generative music platform, launched. Among the talented artists it features is Saint Stereo, an NYC-based musician with a rich history in music production, soundtrack work, and live performances. Saint Stereo’s musical odyssey began in childhood, exploring piano melodies and eventually venturing into guitar and electronic music production. His attraction to generative music stems from a passion for multimedia art and the experimental possibilities it offers beyond traditional composition.',

    publishType: 'view',
    publishedAt: 1705256355588,
    permalink: 'saint-stereo-generative-music-artist-spotlight',
    publishLink:
      'https://hackmd.io/@Unlooped/saint-stereo-generative-music-artist-spotlight',
    shortId: 'rkbAQo-K6',
    content: '',
    lastChangedAt: 1705256291620,
    lastChangeUser: {
      name: 'Unlooped',
      photo:
        'https://www.gravatar.com/avatar/2ee1065c332e7f367c077bb545a28eea?s=96',
      biography: null,
      userPath: 'Unlooped',
    },
    userPath: 'Unlooped',
    teamPath: null,
    readPermission: 'guest',
    writePermission: 'owner',
  },
  {
    id: 'E8IxLas2Qu2RQIH7yDAMfw',
    title: 'Null Hax - Generative Music Artist Spotlight',
    intro:
      'It’s been less than a month since the launch of Unlooped, the generative music platform built on the Base Blockchain. Today, we’re excited to spotlight null_hax, an artist whose passion for electronic music stretches back to the days of mixtapes and Sony Acid Pro. Growing up with a diverse musical background, hax’s journey into music production began in middle school and evolved through his college years where he became an Ableton expert, even creating the Max for Live device ‘Architect.’ Despite a detour into the corporate world, hax’s creative spark remained undimmed, leading to the birth of his alter ego, null_hax. His approach to music is as varied as his inspirations, ranging from experimental bass to emotional garage wave. With Unlooped, hax embraces the challenge of generative music, pushing the boundaries of creativity. His piece Phantom Parlor exemplifies his unique style, combining traditional structures with randomized elements. As an artist breaking out of his shell, null_hax is not just sharing music but embarking on a journey of interactive, generative artistry, and we are here to follow every step of his path',
    tags: ['artist-spotlight'],
    createdAt: 1705253901244,
    titleUpdatedAt: 1705254033896,
    tagsUpdatedAt: 1705255173316,
    publishType: 'view',
    publishedAt: 1705255400579,
    permalink: 'null-hax-generative-music-artist-spotlight',
    publishLink:
      'https://hackmd.io/@Unlooped/null-hax-generative-music-artist-spotlight',
    shortId: 'S1BMn9ZYT',
    content: '',
    lastChangedAt: 1705255000408,
    lastChangeUser: {
      name: 'Unlooped',
      photo:
        'https://www.gravatar.com/avatar/2ee1065c332e7f367c077bb545a28eea?s=96',
      biography: null,
      userPath: 'Unlooped',
    },
    userPath: 'Unlooped',
    teamPath: null,
    readPermission: 'guest',
    writePermission: 'owner',
  },
];

const BlogCard = styled(Card)`
  height: 250px;
`;

const IntroP = styled(P)`
  font-size: 1rem;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 16px;
  max-width: 400px;
`;

const BlogGrid = styled(Grid)`
  margin: 0 -1rem;
`;

export function BlogRow() {
  return (
    <BlogGrid cols={{ xs: 1, sm: 1, md: 2, lg: 4 }}>
      {posts.map(post => (
        <a
          style={{ textDecoration: 'none', color: 'inherit' }}
          href={post.publishLink}
          target="_blank"
        >
          <BlogCard>
            <P>{post.title}</P>
            <span style={{ marginTop: '16px', display: 'inline-block' }}>
              {format(post.createdAt, 'MMMM dd, yyyy')}
            </span>
            <IntroP>{post.intro}</IntroP>
            <A>Read</A>
          </BlogCard>
        </a>
      ))}
    </BlogGrid>
  );
}
