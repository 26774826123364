import styled from 'styled-components';

export const Row = styled.div<{
  align?: string;
  flex?: string;
  justify?: string;
  gap?: number;
  padding?: number;
  flexWrap?: boolean;
}>`
  display: flex;
  flex: ${({ flex }) => (flex !== undefined ? flex : '0 1 auto')};
  align-items: ${({ align }) => align ?? 'flex-start'};
  justify-content: ${({ justify }) => justify ?? 'flex-start'};
  gap: ${({ gap }) => gap ?? 0}rem;
  padding: ${({ padding = 0 }) => padding}rem;
  flex-wrap: ${({ flexWrap }) => (flexWrap ? 'wrap' : 'nowrap')};
  min-width: 0;
`;
