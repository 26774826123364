import { createContext } from 'react';
import { CurrentUserFragment, PlaylistIdentityFragment } from 'backend';

export type SessionState = {
  loading: boolean;
  currentUser: CurrentUserFragment | null;
  mergeCurrentUserChanges: (changes: Partial<CurrentUserFragment>) => void;
  playlists: PlaylistIdentityFragment[];
  mergePlaylistsChanges: (playlists: PlaylistIdentityFragment[]) => void;
  clearSession: () => void;
  getSession: () => Promise<{
    authToken: string;
    expiry: Date;
  } | null>;
};

export const SessionContext = createContext<SessionState>({
  loading: false,
  currentUser: null,
  mergeCurrentUserChanges: () => {},
  playlists: [],
  mergePlaylistsChanges: () => {},
  getSession: async () => null,
  clearSession: () => null,
});
