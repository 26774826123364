import styled from 'styled-components';

export type H1Props = {
  size?: number;
};

export const H1 = styled.h1<H1Props>`
  font-weight: 600;
  font-family: 'Display', sans-serif;
  font-size: ${({ size = 3 }) => size}em;
  line-height: 1.25em;
  letter-spacing: 0.1em;
  color: var(--ui-control-color);
  user-select: text;
  word-break: break-word;
  margin: 0;
`;
