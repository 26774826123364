import styled from 'styled-components';
import { maxTextContentWidth } from '../style';

export const P = styled.p<{ fontSize?: number }>`
  max-width: ${maxTextContentWidth}px;
  font-size: ${({ fontSize = 1.25 }) => fontSize}em;
  line-height: 1.5em;
  color: var(--text-color);
  user-select: text;
  margin: 0;
`;
