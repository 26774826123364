import styled from 'styled-components';
import { breakpointQueries } from '../style';

export type GridProps = {
  space?: number;
  minSize?: number;
  cols?: number | { [size: string]: number };
  maxVisibleCells?: number | { [size: string]: number };
};

export const Grid = styled.div<GridProps>`
  display: grid;
  grid-template-rows: 1fr;
  gap: ${({ space = 1 }) => space}rem;
  ${({ cols, minSize = 12 }) =>
    typeof cols === 'object'
      ? ['xs', 'sm', 'md', 'lg'].reduce(
          (memo, size) =>
            memo +
            `@media ${breakpointQueries[size]} { grid-template-columns: repeat(${cols[size]}, 1fr); }`,
          '',
        )
      : cols
      ? `grid-template-columns: repeat(${cols}, 1fr)`
      : `grid-template-columns: repeat(auto-fill, minmax(${minSize}rem, 1fr))`};
  ${({ maxVisibleCells }) =>
    typeof maxVisibleCells === 'object'
      ? ['xs', 'sm', 'md', 'lg'].reduce(
          (memo, size) =>
            memo +
            `@media ${breakpointQueries[size]} { > :nth-child(n+${
              maxVisibleCells[size] + 1
            }) { display: none } }`,
          '',
        )
      : maxVisibleCells != null
      ? `> :nth-child(n+${maxVisibleCells + 1}) { display: none; }`
      : ''}
`;
