import styled from 'styled-components';
import { inputWidth } from '../style';

export type ButtonProps = {
  fullWidth?: boolean;
  inputWidth?: boolean;
  large?: boolean;
  text?: boolean;
  children?: React.ReactNode;
};

export const Button = styled.button<ButtonProps>`
  display: block;
  width: ${({ fullWidth: fw, inputWidth: iw }) =>
    fw ? '100%' : iw ? `${inputWidth}px` : 'auto'};
  color: ${({ text }) =>
    text ? 'var(--text-color)' : 'var(--button-text-color)'};
  background: ${({ text }) => (text ? 'transparent' : 'var(--button-color)')};
  border: none;
  font-size: ${({ large }) => (large ? 1.75 : 1)}rem;
  padding: ${({ large }) => (large ? `0.625rem 3rem` : `0.3125rem 1.5rem`)};
  text-align: left;
  hyphens: auto;
  position: relative;

  &:hover,
  &:active {
    background-color: ${({ text }) =>
      text ? 'transparent' : 'var(--button-hover-color)'};
    color: ${({ text }) =>
      text ? 'var(--text-secondary-color)' : 'var(--button-hover-text-color)'};
  }

  &:disabled {
    background-color: var(--button-disabled-color);
    color: var(--button-disabled-text-color);
  }
`;
