export * from './repeat';
export * from './connectionArgs';
export * from './paths';

export function truncateString(str: string, num: number) {
  // If the length of str is less than or equal to num, return str
  if (str.length <= num) {
    return str;
  }

  // Truncate the string to the maximum number of characters (minus 3 for the ellipsis)
  let truncated = str.substr(0, num - 3);

  // Find the last space in the truncated string
  let lastSpace = truncated.lastIndexOf(' ');
  if (lastSpace > 0) {
    // Cut the string at the last space so we don't cut in the middle of a word
    truncated = truncated.substr(0, lastSpace);
  }

  // Return the truncated string with an ellipsis
  return truncated + '...';
}
