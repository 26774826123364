import React, { FC } from 'react';
import styled from 'styled-components';
import { Row, Col, Link, ClampedText } from 'ag-components';
import { PlayableTokenFragment } from 'backend';
import { artistPath, tokenPath } from '../../utils';

export type TokenDataProps = {
  token: PlayableTokenFragment;
};

const Image = styled.img`
  width: 2rem;
  height: 2rem;
`;

export const TokenData: FC<TokenDataProps> = ({ token }) => {
  const name = `${token.collection.name} - ${token.name}`;

  return (
    <Row gap={1} align="center">
      <Link
        href={tokenPath(token.collection.address, token.tokenID)}
        title={name}
      >
        {token.image && <Image src={token.image?.uri} />}
      </Link>
      <Col>
        <Link
          href={tokenPath(token.collection.address, token.tokenID)}
          title={name}
        >
          <ClampedText>{name}</ClampedText>
        </Link>
        <Link
          href={artistPath(token.artist.chainID!)}
          title={token.artist.name}
        >
          <ClampedText>{token.artist.name}</ClampedText>
        </Link>
      </Col>
    </Row>
  );
};
