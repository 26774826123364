import styled from 'styled-components';
import { pulse } from '../style';

export const LoadingIndicator = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -0.5rem 0 0 -0.5rem;
  width: 1rem;
  height: 1rem;
  border-radius: 0.5rem;
  background: var(--group-color);
  animation: ${pulse} 1s linear infinite alternate;
  z-index: 1;
`;
