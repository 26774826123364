import React from 'react';
import {
  H1,
  RouteHandler,
  LoadingScaffold,
  ErrorScaffold,
  useQuery,
  Section,
} from 'ag-components';
import {
  CollectionGrid,
  PlatformLayout,
  PaginationControls,
} from '../components';
import {
  ArtistCollectionsPageQuery,
  ArtistCollectionsPageQueryVariables,
} from 'backend';
import { collectionsPath, connectionArgs } from '../utils';
import { useAutoQueue } from '../hooks';

export const ArtistCollectionsPage: RouteHandler = ({
  pathParams,
  searchParams,
}) => {
  const chainID = parseInt(pathParams.chainID);
  const [loading, error, data] = useQuery<
    ArtistCollectionsPageQuery,
    ArtistCollectionsPageQueryVariables
  >('ArtistCollectionsPageQuery', {
    chainID,
    ...connectionArgs(searchParams),
  });

  useAutoQueue(data, data =>
    data.artist.collections.edges.map(e => e.featuredToken),
  );

  if (loading) return <LoadingScaffold />;
  if (error || data === null) return <ErrorScaffold />;

  const { artist } = data;

  return (
    <PlatformLayout breadcrumbs={[['collections', collectionsPath()]]}>
      <Section>
        <H1>{artist.name} Collections</H1>
        <CollectionGrid minSize={40} collections={artist.collections.edges} />
        <PaginationControls pageInfo={artist.collections.pageInfo} />
      </Section>
    </PlatformLayout>
  );
};
