import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import styled, { keyframes } from 'styled-components';

const ModalOverlay = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1000;
  padding: 0 10px 50px;
`;

const animation = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const ModalWrapper = styled.div`
  background-color: var(--background-secondary-color);
  padding: 20px;
  border-radius: 10px;
  max-width: 500px;
  width: 100%;
  position: relative;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.2);
  animation: ${animation} 0.25s forwards;
  margin-top: 50px;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 18px;
  color: var(--text-color);
`;

interface ModalProps {
  isOpen: boolean;
  onClose?: () => void;
  children?: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children }) => {
  const handleOverlayClick = (event: any) => {
    // Check if the clicked element is exactly the overlay, not its children
    if (event.target === event.currentTarget) {
      onClose?.();
    }
  };

  const handleEscapePress = (event: any) => {
    if (event.key === 'Escape') {
      onClose?.();
    }
  };

  useEffect(() => {
    // Attach the event listener for the escape key
    document.addEventListener('keydown', handleEscapePress);
    return () => {
      // Remove the event listener when the component unmounts
      document.removeEventListener('keydown', handleEscapePress);
    };
  }, []);

  if (!isOpen) return null;
  return ReactDOM.createPortal(
    <ModalOverlay onClick={handleOverlayClick}>
      <ModalWrapper>
        {onClose && <CloseButton onClick={onClose}>&times;</CloseButton>}
        {children}
      </ModalWrapper>
    </ModalOverlay>,
    document.body,
  );
};

export default Modal;
