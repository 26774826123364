import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { breakpointQueries } from '../style';

const Grid = styled.div<{
  cols: number;
  visibleCols?: { sm: boolean[]; md: boolean[]; lg: boolean[] };
}>`
  display: grid;
  align-items: stretch;
  grid-template-columns: repeat(${({ cols }) => cols}, auto);
  gap: 0.5rem;
  > * {
    min-width: 0;
    min-height: 0;
  }
  ${({ visibleCols, cols }) =>
    visibleCols &&
    ['sm', 'md', 'lg'].reduce(
      (memo, size) =>
        memo +
        `@media ${(breakpointQueries as any)[size]} {
      grid-template-columns: repeat(${(
        visibleCols as { [size: string]: boolean[] }
      )[size].reduce((memo, visible) => memo + (visible ? 1 : 0), 0)}, auto);
    ${(visibleCols as { [size: string]: boolean[] })[size].reduce(
      (memo, visible, i) =>
        memo +
        (visible
          ? ''
          : `> :nth-child(${cols}n + ${i + 1}) { display: none; } `),
      '',
    )}
  }`,
      '',
    )}
`;

const Cell = styled.div`
  padding: 0.5rem 1rem;
  background-color: var(--project-preview-color);
  transition: background-color 0.33s ease-in-out;
  display: flex;
  align-items: center;
  position: relative;
`;

export type GridTableProps = {
  cols: number;
  header?: ReactNode[];
  rows: ReactNode[][];
  visibleCols?: { sm: boolean[]; md: boolean[]; lg: boolean[] };
};

export const GridTable: FC<GridTableProps> = ({
  cols,
  header,
  rows,
  visibleCols,
}) => (
  <Grid cols={cols} visibleCols={visibleCols}>
    {header?.map((node, i) => (
      <Cell key={i}>{node}</Cell>
    ))}
    {rows.map((row, i) =>
      row.map((item, j) => <Cell key={`${i}|${j}`}>{item}</Cell>),
    )}
  </Grid>
);
