import React, { ChangeEvent, FC } from 'react';
import styled from 'styled-components';
import { inputWidth } from '../style';
import { Col } from './Col';
import { P, useMutation } from '..';
import { UploadImageMutationVariables, UploadImageMutation } from 'backend';

export type ImageInputProps = {
  value: { id: string; uri: string } | null | undefined;
  onChange: (value: { id: string; uri: string }) => void;
  id?: string;
  name?: string;
  disabled?: boolean;
};

const Input = styled.input`
  width: ${inputWidth}px;
  height: ${inputWidth}px;
  background-color: var(--input-disabled-color);
  background-size: cover;
  background-position: center;
  padding: 0.5rem;
  color: transparent;

  ::file-selector-button {
    display: none;
  }
`;

export const ImageInput: FC<ImageInputProps> = ({
  id,
  name,
  value,
  onChange,
  disabled,
}) => {
  const [uploadImage, loading, error] = useMutation<
    UploadImageMutation,
    UploadImageMutationVariables
  >('UploadImageMutation', true);

  return (
    <Col gap={0.5} as="label">
      <Input
        type="file"
        id={id}
        name={name}
        onChange={async (e: ChangeEvent<HTMLInputElement>) => {
          try {
            if (!e.target.files) throw new Error('no files');
            const file = e.target.files[0];
            const response = await uploadImage({
              // file will be translated to the expected type by graphql-upload
              // middleware
              input: { upload: file as any },
            });
            if (response) {
              onChange(response.uploadImage.image);
            }
          } catch (e) {
            console.log(e);
          }
        }}
        style={value ? { backgroundImage: `url(${value.uri})` } : {}}
        disabled={disabled || loading}
      />
      click to upload (Max size 1.25 MB)
      {error && (
        <P fontSize={1} style={{ color: 'red' }}>
          Max image size is 1.25 MB
        </P>
      )}
    </Col>
  );
};
