import React, { FC } from 'react';
import styled from 'styled-components';
import { Col } from './Col';
import { ErrorText } from './ErrorText';
import { NetworkActivityIndicator } from './NetworkActivityIndicator';
import { Overlay } from './Overlay';
import { P } from '.';

const Main = styled(Overlay)`
  pointer-events: none;
`;

export type BlockingOverlayProps = {
  error: string | null;
  text?: string;
};

export const BlockingOverlay: FC<BlockingOverlayProps> = ({ error, text }) => {
  return (
    <Main>
      <Col justify="center" align="center" gap={0.5}>
        {text && <P>{text}</P>}
        <NetworkActivityIndicator
          active={true}
          pending={true}
          error={!!error}
        />
        <ErrorText>{error}</ErrorText>
      </Col>
    </Main>
  );
};
