import React, { FC, MouseEvent, ReactNode, useContext } from 'react';
import { A } from './A';
import { RoutingContext } from '../contexts';

export type LinkProps = {
  href?: string;
  title?: string;
  children: ReactNode;
  className?: string;
  onContextMenu?: (e: MouseEvent<HTMLAnchorElement>) => void;
  tabIndex?: number;
  block?: boolean;
  plain?: boolean;
  style?: any;
};

export const Link: FC<LinkProps> = ({
  href,
  title,
  children,
  className,
  onContextMenu,
  tabIndex,
  block,
  plain,
  style,
}) => {
  const { route } = useContext(RoutingContext);

  return (
    <A
      style={style}
      href={href}
      title={title}
      onClick={e => {
        if (e.metaKey || e.button !== 0) return;
        e.preventDefault();
        href !== undefined && route(href);
      }}
      className={className}
      onContextMenu={onContextMenu}
      tabIndex={tabIndex}
      block={block}
      plain={plain}
    >
      {children}
    </A>
  );
};
