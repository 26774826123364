import React, { FC } from 'react';
import { Col } from 'ag-components';
import { TokenRow } from './TokenRow';
import { PlayableTokenFragment } from 'backend';

export type TokenListProps = {
  queue: PlayableTokenFragment[];
  setQueue: (queue: PlayableTokenFragment[]) => void;
  play: (token: PlayableTokenFragment | undefined) => void;
  group: string;
};

export const TokenList: FC<TokenListProps> = ({
  queue,
  setQueue,
  play,
  group,
}) => {
  return (
    <Col align="stretch">
      {queue.map((token, i) => (
        <TokenRow
          key={i}
          group={group}
          token={token}
          index={i}
          remove={() => {
            setQueue([...queue.slice(0, i), ...queue.slice(i + 1)]);
          }}
          move={(fromIndex: number, toIndex: number) => {
            const nextQueue = [...queue];
            const [token] = nextQueue.splice(fromIndex, 1);
            nextQueue.splice(toIndex, 0, token);
            setQueue(nextQueue);
          }}
          play={() => play(token)}
        />
      ))}
    </Col>
  );
};
