export const UNLOOPED =
  process.env.UNLOOPED || '0xCf7Ed3AccA5a467e9e704C703E8D87F634fB0Fc9';
export const UNLOOPED_PROXY =
  process.env.UNLOOPED_PROXY || '0xDc64a140Aa3E981100a9becA4E685f962f0cF6C9';
export const UNLOOPED_ARTIST_REGISTRY =
  process.env.UNLOOPED_ARTIST_REGISTRY ||
  '0x9fE46736679d2D9a65F0992F2272dE9f3c7fa6e0';
export const UNLOOPED_DATA =
  process.env.UNLOOPED_DATA || '0xa513E6E4b8f2a923D98304ec87F64353C4D5C853';
export const BASIC_MINTER =
  process.env.BASIC_MINTER || '0x5FC8d32690cc91D4c39d9d3abcBD16989F875707';
export const PUBLIC_RPC_NODE =
  process.env.PUBLIC_RPC_NODE || 'http://localhost:8545';
export const CHAIN_ID = process.env.CHAIN_ID || '31337';
export const HTTP_URI = process.env.HTTP_URI || 'http://127.0.0.1:8545';
