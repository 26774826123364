import audioBufferToWav from 'audiobuffer-to-wav';
import { SeededGraph, AudioGraph } from 'helicon';
import { EXTENSIONS } from 'ag-extensions';

export const downloadAudioFile = async (
  graph: SeededGraph,
  name: string,
): Promise<void> => {
  const context = new OfflineAudioContext({
    numberOfChannels: 2,
    length: 120 * 44100,
    sampleRate: 44100,
  });
  const ag = new AudioGraph(graph, EXTENSIONS, context);
  await ag.filesReady;
  ag.destination.gain.setValueAtTime(1, 119);
  ag.destination.gain.linearRampToValueAtTime(0, 120);
  const buffer = await context.startRendering();
  const wav = audioBufferToWav(buffer, { float32: false });
  const blob = new window.Blob([new DataView(wav)], {
    type: 'audio/wav',
  });
  const url = window.URL.createObjectURL(blob);
  const anchor = document.createElement('a');
  anchor.href = url;
  anchor.download = `${name}.wav`;
  anchor.click();
  window.URL.revokeObjectURL(url);
};
