import React, { FC, ReactNode, useState, useMemo } from 'react';
import { Shade } from 'ag-components';
import { BlockingOverlay } from '../components';
import { BlockingOverlayContext, BlockingOverlayOptions } from '../contexts';

export type BlockingOverlayProviderProps = {
  children?: ReactNode;
};

export const BlockingOverlayProvider: FC<BlockingOverlayProviderProps> = ({
  children,
}) => {
  const [blockingOverlayActive, setBlockingOverlayActive] =
    useState<boolean>(false);
  const [blockingOverlayError, setBlockingOverlayError] = useState<
    string | null
  >(null);
  const blockingOverlayOptions = useMemo<BlockingOverlayOptions>(
    () => ({
      active: blockingOverlayActive,
      error: blockingOverlayError,
      start: () => setBlockingOverlayActive(true),
      stop: () => setBlockingOverlayActive(false),
      fail: setBlockingOverlayError,
    }),
    [blockingOverlayActive, blockingOverlayError],
  );

  return (
    <BlockingOverlayContext.Provider value={blockingOverlayOptions}>
      {children}
      {(blockingOverlayActive || blockingOverlayError) && (
        <>
          <Shade />
          <BlockingOverlay error={blockingOverlayError} />
        </>
      )}
    </BlockingOverlayContext.Provider>
  );
};
