import React from 'react';
import { LogoIcon, Row } from '.';

export function Logo() {
  return (
    <Row align="center" gap={1}>
      <LogoIcon style={{ height: '76px' }} />
    </Row>
  );
}
