import React, {
  FC,
  useState,
  useEffect,
  useRef,
  ReactNode,
  useMemo,
} from 'react';
import { AudioGraph } from 'helicon';
import { EXTENSIONS } from 'ag-extensions';
import { PlayableTokenFragment } from 'backend';
import { GraphLoader } from './GraphLoader';
import { Current, PlayerContext } from '../../contexts';
import { Player } from '../../components';

export type PlayerProviderProps = {
  children?: ReactNode;
};

export type PlayerProviderState = {
  current: Current | undefined;
  queue: PlayableTokenFragment[];
  previousQueue: PlayableTokenFragment[];
  autoQueue: PlayableTokenFragment[];
  originalAutoQueue: PlayableTokenFragment[];
  playing: boolean;
  loading: boolean;
  error: boolean;
};

export type LocalStorageState = {
  currentToken?: PlayableTokenFragment;
  queue: PlayableTokenFragment[];
  previousQueue: PlayableTokenFragment[];
};

const LS_KEY = 'AG_PLAYER_STATE';
const BC_KEY = 'AG_PLAYER';

enum Message {
  PLAY,
}

let tag: HTMLMediaElement;
// If Iphone is muted then web audio will not play. Hack is to loop silent audio simultaneously
const hack = async () => {
  if (!isIPhoneOrIpad()) return;
  try {
    var silenceDataURL =
      'data:audio/mp3;base64,//MkxAAHiAICWABElBeKPL/RANb2w+yiT1g/gTok//lP/W/l3h8QO/OCdCqCW2Cw//MkxAQHkAIWUAhEmAQXWUOFW2dxPu//9mr60ElY5sseQ+xxesmHKtZr7bsqqX2L//MkxAgFwAYiQAhEAC2hq22d3///9FTV6tA36JdgBJoOGgc+7qvqej5Zu7/7uI9l//MkxBQHAAYi8AhEAO193vt9KGOq+6qcT7hhfN5FTInmwk8RkqKImTM55pRQHQSq//MkxBsGkgoIAABHhTACIJLf99nVI///yuW1uBqWfEu7CgNPWGpUadBmZ////4sL//MkxCMHMAH9iABEmAsKioqKigsLCwtVTEFNRTMuOTkuNVVVVVVVVVVVVVVVVVVV//MkxCkECAUYCAAAAFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV';
    tag = document.createElement('audio');
    tag.controls = false;
    tag.preload = 'auto';
    tag.loop = true;
    tag.src = silenceDataURL;
    await tag.play();
  } catch (e) {
    console.error(e);
  }
};

function isIPhoneOrIpad() {
  var userAgent = window.navigator.userAgent.toLowerCase();
  return /iphone|ipod/.test(userAgent);
}

hack();

export const PlayerProvider: FC<PlayerProviderProps> = ({ children }) => {
  const [
    {
      current,
      queue,
      previousQueue,
      autoQueue,
      originalAutoQueue,
      playing,
      loading,
      error,
    },
    _setState,
  ] = useState<PlayerProviderState>({
    current: undefined,
    queue: [],
    previousQueue: [],
    autoQueue: [],
    originalAutoQueue: [],
    playing: false,
    loading: false,
    error: false,
  });
  const bc = useMemo(() => new BroadcastChannel(BC_KEY), []);

  // load queue and currently playing token from local storage
  useEffect(() => {
    const savedStateString = localStorage.getItem(LS_KEY);
    const savedState: LocalStorageState | undefined =
      savedStateString && JSON.parse(savedStateString);
    if (savedState) {
      (savedState.currentToken
        ? loadToken(savedState.currentToken)
        : Promise.resolve()
      ).then(next => {
        _setState(state => ({
          ...state,
          current: next ?? current,
          queue: savedState.queue,
          previousQueue: savedState.previousQueue,
        }));
      });
    }
  }, []);

  // stop playing when playback starts on another tab
  useEffect(() => {
    if (current) {
      const handleMessage = (e: MessageEvent) => {
        switch (e.data) {
          case Message.PLAY:
            stop();
            break;
          default:
            throw new Error('unrecognized message');
        }
      };
      bc.addEventListener('message', handleMessage);
      return () => bc.removeEventListener('message', handleMessage);
    }
    return;
  }, [current?.audioGraph]);

  // track updating status in a ref to avoid race conditions w/ mutliple plays
  const updatingRef = useRef<boolean>(false);

  // cache graph loads in memory
  const loader = useMemo(() => new GraphLoader(), []);

  // warp set state to update local storage on changes
  const setState = (s: (state: PlayerProviderState) => PlayerProviderState) =>
    _setState(state => {
      const nextState = s(state);
      const localStorageState: LocalStorageState = {
        currentToken: nextState.current?.token,
        queue: nextState.queue,
        previousQueue: nextState.previousQueue,
      };
      localStorage.setItem(LS_KEY, JSON.stringify(localStorageState));
      return nextState;
    });

  // respond to keyboard commands
  useEffect(() => {
    const onKeypress = (e: KeyboardEvent) => {
      const tagName = (e.target as HTMLElement).tagName;
      if (e.key === ' ' && tagName !== 'INPUT' && tagName !== 'TEXTAREA') {
        e.preventDefault();
        if (current?.audioGraph.playing) {
          setState(state => ({ ...state, playing: false }));
          current.audioGraph.pause();
        } else {
          setState(state => ({ ...state, playing: true }));
          current?.audioGraph.play();
        }
      }
    };
    window.addEventListener('keypress', onKeypress);

    if (current) {
      navigator.mediaSession.setActionHandler('play', () =>
        play(current.token),
      );
      navigator.mediaSession.setActionHandler('pause', () => pause());
      navigator.mediaSession.setActionHandler('stop', () => stop());
    }

    return () => {
      window.removeEventListener('keypress', onKeypress);
    };
  }, [current?.audioGraph]);

  const loadToken = async (token: PlayableTokenFragment): Promise<Current> => {
    updatingRef.current = true;
    const graph = await loader.load(token);
    const nextAudioGraph = new AudioGraph(graph, EXTENSIONS);
    try {
      await nextAudioGraph.filesReady;
    } catch (e) {
      console.log(e);
    }

    try {
      await current?.audioGraph.pause();
    } catch (e) {
      console.log(e);
    }
    // await Promise.all([nextAudioGraph.filesReady, current?.audioGraph.pause()]);
    current?.audioGraph.close();
    updatingRef.current = false;
    return { graph, token, audioGraph: nextAudioGraph };
  };

  const currentFragment = useRef<any>();

  const play = async (
    token: PlayableTokenFragment | undefined,
  ): Promise<Current | undefined> => {
    // @ts-ignore
    if (current?.audioGraph.context.state === 'interrupted') {
      // @ts-ignore
      current?.audioGraph.context.resume().then(() => play(token));
      return;
    }
    if (updatingRef.current) return;
    if (token === undefined) return;
    if (tag && tag.paused) {
      currentFragment.current = token;
      try {
        await tag?.play();
      } catch (e) {
        console.error(e);
      }
    }
    bc.postMessage(Message.PLAY);

    navigator.mediaSession.metadata = new MediaMetadata({
      title: token.name,
      artist: token.artist.name,
      album: token.collection.name,
      artwork: token.image
        ? [
            {
              src: token.image.uri,
            },
          ]
        : void 0,
    });
    navigator.mediaSession.playbackState = 'playing';

    if (current?.token.queueID === token.queueID) {
      current.audioGraph?.play();
      return current;
    }
    setState(state => ({ ...state, loading: true }));
    const nextCurrent = await loadToken(token);
    nextCurrent.audioGraph.play();
    return nextCurrent;
  };

  const pause = () => {
    setState(state => ({ ...state, playing: false }));
    navigator.mediaSession.playbackState = 'paused';
    current?.audioGraph.pause();
  };

  const stop = async () => {
    if (updatingRef.current) return;
    if (current) {
      updatingRef.current = true;
      await current.audioGraph.stop();

      navigator.mediaSession.metadata = null;
      navigator.mediaSession.playbackState = 'none';

      setState(state => ({
        ...state,
        playing: false,
      }));
      updatingRef.current = false;
    }
  };

  useEffect(() => {
    tag?.addEventListener('pause', () => {
      pause();
    });

    tag?.addEventListener('play', () => {
      if (navigator.mediaSession.playbackState === 'playing') return;
      if (currentFragment.current) {
        play(currentFragment.current);
      }
    });

    document.addEventListener('visibilitychange', async function () {
      if (document.hidden && tag) {
        await tag?.pause();
      }
    });
  }, []);

  return (
    <PlayerContext.Provider
      value={{
        current,
        queue,
        previousQueue,
        autoQueue,
        playing,
        loading,
        error,
        play: async (token = current?.token) => {
          const next = await play(token);
          setState(state => {
            const autoQueuePosition = originalAutoQueue.findIndex(
              ({ queueID }) => queueID === token?.queueID,
            );
            return {
              ...state,
              current: next,
              playing: true,
              loading: false,
              previousQueue: current
                ? [...previousQueue, current.token]
                : previousQueue,
              autoQueue:
                autoQueuePosition >= 0
                  ? originalAutoQueue.slice(autoQueuePosition + 1)
                  : autoQueue,
            };
          });
        },
        next: async () => {
          if (queue.length > 0) {
            const next = await play(queue[0]);
            setState(state => ({
              ...state,
              current: next,
              playing: true,
              loading: false,
              queue: queue.slice(1),
              previousQueue: current
                ? [...previousQueue, current.token]
                : previousQueue,
            }));
          } else if (autoQueue.length > 0) {
            const next = await play(autoQueue[0]);
            setState(state => ({
              ...state,
              current: next,
              playing: true,
              loading: false,
              autoQueue: autoQueue.slice(1),
              previousQueue: current
                ? [...previousQueue, current.token]
                : previousQueue,
            }));
          } else {
            return;
          }
        },
        previous: async () => {
          if (previousQueue.length < 1) return;
          const next = await play(previousQueue[previousQueue.length - 1]);
          setState(state => ({
            ...state,
            current: next,
            playing: true,
            loading: false,
            queue: current ? [current.token, ...queue] : queue,
            previousQueue: previousQueue.slice(0, -1),
          }));
        },
        pause,
        stop,
        addToQueue: async token => {
          if (current) {
            setState(state => ({ ...state, queue: [...state.queue, token] }));
          } else {
            const current = await loadToken(token);
            setState(state => ({ ...state, current }));
          }
        },
        setQueue: queue => {
          setState(state => ({ ...state, queue }));
        },
        setAutoQueue: autoQueue => {
          setState(state => ({
            ...state,
            autoQueue,
            originalAutoQueue: autoQueue,
          }));
        },
        isInQueue: token =>
          queue.some(
            ({ collection: { address }, tokenID }) =>
              token.collection.address === address && token.tokenID === tokenID,
          ),
        loader,
      }}
    >
      {children}
      <Player />
    </PlayerContext.Provider>
  );
};
