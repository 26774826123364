import React, { useEffect, useState } from 'react';
import {
  RouteHandler,
  H2,
  P,
  Row,
  Col,
  Link,
  UserTag,
  Price,
  LoadingScaffold,
  ErrorScaffold,
  Section,
  Illustration,
  useQuery,
  DataList,
  formatAddress,
  LicenseIcons,
  lineHeight,
  LicenseName,
  MintButton,
  useMediaQuery,
} from 'ag-components';
import { Helmet } from 'react-helmet';
import { PlatformLayout, IllustratedHero, TokenTable } from '../components';
import { CollectionPageQuery, CollectionPageQueryVariables } from 'backend';
import { formatDistance } from 'date-fns';
import { useMint } from '../hooks/useMint';
import { useAutoQueue } from '../hooks';
import {
  artistPath,
  collectionPath,
  collectionTokensPath,
  collectionsPath,
  truncateString,
} from '../utils';

export const CollectionPage: RouteHandler = ({ pathParams: { address } }) => {
  const [loading, error, data, _mergeChanges, refetch] = useQuery<
    CollectionPageQuery,
    CollectionPageQueryVariables
  >('CollectionPageQuery', { address });
  const { mint, loading: mintLoading } = useMint(
    address,
    String(data?.collection.mintPrice),
    refetch,
  );
  const isSmall = useMediaQuery('(max-width: 730px)');

  useAutoQueue(data, data => [...data.collection.tokens.edges]);

  const [hasStarted, setHasStarted] = useState<boolean>(false);
  const [hasEnded, setHasEnded] = useState<boolean>(false);
  const [countdown, setCountdown] = useState<string>('');

  useEffect(() => {
    const updateStatus = () => {
      const now = new Date();
      const startTime = data?.collection?.mintStartTime
        ? new Date(data.collection.mintStartTime)
        : null;
      const endTime = data?.collection?.mintCloseTime
        ? new Date(data.collection.mintCloseTime)
        : null;

      if (startTime) {
        setHasStarted(now >= startTime);

        if (now < startTime) {
          const timeLeft = startTime.getTime() - now.getTime();
          const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
          const hours = Math.floor(
            (timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
          );
          const minutes = Math.floor(
            (timeLeft % (1000 * 60 * 60)) / (1000 * 60),
          );
          const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);
          setCountdown(`${days}d ${hours}h ${minutes}m ${seconds}s`);
        } else {
          setCountdown('');
        }
      } else {
        setHasStarted(true);
      }

      if (endTime) {
        setHasEnded(now >= endTime);
      } else {
        setHasEnded(false);
      }
    };

    updateStatus();
    const interval = setInterval(updateStatus, 1000);
    return () => clearInterval(interval);
  }, [data]);

  if (loading) return <LoadingScaffold />;
  if (error || data === null) return <ErrorScaffold />;

  const {
    collection: {
      name,
      description,
      publishTime,
      numberOfTokens,
      numberOfTokensMinted,
      minting,
      mintCloseTime,
      mintStartTime,
      mintPrice,
      artist,
      coverImage,
      tokens,
      license,
    },
  } = data;

  return (
    <PlatformLayout
      breadcrumbs={[
        ['collections', collectionsPath()],
        [name, collectionPath(address)],
      ]}
    >
      <Helmet>
        <title>{name} | Unlooped Collection</title>

        <meta name="description" content={truncateString(description, 150)} />

        <meta property="og:title" content={name} />
        <meta
          property="og:description"
          content={truncateString(description, 150)}
        />
        <meta property="og:image" content={coverImage?.uri} />
        <meta property="og:url" content={window.location.href} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Section>
        <IllustratedHero
          illustration={<Illustration src={coverImage?.uri} />}
          content={
            <Col gap={2}>
              <H2 as="h1">{name}</H2>
              <P>
                <UserTag
                  href={artistPath(artist.chainID!)}
                  avatarURI={artist.avatar?.uri}
                  name={artist.name}
                />
              </P>
              <P>{description}</P>
              <DataList
                data={[
                  [
                    'PUBLISHED',
                    publishTime &&
                      `${formatDistance(
                        new Date(publishTime),
                        new Date(),
                      )} ago`,
                  ],
                  ['ADDRESS', formatAddress(address)],
                  [
                    'LICENSE',
                    <Row
                      style={{ marginTop: -0.125 * lineHeight }}
                      align="center"
                    >
                      <LicenseIcons
                        license={license}
                        size={1.25 * lineHeight}
                      />
                      &nbsp;
                      <LicenseName license={license} includeVersion={false} />
                    </Row>,
                  ],
                  ...(data.collection.numberOfTokensMinted
                    ? [
                        [
                          'TOTAL MINTED',
                          data.collection.numberOfTokensMinted.toLocaleString(),
                        ] as [string, string],
                      ]
                    : []),
                ]}
              />
              {minting && (
                <>
                  <Col gap={0.5}>
                    <P>
                      {numberOfTokens === 0 && <>Open edition</>}
                      {numberOfTokens > 0 && (
                        <>
                          {numberOfTokens - numberOfTokensMinted} of{' '}
                          {numberOfTokens} remaining
                        </>
                      )}
                    </P>
                    {mintStartTime && !hasStarted && (
                      <P>
                        Minting opens {new Date(mintStartTime).toLocaleString()}
                      </P>
                    )}
                    {mintCloseTime && (
                      <P>
                        Minting closes{' '}
                        {new Date(mintCloseTime).toLocaleString()}
                      </P>
                    )}
                    <Row align="center" gap={1}>
                      <P>Price</P>{' '}
                      <P>
                        <Price price={mintPrice} />
                      </P>
                    </Row>
                    {!hasStarted && data?.collection?.mintStartTime && (
                      <p>Countdown to Start: {countdown}</p>
                    )}
                  </Col>
                  <Row align="center" gap={1}>
                    {!hasStarted && <P>Minting hasn't started</P>}
                    {hasStarted && !hasEnded && (
                      <MintButton loading={mintLoading} onMint={mint} />
                    )}
                    {hasEnded && <P>Minting has ended</P>}
                  </Row>
                </>
              )}
            </Col>
          }
        />
      </Section>

      {tokens.edges.length > 0 && (
        <Section>
          <H2>Tokens</H2>
          <TokenTable cols={isSmall ? 1 : 2} tokens={tokens.edges} />
          {tokens.pageInfo.hasNextPage && (
            <Link href={collectionTokensPath(address)}>All tokens</Link>
          )}
        </Section>
      )}
    </PlatformLayout>
  );
};
