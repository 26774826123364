import React from 'react';
import ReactDOM from 'react-dom';
import {
  Router,
  SessionProvider,
  BlockingOverlayProvider,
  ContextMenuProvider,
  StyleProvider,
} from 'ag-components';
import { PlayerProvider } from './providers';
import {
  AboutPage,
  ArtistCollectionsPage,
  ArtistPage,
  CollectionPage,
  CollectionsPage,
  CollectionTokensPage,
  EditPlaylistPage,
  EditUserPage,
  HomePage,
  MintsPage,
  NotFoundPage,
  PlaylistPage,
  PrivacyPage,
  SalesPage,
  TermsPage,
  TokenPage,
  UserPage,
  UserPlaylistsPage,
  UserTokensPage,
} from './pages';
import { Player } from './components';
import { Toaster } from 'react-hot-toast';
import { ChainIDWarning } from 'ag-components/src/components/ChainIDWarning';

// set favicon
// setFavicon();

const Root = () => (
  <>
    <Toaster
      toastOptions={{
        className: 'toast',
      }}
    />
    <ChainIDWarning />
    <Router
      routes={[
        ['', HomePage],
        ['/about', AboutPage],
        ['/terms', TermsPage],
        ['/privacy', PrivacyPage],
        ['/mints', MintsPage],
        ['/sales', SalesPage],
        ['/users/:address', UserPage],
        ['/users/:address/edit', EditUserPage],
        ['/users/:address/tokens', UserTokensPage],
        ['/users/:address/playlists', UserPlaylistsPage],
        ['/artists/:chainID', ArtistPage],
        ['/artists/:chainID/collections', ArtistCollectionsPage],
        ['/collections', CollectionsPage],
        ['/collections/:address', CollectionPage],
        ['/collections/:address/tokens', CollectionTokensPage],
        ['/collections/:address/tokens/:tokenID', TokenPage],
        ['/playlists/:playlistID', PlaylistPage],
        ['/playlists/:playlistID/edit', EditPlaylistPage],
      ]}
      providers={[
        StyleProvider,
        BlockingOverlayProvider,
        SessionProvider,
        PlayerProvider,
        ContextMenuProvider,
      ]}
      NotFound={() => <NotFoundPage />}
    >
      <Player />
    </Router>
  </>
);

// render app
ReactDOM.render(<Root />, document.getElementById('app'));
