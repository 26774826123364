import styled from 'styled-components';
import { maxTextContentWidth } from '../style';

export type H2Props = {
  size?: number;
};

export const H2 = styled.h2<H2Props>`
  font-weight: 600;
  font-family: 'Display', sans-serif;
  font-size: ${({ size = 2.4 }) => size}em;
  line-height: 0.75;
  letter-spacing: 0.1em;
  color: var(--ui-control-color);
  max-width: ${maxTextContentWidth}px;
  user-select: text;
  word-break: break-word;
  margin: 0;
`;
