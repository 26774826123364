import { NodeExtension, ParamType } from 'helicon';

export const ComplexRhythmExtension: NodeExtension = {
  type: 'ComplexRhythm',
  processor: '/ComplexRhythmProcessor.js',
  description: {
    numberOfInputs: 0,
    numberOfOutputs: 2,
    outputChannelCount: [1, 1],
    channelCount: 1,
    channelCountMode: 'explicit',
    channelInterpretation: 'discrete',
    params: {
      clock: {
        type: ParamType.AudioParam,
        default: 0,
      },
      length: {
        type: ParamType.AudioParam,
        default: 0,
      },
      pattern: {
        type: ParamType.Value,
        default: [],
      },
      loop: {
        type: ParamType.Value,
        default: true,
      },
    },
    signals: {
      position: {
        readable: true,
        trigger: true,
      },
    },
  },
};
