import React, { FC } from 'react';
import { SVGIcon } from './SVGIcon';

export type PauseButtonProps = {
  onClick?: () => void;
  size?: number;
  disabled?: boolean;
};

export const PauseButton: FC<PauseButtonProps> = ({
  size,
  onClick,
  disabled,
}) => (
  <SVGIcon size={size} onClick={onClick} disabled={disabled}>
    <title>pause</title>
    <path d="M6 19h4V5H6v14zm8-14v14h4V5h-4z" />
  </SVGIcon>
);
