import React, { useContext, useState, useEffect } from 'react';
import {
  RouteHandler,
  Col,
  LoadingScaffold,
  ErrorScaffold,
  Section,
  useQuery,
  A,
  BlockingOverlay,
} from 'ag-components';
import { PlatformLayout, TokenCard, IllustratedHero } from '../../components';
import { TokenPageQuery, TokenPageQueryVariables } from 'backend';
import { TokenData } from './TokenData';
import { useAutoQueue } from '../../hooks';
import { collectionPath, collectionsPath, tokenPath } from '../../utils';
import { Helmet } from 'react-helmet';
import { downloadAudioFile } from '../../utils/downloadAudioFile';
import { PlayerContext } from '../../contexts';
import { GraphThumbnail } from './GraphThumbnail';
import { SeededGraph } from 'helicon';
import { Positions } from './Positions';

export const TokenPage: RouteHandler = ({
  pathParams: { address, tokenID: tokenIDString },
}) => {
  const tokenID = parseInt(tokenIDString);
  const [loading, error, data] = useQuery<
    TokenPageQuery,
    TokenPageQueryVariables
  >('TokenPageQuery', { address, tokenID });
  const [rendering, setRendering] = useState(false);
  const { loader, current, playing } = useContext(PlayerContext);

  useAutoQueue(data, data => [data.token]);

  const [graph, setGraph] = useState<SeededGraph | null>(null);
  useEffect(() => {
    data && loader.load(token).then(setGraph);
  }, [data && data.token]);

  if (loading) return <LoadingScaffold />;
  if (error || data === null) return <ErrorScaffold />;

  const { token, collection } = data;

  if (rendering) {
    return <BlockingOverlay text="Generating .wav file" error={null} />;
  }

  const isPlaying =
    playing &&
    current !== undefined &&
    current.token.collection.address == token.collection.address &&
    current.token.tokenID === token.tokenID;
  const audioGraph = isPlaying ? current.audioGraph : undefined;

  return (
    <PlatformLayout
      breadcrumbs={[
        ['collections', collectionsPath()],
        [collection.name, collectionPath(address)],
        [`#${tokenID}`, tokenPath(address, tokenID)],
      ]}
    >
      <Helmet>
        <title>
          {token.name} | {collection.name} | Unlooped NFT
        </title>

        <meta
          name="description"
          content={`${token.name} from ${collection.name} by ${collection.artist.name}`}
        />

        <meta
          property="og:title"
          content={`${token.name} | ${collection.name} | Unlooped NFT`}
        />
        <meta
          property="og:description"
          content={`${token.name} from ${collection.name} by ${collection.artist.name}`}
        />
        <meta property="og:image" content={token.image?.uri} />
        <meta property="og:url" content={window.location.href} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Section>
        <IllustratedHero
          illustration={<TokenCard token={token} />}
          content={
            <Col gap={2}>
              {graph && (
                <GraphThumbnail
                  graph={graph}
                  audioGraph={audioGraph}
                  width={180}
                />
              )}
              {graph && (
                <Positions
                  graph={graph}
                  audioGraph={audioGraph}
                  playing={isPlaying}
                />
              )}
              <TokenData data={data} />
              {graph && (
                <A
                  onClick={async () => {
                    setRendering(true);
                    await downloadAudioFile(
                      graph,
                      `${token.artist.name} - ${token.collection.name} ${token.name}`,
                    );
                    setRendering(false);
                  }}
                  style={{ fontSize: '1.25em' }}
                >
                  download
                </A>
              )}
            </Col>
          }
        />
      </Section>
    </PlatformLayout>
  );
};
