import React, { FC, useContext } from 'react';
import styled from 'styled-components';
import {
  Col,
  Link,
  Card,
  Row,
  H2,
  UserTag,
  Price,
  Illustration,
  MintButton,
  useOnChangeChain,
  RoutingContext,
} from 'ag-components';
import { PlaybackControls } from './PlaybackControls';
import { CollectionCardFragment } from 'backend';
import { useMint } from '../hooks';
import { artistPath, collectionPath, tokenPath } from '../utils';

export type CollectionCardProps = {
  collection: CollectionCardFragment;
};

const GridRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  align-items: stretch;
  justify-content: stretch;
  > * {
    min-width: 0;
    min-height: 0;
  }
`;

const GridCard = styled(Card)`
  display: grid;
  align-items: stretch;
  justify-content: stretch;
`;

export const CollectionCard: FC<CollectionCardProps> = ({
  collection: {
    address,
    name,
    featuredToken,
    artist,
    coverImage,
    numberOfTokens,
    numberOfTokensMinted,
    mintPrice,
    minting,
    mintCloseTime,
  },
}) => {
  const { changeChainModal, checkChain } = useOnChangeChain();
  const { route } = useContext(RoutingContext);
  const { mint, loading } = useMint(
    address,
    String(mintPrice),
    void 0,
    checkChain,
  );

  return (
    <GridCard
      onClick={event => {
        let target = event.target as HTMLElement;
        while (target !== event.currentTarget) {
          if (
            target.tagName === 'A' ||
            target.tagName === 'BUTTON' ||
            target.onclick
          ) {
            // Clickable element found, do nothing
            return;
          }
          target = target.parentNode as HTMLElement;
        }
        route(collectionPath(address));
      }}
    >
      {changeChainModal}
      <GridRow>
        <Col gap={2.5} justify="space-between">
          <Col gap={1}>
            <H2 as="h3">
              <Link href={collectionPath(address)} plain>
                {name}
              </Link>
            </H2>
            <UserTag
              href={artistPath(artist.chainID!)}
              name={artist.name}
              avatarURI={artist.avatar?.uri}
            />
          </Col>
          {minting && (
            <>
              <Col gap={1}>
                {numberOfTokens > 0 ? (
                  <div>
                    {numberOfTokens - numberOfTokensMinted} of {numberOfTokens}{' '}
                    remaining
                  </div>
                ) : mintCloseTime != null ? (
                  <div>
                    Closes{' '}
                    {new Date(mintCloseTime).toLocaleString(undefined, {
                      month: 'short',
                      day: 'numeric',
                      year:
                        new Date(mintCloseTime).getFullYear() !=
                        new Date().getFullYear()
                          ? 'numeric'
                          : undefined,
                    })}
                  </div>
                ) : (
                  <div>Open edition</div>
                )}

                <Row align="center" gap={1}>
                  <MintButton
                    connectBtnProps={{
                      large: false,
                    }}
                    btnProps={{
                      large: false,
                    }}
                    onMint={mint}
                    loading={loading}
                  />
                  <Price price={mintPrice} />
                </Row>
              </Col>
            </>
          )}
        </Col>
        {featuredToken && coverImage ? (
          <Col justify="center" align="stretch">
            <Col gap={1} align="stretch">
              <Link href={collectionPath(address)} block>
                <Illustration src={coverImage.uri} />
              </Link>
              <Row gap={1} align="center">
                <PlaybackControls token={featuredToken} />
                <Link href={tokenPath(address, featuredToken.tokenID)}>
                  {featuredToken.name}
                </Link>
              </Row>
            </Col>
          </Col>
        ) : (
          <Link href={collectionPath(address)}>
            <Col>
              <Illustration src={coverImage?.uri} />
            </Col>
          </Link>
        )}
      </GridRow>
    </GridCard>
  );
};
