import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { AudioGraph, SeededGraph } from 'helicon';

const cellSize = 4;
const rowSize = 32;
const c1 = 'var(--input-disabled-color)';
const c2 = 'var(--visualization-secondary-color)';
const c3 = 'var(--text-color)';

export const Col = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: ${cellSize / 2}px;
`;

export const Circle = styled.circle`
  fill: ${({ on }: { on: boolean }) => (on ? '#000' : '#206')};
`;

type PatternProps = {
  playing: boolean;
  graph: SeededGraph;
  audioGraph: AudioGraph | undefined;
  nodeID: string;
};

const Pattern: FC<PatternProps> = ({ playing, graph, audioGraph, nodeID }) => {
  const [position, setPosition] = useState(0);

  useEffect(() => {
    if (audioGraph === undefined) return;
    const node = audioGraph.audioNodes[nodeID];
    const listener = () => setPosition(node.signals?.position);
    node.addEventListener('trigger', listener);
    return () => node.removeEventListener('trigger', listener);
  }, [audioGraph, nodeID]);

  const node = graph.nodes[nodeID];
  const values = node.params.sequence;

  return (
    <svg
      viewBox={`0 0 ${values.length % rowSize} ${Math.ceil(
        values.length / rowSize,
      )}`}
      height={Math.ceil(values.length / rowSize) * cellSize}
      width={Math.min(rowSize, values.length) * cellSize}
      style={{ background: c1 }}
    >
      <rect
        x={position % rowSize}
        y={Math.floor(position / rowSize)}
        width={1}
        height={1}
        fill={playing ? c3 : c2}
      />
    </svg>
  );
};

export type PositionsProps = {
  playing: boolean;
  graph: SeededGraph;
  audioGraph: AudioGraph | undefined;
};

export const Positions: FC<PositionsProps> = ({
  playing,
  graph,
  audioGraph,
}) => {
  const nodes = Object.values(graph.nodes).filter(n => n.params.sequence);

  return (
    <Col style={{ maxWidth: 270 }}>
      {nodes.map(n => (
        <Pattern
          key={n.id}
          nodeID={n.id}
          graph={graph}
          audioGraph={audioGraph}
          playing={playing}
        />
      ))}
    </Col>
  );
};
