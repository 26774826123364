// 10 digits have char codes 48-57
// 26 uppercase letters have char codes 65-90
// 26 lowercase letters have char codes 97-122

const MAX_VALUE = 61;
const MID_VALUE = Math.floor(MAX_VALUE / 2);

const toScaledCodes = (string?: string): number[] | undefined =>
  string?.split('').map(char => {
    let code = char.charCodeAt(0) - 48;
    if (code > 9) code -= 7;
    if (code > 37) code -= 6;
    return code;
  });

const fromScaledCodes = (codes: number[]): string =>
  String.fromCharCode(
    ...codes.map(code => {
      code += 48;
      if (code > 57) code += 7;
      if (code > 90) code += 6;
      return code;
    }),
  );

export const stringMidpoint = (
  leftString: string | undefined,
  rightString: string | undefined,
) => {
  const left = toScaledCodes(leftString);
  const right = toScaledCodes(rightString);

  let value: number[] = [];

  if (left === undefined && right === undefined) {
    // handle adding the first item in the list
    value.push(MID_VALUE);
  } else if (left === undefined) {
    // handle adding to the beginning of the list
    let i = 0;
    while (right![i] === 0) {
      value.push(0);
      i++;
    }
    if (right![i] === 1) {
      value.push(0, MID_VALUE);
    } else {
      value.push(Math.floor(right![i] / 2));
    }
  } else if (right === undefined) {
    // handle adding to the end of the list
    let i = 0;
    while (left![i] === MAX_VALUE) {
      value.push(MAX_VALUE);
      i++;
    }
    if (left![i] === undefined) {
      value.push(MID_VALUE);
    } else {
      value.push(Math.ceil((MAX_VALUE + left![i]) / 2));
    }
  } else {
    // handle adding between two items
    let i = 0;
    while (left[i] !== undefined && left[i] === right[i]) {
      value.push(left[i]);
      i++;
    }
    while (right[i] === 0) {
      value.push(0);
      i++;
    }
    if (left[i] === undefined) {
      if (right[i] == 1) {
        value.push(0, MID_VALUE);
      } else {
        value.push(Math.floor(right[i] / 2));
      }
    } else {
      if (right[i] - left[i] === 1) {
        value.push(left[i], MID_VALUE);
      } else {
        value.push(Math.floor((left[i] + right[i]) / 2));
      }
    }
  }

  return fromScaledCodes(value);
};
