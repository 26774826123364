import React, { useContext } from 'react';
import {
  ErrorScaffold,
  H2,
  LoadingScaffold,
  RouteHandler,
  SessionContext,
  formatAddress,
  useQuery,
  Section,
} from 'ag-components';
import { PlatformLayout } from '../../components';
import { EditUserPageQuery, EditUserPageQueryVariables } from 'backend';
import { EditUserForm } from './EditUserForm';
import { editUserPath, userPath } from '../../utils';

export const EditUserPage: RouteHandler = ({ pathParams: { address } }) => {
  const [loading, error, data] = useQuery<
    EditUserPageQuery,
    EditUserPageQueryVariables
  >('EditUserPageQuery', { address });
  const { currentUser } = useContext(SessionContext);

  if (loading) {
    return <LoadingScaffold />;
  }
  if (error || data === null || address !== currentUser?.address) {
    return <ErrorScaffold />;
  }

  const {
    user,
    user: { name },
  } = data;

  const displayName = name ?? formatAddress(address);

  return (
    <PlatformLayout
      breadcrumbs={[
        ['users', null],
        [displayName, userPath(address)],
        ['edit', editUserPath(address)],
      ]}
    >
      <Section>
        <H2 as="h1">Edit User</H2>
        <EditUserForm user={user} />
      </Section>
    </PlatformLayout>
  );
};
