const config = {
  '84531': {
    chainId: `0x${(84531).toString(16)}`,
    rpcUrls: ['https://goerli.base.org'],
    chainName: 'Base Goerli',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    blockExplorerUrls: ['https://goerli.basescan.org'],
  },
  '8453': {
    chainId: `0x${(8453).toString(16)}`,
    rpcUrls: ['https://mainnet.base.org'],
    chainName: 'Base',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    blockExplorerUrls: ['https://basescan.org'],
  },
  '31337': {
    chainId: `0x${(31337).toString(16)}`,
    rpcUrls: ['http://localhost:8545'],
    chainName: 'Anvil',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
  },
  '84532': {
    chainId: `0x${(84532).toString(16)}`,
    rpcUrls: ['https://sepolia.base.org'],
    chainName: 'Base Sepolia',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
  },
};

export const switchToNewChain = async (
  chainId: keyof typeof config,
  callback?: () => void,
) => {
  const params = config[chainId];
  if (!params) {
    console.error('Missing config for chain ID');
    return;
  }
  try {
    // Check if MetaMask is installed
    if (window.ethereum) {
      // Try to switch to the chain
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: params.chainId }],
      });
      callback?.();
    } else {
      console.error('MetaMask is not installed');
      return;
    }
  } catch (switchError: any) {
    // This error code indicates that the chain has not been added to MetaMask.
    if (
      switchError.code === 4902 ||
      switchError?.data?.originalError?.code === 4902
    ) {
      try {
        // Add a new chain
        await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [params],
        });
        callback?.();
      } catch (addError) {
        console.error('Failed to add the chain', addError);
      }
    } else {
      console.error('Error switching chain', switchError);
    }
  }
};
