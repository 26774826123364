import React, { useContext } from 'react';
import {
  H2,
  Link,
  LoadingScaffold,
  ErrorScaffold,
  RouteHandler,
  P,
  Col,
  Row,
  useQuery,
  SessionContext,
  Section,
  Illustration,
  formatAddress,
  DataList,
  InfoText,
  useMutation,
  RoutingContext,
  Button,
  useMediaQuery,
  A,
  useWeb3Store,
  useDep,
} from 'ag-components';
import { PlatformLayout, IllustratedHero, PlaylistList } from '../components';
import {
  CreatePlaylistMutation,
  CreatePlaylistMutationVariables,
  UserPageQuery,
  UserPageQueryVariables,
} from 'backend';
import { useAutoQueue } from '../hooks';
import {
  editPlaylistPath,
  editUserPath,
  userPath,
  userPlaylistsPath,
  userTokensPath,
} from '../utils';
import { TokenTable } from '../components/TokenTable';

export const UserPage: RouteHandler = ({ pathParams: { address } }) => {
  const { currentUser, mergePlaylistsChanges } = useContext(SessionContext);
  const { depJwt } = useWeb3Store();
  const dep = useDep();
  const { route } = useContext(RoutingContext);
  const [loading, error, data] = useQuery<
    UserPageQuery,
    UserPageQueryVariables
  >('UserPageQuery', { address });
  const [createPlaylist, createPlaylistLoading, createPlaylistError] =
    useMutation<CreatePlaylistMutation, CreatePlaylistMutationVariables>(
      'CreatePlaylistMutation',
      true,
    );
  const isSmall = useMediaQuery('(max-width: 730px)');
  useAutoQueue(data, data => data.user.ownedTokens.edges);

  if (loading || createPlaylistLoading) return <LoadingScaffold />;
  if (error || createPlaylistError || data === null) return <ErrorScaffold />;

  const {
    user: { name, avatar, description, ownedTokens, playlists },
  } = data;

  const viewerIsUser = address === currentUser?.address;
  const displayName = name ?? formatAddress(address);

  return (
    <PlatformLayout
      breadcrumbs={[
        ['users', null],
        [displayName, userPath(address)],
      ]}
    >
      <Section>
        <IllustratedHero
          illustration={<Illustration src={avatar?.uri} />}
          content={
            <Col gap={2}>
              <H2 as="h1">{displayName}</H2>
              <DataList data={[['ADDRESS', formatAddress(address)]]} />
              {description && <P>{description}</P>}
              {viewerIsUser && (
                <>
                  <P>
                    <Link href={editUserPath(address)}>edit profile</Link>
                  </P>
                  {depJwt && (
                    <P>
                      <A
                        onClick={() => {
                          dep.triggerSignIn();
                        }}
                      >
                        open wallet
                      </A>
                    </P>
                  )}
                </>
              )}
            </Col>
          }
        />
      </Section>

      <Section>
        <H2>Playlists</H2>
        {playlists.edges.length > 0 ? (
          <>
            <PlaylistList playlists={playlists} />
            {playlists.pageInfo.hasNextPage && (
              <Row>
                <Link href={userPlaylistsPath(address)}>All playlists</Link>
              </Row>
            )}
          </>
        ) : (
          <P>
            <InfoText>
              {' '}
              {viewerIsUser ? 'You have' : `${displayName} has`} not yet created
              any playlists
            </InfoText>
          </P>
        )}
        {viewerIsUser && (
          <div>
            <Button
              onClick={async () => {
                const response = await createPlaylist({
                  input: { name: 'Playlist' },
                });
                if (response) {
                  const {
                    createPlaylist: { playlist },
                  } = response;
                  route(editPlaylistPath(playlist.id));
                  mergePlaylistsChanges([...playlists.edges, playlist]);
                }
              }}
            >
              Create playlist
            </Button>
          </div>
        )}
      </Section>
      <Section>
        <H2>Owned</H2>
        {ownedTokens.edges.length > 0 ? (
          <>
            <TokenTable cols={isSmall ? 1 : 2} tokens={ownedTokens.edges} />
            {ownedTokens.pageInfo.hasNextPage && (
              <Row>
                <Link href={userTokensPath(address)}>All owned tokens</Link>
              </Row>
            )}
          </>
        ) : (
          <P>
            <InfoText>
              {viewerIsUser ? 'You have' : `${displayName} has`} not yet
              collected any tokens
            </InfoText>
          </P>
        )}
      </Section>
    </PlatformLayout>
  );
};
