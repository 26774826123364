import React, {
  DragEvent,
  MouseEvent,
  FC,
  useState,
  ReactNode,
  MouseEventHandler,
} from 'react';
import styled from 'styled-components';
import { Row } from './Row';

export type DraggableRowProps = {
  index: number;
  group: string;
  move: (fromIndex: number, toIndex: number) => void;
  children: ReactNode;
  onClick?: MouseEventHandler;
  onMouseOver?: MouseEventHandler;
  onMouseOut?: MouseEventHandler;
  onContextMenu?: MouseEventHandler;
  justify?: string;
  align?: string;
  className?: string;
  dragDisabled?: boolean;
};

enum DropTargetStatus {
  NONE,
  BEFORE,
  AFTER,
}

const Container = styled(Row)<{
  dragging: boolean;
  dropTarget: DropTargetStatus;
}>`
  position: relative;
  &:after {
    display: none;
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    border-top: solid 3px var(--outline-color);
    z-index: 1;
  }
  ${({ dropTarget }) =>
    dropTarget === DropTargetStatus.BEFORE
      ? `&:after {
        display: block;
        top: -1.5px;
      `
      : dropTarget === DropTargetStatus.AFTER
      ? `&:after {
        display: block;
        bottom: -1.5px;
      `
      : ''}
`;

const dropTargetStatus = (e: DragEvent | MouseEvent): DropTargetStatus => {
  const { y, height } = (e.target as HTMLDivElement).getBoundingClientRect();
  return e.clientY - y < height / 2
    ? DropTargetStatus.BEFORE
    : DropTargetStatus.AFTER;
};

let globalDragGroup: string | null = null;

export const DraggableRow: FC<DraggableRowProps> = ({
  index,
  group,
  move,
  children,
  onClick,
  onMouseOver,
  onMouseOut,
  onContextMenu,
  justify,
  align,
  className,
  dragDisabled,
}) => {
  const [dragging, setDragging] = useState(false);
  const [dropTarget, setDropTarget] = useState(DropTargetStatus.NONE);

  return (
    <Container
      onClick={onClick}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      onContextMenu={onContextMenu}
      className={className}
      justify={justify}
      align={align}
      dragging={dragging}
      dropTarget={dragDisabled ? DropTargetStatus.NONE : dropTarget}
      draggable={!dragDisabled}
      data-group={group}
      onDragStart={e => {
        setDragging(true);
        e.dataTransfer.setData('text/plain', `${index}`);
        globalDragGroup = group;
      }}
      onDragEnd={() => {
        setDragging(false);
        globalDragGroup = null;
      }}
      onDragEnter={e => {
        if (globalDragGroup !== group) return;

        setDropTarget(dropTargetStatus(e));
      }}
      onDragOver={e => {
        if (globalDragGroup !== group) return;

        e.preventDefault();
        if (dropTarget !== DropTargetStatus.NONE) {
          setDropTarget(dropTargetStatus(e));
        }
      }}
      onDragLeave={e => {
        if (e.currentTarget.contains(e.relatedTarget as Node | null)) return;
        if (globalDragGroup !== group) return;

        setDropTarget(DropTargetStatus.NONE);
      }}
      onDrop={e => {
        e.stopPropagation();
        if (globalDragGroup !== group) return;

        const fromIndex = parseInt(e.dataTransfer.getData('text/plain'));
        const targetIndex =
          index + (dropTarget === DropTargetStatus.AFTER ? 1 : 0);
        const toIndex = targetIndex - (targetIndex > fromIndex ? 1 : 0);
        setDropTarget(DropTargetStatus.NONE);
        move(fromIndex, toIndex);
      }}
    >
      {children}
    </Container>
  );
};
