import React, { FC, ReactNode, useContext } from 'react';
import {
  Row,
  Breadcrumbs,
  DarkModeToggleButton,
  Layout,
  Main,
  ConnectWalletButton,
  SessionContext,
  A,
  Button,
  Col,
  Link,
  Logout,
} from 'ag-components';
import { homePath, userPath } from '../utils';
import { DOCS_URL, STUDIO_URL } from 'ag-config';
import { Logo } from 'ag-components/src/components/Logo';

export type PlatformLayoutProps = {
  children: ReactNode;
  breadcrumbs?: [text: string, url: string | null][];
  smallMargin?: boolean;
};

export const PlatformLayout: FC<PlatformLayoutProps> = ({
  breadcrumbs = [],
  children,
  smallMargin,
}) => {
  const { currentUser } = useContext(SessionContext);

  return (
    <Layout
      left={
        <>
          <Col gap={3}>
            <Link style={{ textDecoration: 'none' }} href={homePath()}>
              <Logo />
            </Link>
            {breadcrumbs.length > 0 && <Breadcrumbs items={breadcrumbs} />}
          </Col>
        </>
      }
      right={
        <Row gap={1} align="center" style={{ height: 76 }}>
          <A href={STUDIO_URL} target="_blank">
            <Button fullWidth>
              <div
                style={{
                  display: 'flex',
                  whiteSpace: 'nowrap',
                  alignItems: 'center',
                }}
              >
                Studio{' '}
                <span
                  style={{ marginLeft: '10px', display: 'inline-flex' }}
                  dangerouslySetInnerHTML={{
                    __html: `<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><style>svg{fill:var(--button-text-color)}</style><path d="M320 0H288V64h32 82.7L201.4 265.4 178.7 288 224 333.3l22.6-22.6L448 109.3V192v32h64V192 32 0H480 320zM32 32H0V64 480v32H32 456h32V480 352 320H424v32 96H64V96h96 32V32H160 32z"/></svg>`,
                  }}
                />
              </div>
            </Button>
          </A>

          <A href={DOCS_URL} target="_blank">
            <Button fullWidth>
              <div
                style={{
                  display: 'flex',
                  whiteSpace: 'nowrap',
                  alignItems: 'center',
                }}
              >
                Docs{' '}
                <span
                  style={{ marginLeft: '10px', display: 'inline-flex' }}
                  dangerouslySetInnerHTML={{
                    __html: `<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><style>svg{fill:var(--button-text-color)}</style><path d="M320 0H288V64h32 82.7L201.4 265.4 178.7 288 224 333.3l22.6-22.6L448 109.3V192v32h64V192 32 0H480 320zM32 32H0V64 480v32H32 456h32V480 352 320H424v32 96H64V96h96 32V32H160 32z"/></svg>`,
                  }}
                />
              </div>
            </Button>
          </A>

          <ConnectWalletButton
            href={userPath(currentUser?.address as string)}
          />
          <Logout />
          <DarkModeToggleButton />
        </Row>
      }
    >
      <Main
        style={{
          padding: smallMargin ? '1rem 1.5rem' : '1rem 2.5rem',
          paddingBottom: '100px',
        }}
      >
        {children}
      </Main>
    </Layout>
  );
};
