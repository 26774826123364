import React, { FC, ReactNode, useState, useMemo, useEffect } from 'react';
import { GlobalStyle } from '../components';
import { StyleContext, StyleOptions } from '../contexts';

export type StyleProviderProps = {
  children?: ReactNode;
};

const LS_KEY = 'AG_DARK_MODE';

export const StyleProvider: FC<StyleProviderProps> = ({ children }) => {
  const [prefersColorSchemeDark, setPrefersColorSchemeDark] = useState<boolean>(
    () => {
      const mqList = window.matchMedia('(prefers-color-scheme: dark)');
      return mqList.matches;
    },
  );

  useEffect(() => {
    const onMediaChange = ({ matches }: MediaQueryList | MediaQueryListEvent) =>
      setPrefersColorSchemeDark(matches);
    const mqList = window.matchMedia('(prefers-color-scheme: dark)');
    mqList.addEventListener('change', onMediaChange);
    return () => {
      mqList.removeEventListener('change', onMediaChange);
    };
  }, []);

  const [darkMode, _setDarkMode] = useState<boolean | null>(() => {
    const savedStateString = localStorage.getItem(LS_KEY);
    if (savedStateString === 'true') return true;
    if (savedStateString === 'false') return false;
    return null;
  });

  const setDarkMode = (darkMode: boolean) => {
    localStorage.setItem(LS_KEY, `${darkMode}`);
    _setDarkMode(darkMode);
  };

  const isDarkMode = darkMode === null ? prefersColorSchemeDark : darkMode;

  const styleOptions = useMemo<StyleOptions>(
    () => ({
      darkMode: isDarkMode,
      setDarkMode,
    }),
    [isDarkMode],
  );

  useEffect(() => {
    document.body.classList.add('global-styles-applied');
    return () => {
      document.body.classList.remove('global-styles-applied');
    };
  }, []);

  return (
    <StyleContext.Provider value={styleOptions}>
      <GlobalStyle darkMode={isDarkMode} />
      {children}
    </StyleContext.Provider>
  );
};
