import React, { FC } from 'react';
import { SVGIcon } from './SVGIcon';

export type ShowQueueButtonProps = {
  onClick?: () => void;
  size?: number;
  disabled?: boolean;
};

export const ShowQueueButton: FC<ShowQueueButtonProps> = ({
  size,
  onClick,
  disabled,
}) => (
  <SVGIcon size={size} onClick={onClick} disabled={disabled}>
    <title>show queue</title>
    <rect height="2" width="11" x="3" y="10" />
    <rect height="2" width="11" x="3" y="6" />
    <rect height="2" width="7" x="3" y="14" />
    <polygon points="16,13 16,21 22,17" />
  </SVGIcon>
);
