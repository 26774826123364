import styled from 'styled-components';

export type CardProps = {
  padding?: number;
};

export const Card = styled.div<CardProps>`
  background-color: var(--project-preview-color);
  padding: ${({ padding = 1 }) => padding}rem;
  transition: background-color 0.33s ease-in-out;
`;
