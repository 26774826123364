import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

export type DataListProps = {
  data: [ReactNode | undefined, ReactNode | undefined][];
  fontSize?: number;
};

const Grid = styled.div<{ fontSize: number }>`
  gap: 1rem 2rem;
  display: grid;
  grid-template-columns: auto 1fr;
  font-size: ${({ fontSize }) => fontSize}em;
  color: var(--text-secondary-color);
`;

export const DataList: FC<DataListProps> = ({ data, fontSize = 1.25 }) => (
  <Grid fontSize={fontSize}>
    {data.map(([label, item]) => (
      <>
        {label}
        <span>{item}</span>
      </>
    ))}
  </Grid>
);
