import React from 'react';
import {
  H1,
  RouteHandler,
  LoadingScaffold,
  ErrorScaffold,
  useQuery,
  Section,
} from 'ag-components';
import { PlatformLayout, PaginationControls } from '../components';
import { MintsPageQuery, MintsPageQueryVariables } from 'backend';
import { connectionArgs, mintsPath } from '../utils';
import { useAutoQueue } from '../hooks';
import { TokenTable } from '../components/TokenTable';

export const MintsPage: RouteHandler = ({ searchParams }) => {
  const [loading, error, data] = useQuery<
    MintsPageQuery,
    MintsPageQueryVariables
  >('MintsPageQuery', connectionArgs(searchParams));

  useAutoQueue(data, data => data.mints.edges);

  if (loading) return <LoadingScaffold />;
  if (error || data === null) return <ErrorScaffold />;

  const { mints } = data;

  return (
    <PlatformLayout breadcrumbs={[['mints', mintsPath()]]}>
      <Section>
        <H1>Mints</H1>
        <TokenTable tokens={mints.edges} />
        <PaginationControls pageInfo={mints.pageInfo} />
      </Section>
    </PlatformLayout>
  );
};
