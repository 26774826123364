import React from 'react';
import {
  H1,
  RouteHandler,
  LoadingScaffold,
  ErrorScaffold,
  useQuery,
  Section,
  formatAddress,
} from 'ag-components';
import {
  PlatformLayout,
  PaginationControls,
  PlaylistList,
} from '../components';
import {
  UserPlaylistsPageQuery,
  UserPlaylistsPageQueryVariables,
} from 'backend';
import { userPath, userPlaylistsPath } from '../utils';

export const UserPlaylistsPage: RouteHandler = ({
  pathParams: { address },
}) => {
  const [loading, error, data] = useQuery<
    UserPlaylistsPageQuery,
    UserPlaylistsPageQueryVariables
  >('UserPlaylistsPageQuery', { address });

  if (loading) return <LoadingScaffold />;
  if (error || data === null) return <ErrorScaffold />;

  const {
    user: { name, playlists },
  } = data;

  return (
    <PlatformLayout
      breadcrumbs={[
        [name ?? formatAddress(address), userPath(address)],
        ['playlists', userPlaylistsPath(address)],
      ]}
    >
      <Section>
        <H1>{name}’s Playlists</H1>
        <PlaylistList playlists={playlists} />
        <PaginationControls pageInfo={playlists.pageInfo} />
      </Section>
    </PlatformLayout>
  );
};
