import { useContext, useEffect } from 'react';
import { PlayableTokenFragment } from 'backend';
import { PlayerContext } from '../contexts';

export const useAutoQueue = <Data>(
  data: Data | null,
  getTokens: (data: Data) => (PlayableTokenFragment | null | undefined)[],
): void => {
  const { setAutoQueue } = useContext(PlayerContext);
  useEffect(() => {
    if (data) {
      setAutoQueue(getTokens(data).filter(t => t) as PlayableTokenFragment[]);
    }
  }, [data]);
};
