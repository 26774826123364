import manifest from 'ag-processors/dist/manifest.json';

import { ADEnvelopeExtension } from './ADEnvelopeExtension';
import { ADSREnvelopeExtension } from './ADSREnvelopeExtension';
import { ArrangerExtension } from './ArrangerExtension';
import { CartesianSequenceExtension } from './CartesianSequenceExtension';
import { ChordSequenceExtension } from './ChordSequenceExtension';
import { ClockExtension } from './ClockExtension';
import { ComplexEnvelopeExtension } from './ComplexEnvelopeExtension';
import { ComplexRhythmExtension } from './ComplexRhythmExtension';
import { EuclideanRhythmExtension } from './EuclideanRhythmExtension';
import { LogicExtension } from './LogicExtension';
import { PitchShifterExtension } from './PitchShifterExtension';
import { QuantizerExtension } from './QuantizerExtension';
import { RandomGateExtension } from './RandomGateExtension';
import { ResonatorExtension } from './ResonatorExtension';
import { ReverbExtension } from './ReverbExtension';
import { RhythmExtension } from './RhythmExtension';
import { SampleAndHoldExtension } from './SampleAndHoldExtension';
import { SampleSlicerExtension } from './SampleSlicerExtension';
import { ScaleExtension } from './ScaleExtension';
import { SequenceExtension } from './SequenceExtension';
import { SlewLimiterExtension } from './SlewLimiterExtension';
import { StochasticRhythmExtension } from './StochasticRhythmExtension';
import { TriggeredSamplerExtension } from './TriggeredSamplerExtension';
import { WhiteNoiseExtension } from './WhiteNoiseExtension';

export const EXTENSIONS = [
  ADEnvelopeExtension,
  ADSREnvelopeExtension,
  ArrangerExtension,
  CartesianSequenceExtension,
  ChordSequenceExtension,
  ClockExtension,
  ComplexEnvelopeExtension,
  ComplexRhythmExtension,
  EuclideanRhythmExtension,
  LogicExtension,
  PitchShifterExtension,
  QuantizerExtension,
  RandomGateExtension,
  ResonatorExtension,
  ReverbExtension,
  RhythmExtension,
  SampleAndHoldExtension,
  SampleSlicerExtension,
  ScaleExtension,
  SequenceExtension,
  SlewLimiterExtension,
  StochasticRhythmExtension,
  TriggeredSamplerExtension,
  WhiteNoiseExtension,
].map(e => ({
  ...e,
  processor:
    (manifest as { [path: string]: string })?.[
      e.processor.replace(/^\//, '')
    ] ?? e.processor,
}));
