import React from 'react';
import {
  H1,
  RouteHandler,
  LoadingScaffold,
  ErrorScaffold,
  useQuery,
  Section,
} from 'ag-components';
import { PlatformLayout, SalesTable, PaginationControls } from '../components';
import { SalesPageQuery, SalesPageQueryVariables } from 'backend';
import { connectionArgs, salesPath } from '../utils';
import { useAutoQueue } from '../hooks';

export const SalesPage: RouteHandler = ({ searchParams }) => {
  const [loading, error, data] = useQuery<
    SalesPageQuery,
    SalesPageQueryVariables
  >('SalesPageQuery', connectionArgs(searchParams));

  useAutoQueue(data, data => data.transfers.edges.map(s => s.token));

  if (loading) return <LoadingScaffold />;
  if (error || data === null) return <ErrorScaffold />;

  const { transfers } = data;

  return (
    <PlatformLayout breadcrumbs={[['sales', salesPath()]]}>
      <Section>
        <H1>Sales</H1>
        <SalesTable sales={transfers.edges} />
        <PaginationControls pageInfo={transfers.pageInfo} />
      </Section>
    </PlatformLayout>
  );
};
