import { Dep } from '@depdev/dep-core';
import { useMemo } from 'react';

export const depInstance = new Dep({
  key: 'd768959d-1423-4b29-95f8-53191407b4a4',
});

export function useDep() {
  const dep = useMemo(() => depInstance, []);
  return dep;
}
