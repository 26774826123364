export const homePath = () => `/`;
export const aboutPath = () => `/about`;
export const termsPath = () => `/terms`;
export const privacyPath = () => `/privacy`;
export const mintsPath = () => `/mints`;
export const salesPath = () => `/sales`;
export const userPath = (address: string) => `/users/${address}`;
export const editUserPath = (address: string) => `/users/${address}/edit`;
export const userTokensPath = (address: string) => `/users/${address}/tokens`;
export const userPlaylistsPath = (address: string) =>
  `/users/${address}/playlists`;
export const artistPath = (chainID: number) => `/artists/${chainID}`;
export const artistCollectionsPath = (chainID: number) =>
  `/artists/${chainID}/collections`;
export const collectionsPath = () => `/collections`;
export const collectionPath = (address: string) => `/collections/${address}`;
export const collectionSalesPath = (address: string) =>
  `/collections/${address}/sales`;
export const collectionListingsPath = (address: string) =>
  `/collections/${address}/listings`;
export const collectionTokensPath = (address: string) =>
  `/collections/${address}/tokens`;
export const tokenPath = (address: string, tokenID: number) =>
  `/collections/${address}/tokens/${tokenID}`;
export const listTokenPath = (address: string, tokenID: number) =>
  `/collections/${address}/tokens/${tokenID}/list`;
export const playlistPath = (playlistID: string) => `/playlists/${playlistID}`;
export const editPlaylistPath = (playlistID: string) =>
  `/playlists/${playlistID}/edit`;
