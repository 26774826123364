import React, { FC } from 'react';
import { SVGIcon } from './SVGIcon';

export type NextButtonProps = {
  onClick?: () => void;
  size?: number;
  disabled?: boolean;
};

export const NextButton: FC<NextButtonProps> = ({
  size,
  onClick,
  disabled,
}) => (
  <SVGIcon size={size} onClick={onClick} disabled={disabled}>
    <title>next</title>
    <path d="M4 18l8.5-6L4 6v12zm9-12v12l8.5-6L13 6z" />
  </SVGIcon>
);
