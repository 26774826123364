import React, { FC } from 'react';
import { Grid } from './Grid';
import { Link } from './Link';
import { P } from './P';
import { A } from './A';
import { maxBlockContentWidth } from '../style';

export type FooterProps = {
  links: [text: string, path: string][];
};

export const Footer: FC<FooterProps> = ({ links }) => (
  <Grid style={{ maxWidth: maxBlockContentWidth }}>
    {links.map(([text, path], i) => (
      <P>
        <Link key={i} href={path}>
          {text}
        </Link>
      </P>
    ))}
    <P>
      <A href="https://twitter.com/unlooped_xyz" target="_blank">
        twitter
      </A>
    </P>
    <P>
      <A href="https://discord.gg/xMDZSYCUkw" target="_blank">
        discord
      </A>
    </P>
    <P>
      <A href="mailto:contact@unlooped.xyz">contact</A>
    </P>
  </Grid>
);
