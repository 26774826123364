import React from 'react';
import {
  Col,
  Footer,
  H2,
  P,
  RouteHandler,
  Row,
  ErrorScaffold,
  LoadingScaffold,
  Section,
  useQuery,
  Link,
} from 'ag-components';
import { CollectionGrid, PlatformLayout } from '../components';
import { HomePageQuery, HomePageQueryVariables } from 'backend';
import { useAutoQueue } from '../hooks';
import { aboutPath, homePath, privacyPath, termsPath } from '../utils';
import { TokenTable } from '../components/TokenTable';
import { BlogRow } from '../components/BlogRow';

export const HomePage: RouteHandler = () => {
  const [loading, error, data] = useQuery<
    HomePageQuery,
    HomePageQueryVariables
  >('HomePageQuery', {});

  useAutoQueue(data, data => [
    ...data.featuredCollections.edges.map(c => c.collection.featuredToken),
    ...data.collections.edges.map(c => c.featuredToken),
    ...data.mints.edges,
  ]);

  if (loading) return <LoadingScaffold />;
  if (error || data === null) return <ErrorScaffold />;

  return (
    <PlatformLayout>
      <Section style={{ marginTop: '2.5rem' }}>
        <Col align="center">
          <P style={{ textAlign: 'center' }}>
            Collect, explore, and create generative music.{' '}
            <Link href="/about" style={{ whiteSpace: 'nowrap' }}>
              Learn more
            </Link>
          </P>
          <P style={{ textAlign: 'center' }}></P>
        </Col>
      </Section>

      {data.featuredCollections?.edges?.length > 0 && (
        <Section>
          <H2>Featured Collections</H2>
          <CollectionGrid
            cols={{ sm: 1, md: 2, lg: 2 }}
            maxVisibleCells={{ sm: 2, md: 4, lg: 4 }}
            collections={data.featuredCollections.edges.map(
              ({ collection }) => collection,
            )}
          />
        </Section>
      )}

      <Section>
        <H2>Newest Collections</H2>
        <CollectionGrid
          cols={{ sm: 1, md: 2, lg: 3 }}
          maxVisibleCells={{ xs: 2, sm: 2, md: 4, lg: 6 }}
          collections={data.collections.edges}
        />
        <Row>
          <Link href="/collections">All collections</Link>
        </Row>
      </Section>

      <Section>
        <H2>Recent Mints</H2>
        <TokenTable tokens={data.mints.edges as any} />
        <Row>
          <Link href="/mints">All mints</Link>
        </Row>
      </Section>

      <Section>
        <H2>Blog</H2>
        <BlogRow />
      </Section>

      <Section>
        <H2>More</H2>
        <Footer
          links={[
            ['home', homePath()],
            ['about', aboutPath()],
            ['terms', termsPath()],
            ['privacy', privacyPath()],
          ]}
        />
      </Section>
    </PlatformLayout>
  );
};
