import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import {
  type Address,
  WalletClient,
  PublicClient,
  createPublicClient,
  createWalletClient,
  custom,
} from 'viem';

declare global {
  interface Window {
    ethereum?: any;
  }
}

interface Web3State {
  address?: Address;
  depJwt?: string;
  chainId?: number;
  isConnected: boolean;
  isConnecting: boolean;
  walletClient?: WalletClient;
  publicClient?: PublicClient;
  connect: (provider?: any) => Promise<void>;
  chainChanged: () => Promise<void>;
  setAddress: (address: Address) => void;
  setDepJwt: (depJwt: string) => void;
}

// Arb chain ID 42161

export const useWeb3Store = create<Web3State>()(
  devtools(
    persist(
      set => ({
        isConnected: false as boolean,
        isConnecting: false as boolean,

        async chainChanged() {
          const client = createPublicClient({
            transport: custom(window.ethereum),
          });
          const chainId = await client.getChainId();
          set({ chainId });
        },
        setAddress(address: Address) {
          set({ address });
        },
        setDepJwt(depJwt: string) {
          set({ depJwt });
        },
        async connect(provider: any) {
          let address;
          if (!provider) {
            provider = window.ethereum;
          }
          if (provider) {
            try {
              [address] = await provider.request({
                method: 'eth_requestAccounts',
              });
            } catch (e) {
              console.log(e);
              set({ isConnecting: false });
              return;
            }
          }
          if (typeof window.ethereum !== 'undefined' || provider) {
            try {
              set({ isConnecting: true });
              const client = createWalletClient({
                transport: custom(provider || window.ethereum),
              });
              const publicClient = createPublicClient({
                transport: custom(provider || window.ethereum),
              });

              const chainId = await client.getChainId();
              set({
                address: address.toLowerCase() as Address,
                // signer,
                // provider,
                publicClient,
                walletClient: client,
                isConnected: true,
                isConnecting: false,
                chainId: chainId,
              });
            } catch (e) {
              console.log(e);
              set({ isConnecting: false });
            }
          }
        },
      }),
      {
        name: 'Web3Store',
        partialize: state => ({
          address: state.address,
          depJwt: state.depJwt,
        }),
      },
    ),
    { name: 'Web3Store' },
  ),
);
