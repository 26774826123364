import React, { FC, FormEvent, useContext, useState } from 'react';
import {
  Button,
  Col,
  ErrorScaffold,
  Input,
  LoadingScaffold,
  RoutingContext,
  SessionContext,
  TextArea,
  useMutation,
} from 'ag-components';
import {
  UpdatePlaylistMutation,
  UpdatePlaylistMutationVariables,
} from 'backend';
import { playlistPath } from '../../utils';

export type EditPlaylistFormProps = {
  playlist: {
    id: string;
    name: string;
    description: string;
  };
};

export const EditPlaylistForm: FC<EditPlaylistFormProps> = ({ playlist }) => {
  const { playlists, mergePlaylistsChanges } = useContext(SessionContext);
  const [updatePlaylist, loading, error] = useMutation<
    UpdatePlaylistMutation,
    UpdatePlaylistMutationVariables
  >('UpdatePlaylistMutation', true);
  const { route } = useContext(RoutingContext);

  const [name, setName] = useState(playlist.name);
  const [description, setDescription] = useState(playlist.description);

  if (loading) return <LoadingScaffold />;
  if (error) return <ErrorScaffold />;

  return (
    <Col
      gap={2}
      as="form"
      onSubmit={async (e: FormEvent) => {
        e.preventDefault();
        if (!loading) {
          const result = await updatePlaylist({
            input: { id: playlist.id, name, description },
          });
          if (result) {
            route(playlistPath(result.updatePlaylist.playlist.id));
            mergePlaylistsChanges(
              playlists.map(p =>
                p.id === playlist.id ? { ...p, name, description } : p,
              ),
            );
          }
        }
      }}
    >
      <Col as="label" gap={0.5}>
        Name
        <Input
          name="name"
          value={name ?? ''}
          onChange={e => setName(e.target.value)}
          disabled={loading}
        />
      </Col>
      <Col as="label" gap={0.5}>
        Description
        <TextArea
          name="description"
          value={description ?? ''}
          onChange={e => setDescription(e.target.value)}
          disabled={loading}
        />
      </Col>
      <Button type="submit" disabled={loading}>
        Save
      </Button>
    </Col>
  );
};
