import styled from 'styled-components';

export const Illustration = styled.div<{ src: string | null | undefined }>`
  width: 100%;
  position: relative;
  aspect-ratio: 1;
  background-color: var(--background-secondary-color);
  background-image: ${({ src }) => (src ? `url(${CSS.escape(src)})` : 'none')};
  background-size: cover;
  background-position: center center;
  border: 0;
  display: block;
`;
