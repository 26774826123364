import { MouseEvent, useContext } from 'react';
import { MenuItems } from '../types';
import { ContextMenuContext } from '../contexts';

export const useContextMenu = (
  items?: MenuItems | (() => MenuItems),
): ((e: MouseEvent<Element>) => void) => {
  const { open } = useContext(ContextMenuContext);

  const onContextMenu = (e: MouseEvent<Element>) => {
    e.preventDefault();
    e.stopPropagation();
    const menuItems = typeof items === 'function' ? items() : items;
    menuItems && open([e.pageX, e.pageY], menuItems);
  };

  return onContextMenu;
};
