import { createContext } from 'react';

export type RoutingOptions = {
  route: (path: string) => void;
  refresh: () => void;
  setConfirmExit: (confirmExit: boolean) => void;
  confirmExit: boolean;
};

export const RoutingContext = createContext<RoutingOptions>({
  route: () => {},
  refresh: () => {},
  setConfirmExit: () => {},
  confirmExit: false,
});
