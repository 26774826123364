import React, { FC } from 'react';
import styled from 'styled-components';
import { Link } from 'ag-components';

export type PaginationControlsProps = {
  pageInfo: {
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    startCursor?: string | null | undefined;
    endCursor?: string | null | undefined;
  };
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 1.25em;
`;

export const PaginationControls: FC<PaginationControlsProps> = ({
  pageInfo: { hasPreviousPage, hasNextPage, startCursor, endCursor },
}) => (
  <Container>
    <div>
      {hasPreviousPage && (
        <Link href={`${window.location.pathname}?before=${startCursor}`}>
          Previous
        </Link>
      )}
    </div>
    <div>
      {hasNextPage && (
        <Link href={`${window.location.pathname}?after=${endCursor}`}>
          Next
        </Link>
      )}
    </div>
  </Container>
);
