import styled from 'styled-components';
import { inputWidth } from '../style';

export type TextAreaProps = {
  highlighted?: boolean;
  disabled?: boolean;
};

export const TextArea = styled.textarea`
  background-color: var(
    ${({ highlighted, disabled }: TextAreaProps) =>
      disabled
        ? '--input-disabled-color'
        : highlighted
        ? '--input-highlighted-color'
        : '--input-color'}
  );
  color: var(--input-text-color);
  border: 0;
  margin: 0;
  padding: 0.25rem 0.5rem;
  -webkit-appearance: none;
  border-radius: 0;
  height: 5rem;
  width: ${inputWidth}px;
  display: block;
  position: relative;

  ::-webkit-resizer {
    border-width: 8px;
    border-style: solid;
    border-color: transparent var(--input-disabled-color)
      var(--input-disabled-color) transparent;
  }
`;
