import React, { FC } from 'react';
import styled from 'styled-components';
import { Col, Link } from 'ag-components';
import { playlistPath } from '../utils';

const PlaylistLink = styled(Link)`
  font-size: 1.25em;
  padding: 1rem;
`;

export type PlaylistListProps = {
  playlists: {
    edges: {
      name: string;
      id: string;
    }[];
  };
};

export const PlaylistList: FC<PlaylistListProps> = ({ playlists }) => (
  <Col gap={1} style={{ margin: '0 -1rem' }}>
    {playlists.edges.map(playlist => (
      <PlaylistLink href={playlistPath(playlist.id)}>
        {playlist.name}
      </PlaylistLink>
    ))}
  </Col>
);
