import React, { FC } from 'react';
import styled from 'styled-components';
import { Row } from 'ag-components';
import { C1, C2, C3, C4 } from './columns';

const HeaderRowContainer = styled(Row)`
  padding: 1rem;
  position: sticky;
  top: 0;
  background: var(--background-color);
  color: var(--secondary-text-color);
`;

export type HeaderRowProps = {};

export const HeaderRow: FC<HeaderRowProps> = () => (
  <HeaderRowContainer gap={1}>
    <C1 />
    <C2>NAME</C2>
    <C3>COLLECTION</C3>
    <C3>ARTIST</C3>
    <C4 />
  </HeaderRowContainer>
);
